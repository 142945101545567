export class Vendor {
  vendorLid: string;
  orgLid: string;
  name: string;
  status: number;

  constructor(d: any) {
    this.vendorLid = d.vendor_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.status = d.status;
  }
}