<div class="context-menu" dropdown>
    <a href id="context-menu-link" dropdownToggle (click)="false" aria-controls="context-menu-link-dropdown">
      <i class="fa fa-ellipsis-h"></i>
    </a>
    <ul id="context-menu-link-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="context-menu-link">
      <li role="menuitem" *ngFor="let item of items">
        <a class="dropdown-item" [ngClass]="{ 'disabled': !item.enabled }" href="#" (click)="select($event, item)">{{ item.label }}</a>
      </li>
    </ul>
  </div>
