import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';

// @ts-ignore
import packageObj from '../../../package.json';

export const ANALYTICS_API_URL = '/api/v1.0/analytics';
export const ANALYTICS_DOCUMENT_URL = '/document/v1.0/analytics';
export const AUDIT_API_URL = '/api/v1.0/audit';
export const IDENTITY_API_URL = '/api/v1.0/identity';
export const INTEGRATION_API_URL = '/api/v1.0/integration';
export const NEWS_API_URL = '/api/v1.0/news';
export const NOTE_API_URL = '/api/v1.0/note';
export const NOTIFICATION_API_URL = '/api/v1.0/notification';
export const ORG_API_URL = '/api/v1.0/org';
export const PATIENT_API_URL = '/api/v1.0/patient';
export const PROVIDER_API_URL = '/api/v1.0/provider';
export const SEARCH_API_URL = '/api/v1.0/search';
export const SURGERY_API_URL = '/api/v1.0/surgery';
export const USER_API_URL = '/api/v1.0/user';

export const XSRF_TOKEN = 'XSRF-TOKEN';

@Injectable()
export class DataService {

  private isAuthExpired = false;

  constructor(
    private _cookieService: CookieService,
    private _router: Router,
    private _ngxPermissionsService: NgxPermissionsService
  ) { }

  public getXsrfToken() {
    return this._cookieService.get(XSRF_TOKEN);
  }

  public handleHttpError(resp: HttpErrorResponse): Observable<never> {
    if (resp.status === 401) {
      this.isAuthExpired = true;
      let that = this;
      window.setTimeout(() => {
        if (this.isAuthExpired) {
          that.isAuthExpired = false;
          that.logout();
        }
      }, 100);
    }
    if (resp.status === 422 && resp.error && resp.error.error) {
      return throwError(resp.error.error);  
    }
    if (resp.error) {
      return throwError({
        status: resp.status,
        error: resp.error.error
      });  
    }
    return throwError({
      message: resp.message,
      type: 'unauthorized'
    });
  }

  public getAuthRequestHeader(params?: HttpParams, responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'): object {
    const xsrfToken = this.getXsrfToken();
    const headers = new HttpHeaders({
      'X-LR-Product-Platform': '100',
      'X-LR-Product-Version': packageObj.version,
    });
    const options: any = {
      headers: xsrfToken ? headers.append('X-XSRF-TOKEN', xsrfToken) : headers,
      responseType: responseType ? responseType : 'json'
    };

    if (params) options['params'] = params;

    return options;
  }

  public getApiRequestHeader(params?: HttpParams, responseType?: 'arraybuffer' | 'blob' | 'json' | 'text', accept?: Array<string>): object {
    const xsrfToken = this.getXsrfToken();
    let headers = new HttpHeaders({
      'X-LR-Product-Platform': '100',
      'X-LR-Product-Version': packageObj.version,
    });
    if (xsrfToken) {
      headers = headers.append('X-XSRF-TOKEN', xsrfToken)
    }
    if (accept) {
      for (let mime of accept) {
        headers = headers.append('Accept', mime)
      }
    }
    const options: any = {
      headers: headers,
      responseType: responseType ? responseType : 'json'
    };

    if (params) options['params'] = params;

    return options;
  }

  public logout() {
    this.clearCookies();
    this._ngxPermissionsService.flushPermissions();
    const ssoCookie = this._cookieService.get('lrSSO');
    if (ssoCookie) {
      let redirectUrl = `${window.location.origin}/login/sso`;
      let sso = JSON.parse(ssoCookie);
      if (sso.type && sso.logoutUrl && sso.type == 1) {
        let url = `${sso.logoutUrl}?wa=wsignout1.0&wreply=${encodeURIComponent(redirectUrl)}`;
        window.location.href = url;
      } else {
        this._router.navigate([this.getPreferredLogin()]);
      }
    } else {
      this._router.navigate([this.getPreferredLogin()]);
    }
  }

  public getPreferredLogin() {
    const ssoCookie = this._cookieService.get('lrSSO');
    if (ssoCookie) {
      return '/login/sso';
    } else {
      return '/login';
    }
  }

  private clearCookies() {
    this._cookieService.delete('lrUser', '/');
    this._cookieService.delete(XSRF_TOKEN, '/');
  }
}
