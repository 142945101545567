import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TypedJSON } from 'typedjson';

import { DataService, SEARCH_API_URL } from '../data.service';

import { CustomQueryEncoderHelper } from '../data.model';
import { UserSearchResult, SearchSurgeryBookingRequestResponse, SurgeryCaseQueryRequest } from './search.model';

import { SearchProcedureResponse, SearchProviderResponse, SearchSurgeryCaseResponse } from './search.message';

@Injectable()
export class SearchService {
  private _surgeryCaseRequestSerializer: TypedJSON<SurgeryCaseQueryRequest>;
  private _searchSurgeryCaseResponseSerializer: TypedJSON<SearchSurgeryCaseResponse>;

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService
  ) {
    this._surgeryCaseRequestSerializer = new TypedJSON(SurgeryCaseQueryRequest);
    this._searchSurgeryCaseResponseSerializer = new TypedJSON(SearchSurgeryCaseResponse);
  };

  public searchProcedure(orgLid: string, term: string, exclusions: Array<string>): Observable<SearchProcedureResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        query: term
      }
    });

    if (exclusions.length > 0) {
      preparedParams = preparedParams.set('exclusions', exclusions.join(','));
    }

    return this._httpClient.get(`${SEARCH_API_URL}/org/${orgLid}/procedure`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new SearchProcedureResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public searchProvider(orgLid: string, term: string, exclusionList: Array<string>, role: number | undefined): Observable<SearchProviderResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        query: term
      }
    });

    if (exclusionList.length > 0) {
      preparedParams = preparedParams.set('exclusions', exclusionList.join(','));
    }

    if (role) {
      preparedParams = preparedParams.set('role', role.toString());
    }
    
    return this._httpClient.get(`${SEARCH_API_URL}/org/${orgLid}/provider`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new SearchProviderResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public searchSurgeryCase(orgLid: string, request: SurgeryCaseQueryRequest): Observable<SearchSurgeryCaseResponse | undefined> {
    const body = this._surgeryCaseRequestSerializer.stringify(request);
    return this._httpClient.post(`${SEARCH_API_URL}/org/${orgLid}/surgery/case/search`, body, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => this._searchSurgeryCaseResponseSerializer.parse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public searchSurgeryBookingRequest(orgLid: string, term: string, roomGroupLid: string | undefined): Observable<SearchSurgeryBookingRequestResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        query: term
      }
    });

    if (roomGroupLid) {
      preparedParams = preparedParams.set('room_group_lid', roomGroupLid);
    }

    return this._httpClient.get(`${SEARCH_API_URL}/org/${orgLid}/surgery/booking_request`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new SearchSurgeryBookingRequestResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public searchUser(orgLid: string, term: string): Observable<Array<UserSearchResult>> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        query: term
      }
    });

    return this._httpClient.get(`${SEARCH_API_URL}/org/${orgLid}/user`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => response['data'].map((u: any) => new UserSearchResult(u))),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }
}