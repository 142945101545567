<ng-template #customProviderTemplate let-provider="item">
  <div class="row">
    <div class="col">
      {{ provider.getFullName() }} 
      <span class="small fw-light" *ngIf="provider.credentials && provider.credentials.length">
        {{ provider.getCredentials() }}
      </span>  
    </div>
  </div>
  <div class="row" *ngIf="provider.roles && provider.roles.length">
    <div class="col small fw-light">
      <span *ngFor="let role of provider.roles">
        <ng-container [ngSwitch]="role.roleType">
          <ng-container *ngSwitchCase='1000'>{{ 'PROVIDER_SEARCH.VENDOR_REP' | translate:{ vendor_name: role.roleRefText } }}</ng-container>
          <ng-container *ngSwitchDefault>{{ role.roleRefText }}</ng-container>
        </ng-container>
      </span>
    </div>
  </div>
</ng-template>
 
<input [(ngModel)]="searchStr"
  [typeahead]="searchObservable"
  [typeaheadItemTemplate]="customProviderTemplate"
  (typeaheadLoading)="changeTypeaheadLoading($event)"
  (typeaheadOnSelect)="onSelect($event)"
  typeaheadOptionField="getFullName()"
  placeholder="{{ placeholder }}"
  class="form-control">