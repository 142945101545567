import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../app.service';
import { SearchService } from '@app/data/search/search.service';

import { ProcedureDocument } from '../../data/search/search.model';

@Component({
  selector: 'app-procedure-search',
  templateUrl: './procedure-search.component.html',
  providers: [SearchService]
})
export class ProcedureSearchComponent implements OnInit {

  @Input() public exclusions!: Array<string>;
  @Output() public procedureSelected = new EventEmitter<ProcedureDocument>();

  private _orgLid!: string;

  public placeholder: string;
  public searchStr?: string;
  public typeaheadLoading?: boolean;
  public typeaheadNoResults?: boolean;
  public searchObservable: Observable<Array<ProcedureDocument>>;
  
  constructor(
    protected _translate: TranslateService,
    private _appState: AppState,
    private _searchService: SearchService
  ){
    this.placeholder = this._translate.instant('SEARCH');
    this.searchObservable = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchStr);
    }).pipe(
      mergeMap((term: string) => this._search(term))
    );
  }

  ngOnInit() {
    this._orgLid = this._appState.get('orgId');
  }

  public reset() {
    this.searchStr = '';
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
 
  public onSelect(e: TypeaheadMatch): void {
    if (e) {
      const selected: ProcedureDocument = e.item;
      this.procedureSelected.emit(selected);
    } else {
      this.procedureSelected.emit(undefined);
    }
  }

  private _search(term: string): Observable<Array<ProcedureDocument>> {
    return this._searchService.searchProcedure(this._orgLid, term, this.exclusions).pipe(
      map((x) => x.results)
    );
  }
}