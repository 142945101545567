import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { SurgeryBlockAssignment, SurgeryBlockProvider, SurgeryBlockManager, SurgeryBlock, SurgeryVoluntaryBlockRelease, SurgerySpecialty } from './org/org.model';
import { Provider, ProviderRole, ProviderSchedule } from './provider/provider.model';
import { OrgRole, UserRoleRow } from './user/user.model';
import { ProviderMedium } from './notification/notification.model';
import { BookingRequestPatient, BookingRequestMeta, BookingRequestProcedure, BookingRequestProposal, BookingRequest, BookingRequestNotifications, BookingRequestDocument, BookingRequestNephrology, BookingRequestProposalCandidate } from './case/booking.model';
import { Procedure } from './org/procedure.model';
import { SurgeryRoom, SurgeryRoomPrimetime, SurgeryRoomPrimeTimeRelease } from './room/room.model';
import { Document } from './document/document.model';
import { OrgUserViewState } from './user/user-response.model';
import { SurgeryBlockManualCredit } from './org/org.surgery.block.model';
import { AnnouncementMessage } from './case/surgery-announcement.model';

@Injectable()
export class AnnouncementService {

  // Observable string sources
  private commentedSource = new Subject<[string, boolean, string, any, string | undefined]>();
  private blockUpdatedSource = new Subject<string>();
  private blockAssignmentUpdatedSource = new Subject<SurgeryBlockAssignment>();
  private blockProviderUpdatedSource = new Subject<[SurgeryBlockProvider, Provider]>();
  private blockManagerUpdatedSource = new Subject<[SurgeryBlockManager, Provider]>();
  private blockManualCreditUpdatedSource = new Subject<[SurgeryBlockManualCredit, SurgeryBlockAssignment, Provider, SurgeryRoom]>();
  private blockReleaseUpdatedSource = new Subject<[SurgeryVoluntaryBlockRelease, SurgeryBlockAssignment, Provider, SurgeryRoom]>();
  private roomReleaseUpdatedSource = new Subject<[SurgeryRoomPrimeTimeRelease, Provider]>();
  private bookingRequestUpdatedSource = new Subject<BookingRequest>();
  private bookingRequestMetaUpdatedSource = new Subject<BookingRequestMeta>();
  private bookingRequestNotificationsUpdatedSource = new Subject<BookingRequestNotifications>();
  private bookingRequestPatientUpdatedSource = new Subject<BookingRequestPatient>();
  private bookingRequestProceduresUpdatedSource = new Subject<[Array<BookingRequestProcedure>, Map<string, Procedure>]>();
  private bookingRequestProposalsUpdatedSource = new Subject<[Array<BookingRequestProposal>, Map<string, SurgeryRoom>, Map<string, SurgeryBlock>]>();
  private bookingRequestProposalSelectedSource = new Subject<BookingRequestProposalCandidate>();
  private bookingRequestDocumentAddedSource = new Subject<[BookingRequestDocument, Document]>();
  private bookingRequestNephrologyUpdatedSource = new Subject<BookingRequestNephrology>();
  private caseUpdatedSource = new Subject<string>();
  private userUpdatedSource = new Subject<OrgUserViewState>();
  private userRoleUpdatedSource = new Subject<[UserRoleRow, OrgRole]>();
  private procedureUpdatedSource = new Subject<[Procedure, SurgerySpecialty | undefined]>();
  private providerUpdatedSource = new Subject<Provider>();
  private providerRoleAddedSource = new Subject<ProviderRole>();
  private providerMediumUpdatedSource = new Subject<ProviderMedium>();
  private providerScheduleUpdatedSource = new Subject<[ProviderSchedule, Provider]>();
  private surgeryAnnouncementMessageUpdatedSource = new Subject<AnnouncementMessage>();
  private surgeryRoomPrimeTimeUpdatedSource = new Subject<SurgeryRoomPrimetime>();
  
  // Observable string streams
  commented$ = this.commentedSource.asObservable();
  blockUpdated$ = this.blockUpdatedSource.asObservable();
  blockAssignmentUpdated$ = this.blockAssignmentUpdatedSource.asObservable();
  blockProviderUpdated$ = this.blockProviderUpdatedSource.asObservable();
  blockManagerUpdated$ = this.blockManagerUpdatedSource.asObservable();
  blockManualCreditUpdated$ = this.blockManualCreditUpdatedSource.asObservable();
  blockReleaseUpdated$ = this.blockReleaseUpdatedSource.asObservable();
  roomReleaseUpdated$ = this.roomReleaseUpdatedSource.asObservable();
  bookingRequestUpdated$ = this.bookingRequestUpdatedSource.asObservable();
  bookingRequestMetaUpdated$ = this.bookingRequestMetaUpdatedSource.asObservable();
  bookingRequestNotificationsUpdated$ = this.bookingRequestNotificationsUpdatedSource.asObservable();
  bookingRequestPatientUpdated$ = this.bookingRequestPatientUpdatedSource.asObservable();
  bookingRequestProceduresUpdated$ = this.bookingRequestProceduresUpdatedSource.asObservable();
  bookingRequestProposalsUpdated$ = this.bookingRequestProposalsUpdatedSource.asObservable();
  bookingRequestProposalSelected$ = this.bookingRequestProposalSelectedSource.asObservable();
  bookingRequestDocumentAdded$ = this.bookingRequestDocumentAddedSource.asObservable();
  bookingRequestNephrologyUpdated$ = this.bookingRequestNephrologyUpdatedSource.asObservable();
  caseUpdated$ = this.caseUpdatedSource.asObservable();
  userUpdated$ = this.userUpdatedSource.asObservable();
  userRoleUpdated$ = this.userRoleUpdatedSource.asObservable();
  procedureUpdated$ = this.procedureUpdatedSource.asObservable();
  providerUpdated$ = this.providerUpdatedSource.asObservable();
  providerRoleAdded$ = this.providerRoleAddedSource.asObservable();
  providerMediumUpdated$ = this.providerMediumUpdatedSource.asObservable();
  providerScheduleUpdated$ = this.providerScheduleUpdatedSource.asObservable();
  surgeryRoomPrimeTimeUpdatedSource$ = this.surgeryRoomPrimeTimeUpdatedSource.asObservable();
  surgeryAnnouncementMessageUpdated$ = this.surgeryAnnouncementMessageUpdatedSource.asObservable();

  // Service message commands
  announceCommented(id: string, action: boolean, label: string, payload: any, comment: string | undefined) {
    this.commentedSource.next([id, action, label, payload, comment]);
  }

  announceBlockUpdated(blockLid: string) {
    this.blockUpdatedSource.next(blockLid);
  }

  announceBlockAssignmentUpdated(assignment: SurgeryBlockAssignment) {
    this.blockAssignmentUpdatedSource.next(assignment);
  }

  announceBlockProviderUpdated(blockProvider: SurgeryBlockProvider, provider: Provider) {
    this.blockProviderUpdatedSource.next([blockProvider, provider]);
  }

  announceBlockManagerUpdated(manager: SurgeryBlockManager, provider: Provider) {
    this.blockManagerUpdatedSource.next([manager, provider]);
  }

  announceBlockManualCreditUpdated(credit: SurgeryBlockManualCredit, assignment: SurgeryBlockAssignment, provider: Provider, room: SurgeryRoom) {
    this.blockManualCreditUpdatedSource.next([credit, assignment, provider, room]);
  }

  announceBlockReleaseUpdated(release: SurgeryVoluntaryBlockRelease, assignment: SurgeryBlockAssignment, provider: Provider, room: SurgeryRoom) {
    this.blockReleaseUpdatedSource.next([release, assignment, provider, room]);
  }

  announceRoomReleaseUpdated(release: SurgeryRoomPrimeTimeRelease, provider: Provider) {
    this.roomReleaseUpdatedSource.next([release, provider]);
  }

  announceBookingRequestUpdated(booking: BookingRequest) {
    this.bookingRequestUpdatedSource.next(booking);
  }

  announceBookingRequestMetaUpdated(meta: BookingRequestMeta) {
    this.bookingRequestMetaUpdatedSource.next(meta);
  }

  announceBookingRequestNotificationsUpdated(notifications: BookingRequestNotifications) {
    this.bookingRequestNotificationsUpdatedSource.next(notifications);
  }

  announceBookingRequestPatientUpdated(patient: BookingRequestPatient) {
    this.bookingRequestPatientUpdatedSource.next(patient);
  }

  announceBookingRequestProceduresUpdated(requestProcedures: Array<BookingRequestProcedure>, procedures: Map<string, Procedure>) {
    this.bookingRequestProceduresUpdatedSource.next([requestProcedures, procedures]);
  }

  announceBookingRequestProposalsUpdated(proposals: Array<BookingRequestProposal>, rooms: Map<string, SurgeryRoom>, blocks: Map<string, SurgeryBlock>) {
    this.bookingRequestProposalsUpdatedSource.next([proposals, rooms, blocks]);
  }

  announceBookingRequestProposalSelected(c: BookingRequestProposalCandidate) {
    this.bookingRequestProposalSelectedSource.next(c);
  }

  announceBookingRequestDocumentAdded(requestDocument: BookingRequestDocument, document: Document) {
    this.bookingRequestDocumentAddedSource.next([requestDocument, document]);
  }

  announceBookingRequestNephrologyUpdated(nephrology: BookingRequestNephrology) {
    this.bookingRequestNephrologyUpdatedSource.next(nephrology);
  }

  announceCaseUpdated(caseLid: string) {
    this.caseUpdatedSource.next(caseLid);
  }

  announceUserUpdated(user: OrgUserViewState) {
    this.userUpdatedSource.next(user);
  }

  announceUserRoleUpdated(userRole: UserRoleRow, orgRole: OrgRole) {
    this.userRoleUpdatedSource.next([userRole, orgRole]);
  }

  announceProcedureUpdated(procedure: Procedure, specialty: SurgerySpecialty | undefined) {
    this.procedureUpdatedSource.next([procedure, specialty]);
  }

  announceProviderUpdated(provider: Provider) {
    this.providerUpdatedSource.next(provider);
  }

  announceProviderRoleAdded(role: ProviderRole) {
    this.providerRoleAddedSource.next(role);
  }

  announceProviderMediumUpdated(medium: ProviderMedium) {
    this.providerMediumUpdatedSource.next(medium);
  }

  announceProviderScheduleUpdated(schedule: ProviderSchedule, provider: Provider) {
    this.providerScheduleUpdatedSource.next([schedule, provider]);
  }

  announceSurgeryRoomPrimeTimeUpdated(primeTime: SurgeryRoomPrimetime) {
    this.surgeryRoomPrimeTimeUpdatedSource.next(primeTime);
  }

  announceSurgeryAnnouncementMessageUpdated(message: AnnouncementMessage) {
    this.surgeryAnnouncementMessageUpdatedSource.next(message);
  }
}