import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { CustomQueryEncoderHelper } from '../data.model';
import { Procedure } from '../org/procedure.model';
import { Provider } from '../provider/provider.model';
import { GroupBy } from './analytics-enums.model';

const DateFormat = 'YYYY-MM-DD';

export class Interval {
  public start!: moment.Moment;
  public end!: moment.Moment;

  public durationInDays!: number;

  public getFormatted(): string {
    if (this.durationInDays > 1) {
      return `${this.getStartFormatted()} - ${this.getEndFormatted()}`;
    }

    return this.getStartFormatted();
  }

  public getStartFormatted(): string {
    return this.start.format(DateFormat);
  }

  public getEndFormatted(): string {
    return this.end.format(DateFormat);
  }

  public isToday(): boolean {
    return this.durationInDays === 1 && this.start.isSame(moment(), 'day');
  }

  public isYesterday(): boolean {
    return this.durationInDays === 1 && this.start.isSame(moment().subtract(1, 'day'), 'day');
  }

  public update(_start: moment.Moment, _end: moment.Moment) {
    if (_end.isAfter(_start)) {
      this.start = _start;
      this.end = _end;
    } else {
      this.end = _start;
      this.start = _end;
    }

    this.durationInDays = this.end.diff(this.start, 'days') + 1;
  }

  constructor(_start: moment.Moment, _end: moment.Moment) {
    this.update(_start, _end);
  }

  public static RelativeTo(anchor: Interval, relativeFunction: string): Interval {
    const start = anchor.start.clone();
    const end = anchor.end.clone();

    switch(relativeFunction) {
      case 'month':
        return new Interval(
          start.subtract(1, 'month'),
          end.subtract(1, 'month')
        );

      case 'year':
        return new Interval(
          start.subtract(1, 'year'),
          end.subtract(1, 'year')
        );

      // case 'period'
      default:
        return new Interval(
          start.subtract(anchor.durationInDays, 'days'),
          end.subtract(anchor.durationInDays, 'days')
        );
    }
  }
}

export class TwoPeriods {
  public current: Interval;
  public previous: Interval;
  public relativeFunction: string;

  public toString(): string {
    return this.current.start.format(DateFormat) +
      '_' +
      this.current.end.format(DateFormat) +
      '|' +
      this.previous.start.format(DateFormat) +
      '_' +
      this.previous.end.format(DateFormat) +
      '|' +
      this.relativeFunction;
  }

  public toHttpParams(): HttpParams {
    return new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        current_start: this.current.start.format(DateFormat),
        current_end: this.current.end.format(DateFormat),
        previous_start: this.previous.start.format(DateFormat),
        previous_end: this.previous.end.format(DateFormat),
      }
    });
  }

  constructor(s: string) {
    const periodParts = s.split('|');

    let _current = new Interval(moment().subtract(7, 'days'), moment().subtract(1, 'days'));
    if (periodParts[0]) {
      const dateParts = periodParts[0].split('_');
      if (dateParts.length === 2) {
        const _currentStartCandidate = moment(dateParts[0], DateFormat);
        const _currentEndCandidate = moment(dateParts[1], DateFormat);

        if (_currentStartCandidate.isValid() && _currentEndCandidate.isValid()) {
          _current = new Interval(_currentStartCandidate, _currentEndCandidate);
        }
      }
    }
    this.current = _current;
    
    switch(periodParts[2]) {
      case 'month':
        this.relativeFunction = 'month';
        break;

      case 'year':
        this.relativeFunction = 'year';
        break;

      case 'custom':
        this.relativeFunction = periodParts[1] ? 'custom' : 'period';
        break;

      default:
        this.relativeFunction = 'period';
        break;
    }

    if (this.relativeFunction === 'custom' && periodParts[1]) {
      const dateParts = periodParts[1].split('_');
      if (dateParts.length === 2) {
        const _previousStartCandidate = moment(dateParts[0], DateFormat);
        const _previousEndCandidate = moment(dateParts[1], DateFormat);
        if (_previousStartCandidate.isValid() && _previousEndCandidate.isValid()) {
          if (_previousStartCandidate.isSame(this.current.start, 'day') && _previousEndCandidate.isSame(this.current.end, 'day')) {
            this.previous = Interval.RelativeTo(this.current, 'period');
          } else {
            this.previous = new Interval(_previousStartCandidate, _previousEndCandidate);
          }
        } else {
          this.previous = Interval.RelativeTo(this.current, 'period');
        }
      } else {
        this.previous = Interval.RelativeTo(this.current, 'period');
      }
    } else {
      this.previous = Interval.RelativeTo(this.current, this.relativeFunction);
    }
  }
}

export interface TableFilter {
  key: string,
  label: string,
  groupId: GroupBy,
  drillThroughs: Array<GroupBy>,
}

export class MetricDefinition {
  name: string;
  definition: string;

  constructor(d:any) {
    this.name = d.name;
    this.definition = d.definition;
  }
}

export class AnalyticsSurgeryCase {
  caseLid: string;
  caseType: number;
  scheduleType: number;
  scheduleStatus: number;
  orgLid: string;
  caseId: string;
  siteLid: string;
  roomLid: string | undefined;
  blockLid: string | undefined;
  encounterId: string;
  encounterLid: string;
  encounterClass: number | undefined;
  patientLid: string;
  specialtyLid: string | undefined;
  primaryProcedureLid: string;
  primarySurgeonLid: string;
  primaryAnesthesiaLid: string;
  primaryScrubLid: string;
  primaryCirculatorLid: string;
  primaryPACU1RnLid: string;
  primaryPACU2RnLid: string;
  scheduled: number | undefined;
  scheduledLeadDays: number | undefined;
  scheduledStart: moment.Moment | undefined;
  scheduledStop: number;
  scheduledDuration: number;
  actualDuration: number;
  setupDuration: number | undefined;
  cleanupDuration: number | undefined;
  scheduledProcedureDuration: number | undefined;
  predictedProcedureDuration: number | undefined;
  actualProcedureDuration: number | undefined;
  procedureDurationDelta: number | undefined;
  adjustedProcedureDurationDelta: number | undefined;
  canceled: number | undefined;
  canceledDoS: boolean | undefined;
  cancelAvoidable: boolean | undefined;
  cancelReasonCategory: string | undefined;
  cancelReason: string | undefined;
  roomEnter: moment.Moment | undefined;
  roomExit: moment.Moment | undefined;
  charges: number | undefined;
  actualPayments: number | undefined;
  directCosts: number | undefined;
  directCostsCharged: number | undefined;
  isFirstCase: boolean;
  isWeekendHoliday: boolean;
  delay: number;
  delayReasonCategory: string | undefined;
  delayReason: string;
  turnoverRoom: number | undefined;
  turnoverSurgeon: number | undefined;
  turnoverFlipRoom: number | undefined;
  turnoverFlipSurgeon: number | undefined;
  gapPre: number;
  gapPost: number;
  PACU1Stay: number;
  PACU1StayBucket: number;
  PACU2Stay: number;
  PACU2StayBucket: number;
  PACUAllStay: number;
  PACUAllStayBucket: number;
  robotic: boolean;

  constructor(d:any) {
    this.caseLid = d.case_lid;
    this.caseType = d.case_type;
    this.scheduleType = d.schedule_type;
    this.scheduleStatus = d.schedule_status;
    this.orgLid = d.org_lid;
    this.caseId = d.case_id;
    this.siteLid = d.site_lid;
    this.roomLid = d.room_lid;
    this.blockLid = d.block_lid;
    this.encounterId = d.encounter_id;
    this.encounterLid = d.encounter_lid;
    this.encounterClass = d.encounter_class;
    this.patientLid = d.patient_lid;
    this.specialtyLid = d.specialty_lid;
    this.primaryProcedureLid = d.primary_procedure_lid;
    this.primarySurgeonLid = d.primary_surgeon_lid;
    this.primaryAnesthesiaLid = d.primary_anesthesia_lid;
    this.primaryScrubLid = d.primary_scrub_lid;
    this.primaryCirculatorLid = d.primary_circulator_lid;
    this.primaryPACU1RnLid = d.primary_pacu1_rn_lid;
    this.primaryPACU2RnLid = d.primary_pacu2_rn_lid;
    this.scheduled = d.scheduled;
    this.scheduledLeadDays = d.scheduled_lead_days;
    this.scheduledStart = d.scheduled_start ? moment.unix(d.scheduled_start) : undefined;
    this.scheduledStop = d.scheduled_stop;
    this.scheduledDuration = d.scheduled_duration;
    this.actualDuration = d.actual_duration;
    this.setupDuration = d.setup_duration;
    this.cleanupDuration = d.cleanup_duration;
    this.scheduledProcedureDuration = d.scheduled_procedure_duration;
    this.predictedProcedureDuration = d.predicted_procedure_duration;
    this.actualProcedureDuration = d.actual_procedure_duration;
    this.procedureDurationDelta = d.procedure_duration_delta;
    this.adjustedProcedureDurationDelta = d.adjusted_procedure_duration_delta;
    this.canceled = d.canceled;
    this.canceledDoS = d.canceled_dos;
    this.cancelAvoidable = d.cancel_avoidable;
    this.cancelReasonCategory = d.cancel_reason_category;
    this.cancelReason = d.cancel_reason;
    this.roomEnter = d.room_enter ? moment.unix(d.room_enter) : undefined;
    this.roomExit = d.room_exit ? moment.unix(d.room_exit) : undefined;
    this.charges = d.charges;
    this.actualPayments = d.actual_payments;
    this.directCosts = d.direct_costs;
    this.directCostsCharged = d.direct_costs_charged;
    this.isFirstCase = d.is_first_case;
    this.isWeekendHoliday = d.is_weekend_holiday;
    this.delay = d.delay;
    this.delayReasonCategory = d.delay_reason_category;
    this.delayReason = d.delay_reason;
    this.turnoverRoom = d.turnover_room;
    this.turnoverSurgeon = d.turnover_surgeon;
    this.turnoverFlipRoom = d.turnover_flip_room;
    this.turnoverFlipSurgeon = d.turnover_flip_surgeon;
    this.gapPre = d.gap_rep;
    this.gapPost = d.gap_post;
    this.PACU1Stay = d.pacu1_stay;
    this.PACU1StayBucket = d.pacu1_stay_bucket;
    this.PACU2Stay = d.pacu2_stay;
    this.PACU2StayBucket = d.pacu2_stay_bucket;
    this.PACUAllStay = d.pacu_all_stay;
    this.PACUAllStayBucket = d.pacu_all_stay_bucket;
    this.robotic = d.robotic;
  }
}

export class AnalyticsSurgeryRoom {
  roomLid: string;
  orgLid: string;
  siteLid: string;
  name: string;
  status: number;
  timezone: string;

  constructor(d:any) {
    this.roomLid = d.room_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.name = d.name;
    this.status = d.status;
    this.timezone = d.timezone;
  }
}

export class GetSurgeryProviderContributionCasesResponse {
  cases: Array<AnalyticsSurgeryCase>;
  procedures: Map<string, Procedure>;
  rooms: Map<string, AnalyticsSurgeryRoom>;
  providers: Map<string, Provider>;

  constructor(d: any) {
    this.cases = d.cases.map((c: any) => new AnalyticsSurgeryCase(c));
    
    this.procedures = new Map<string, Procedure>();
    for(let p in d.procedures) {
      this.procedures.set(p, new Procedure(d.procedures[p]));
    }

    this.rooms = new Map<string, AnalyticsSurgeryRoom>();
    for(let r in d.rooms) {
      this.rooms.set(r, new AnalyticsSurgeryRoom(d.rooms[r]));
    }

    this.providers = new Map<string, Provider>();
    for(let p in d.providers) {
      this.providers.set(p, new Provider(d.providers[p]));
    }
  }
}

export class SurgeryBlockUtilizationHistory {
  orgLid: string;
  blockLid: string;
  assignmentLid: string;
  assignmentDate: string;
  measurementDate: string;
  daysBefore: number;
  utilization: number;
  
  constructor(d:any) {
    this.orgLid = d.org_lid;
    this.blockLid = d.block_lid;
    this.assignmentLid = d.assignment_lid;
    this.assignmentDate = d.assignment_date;
    this.measurementDate = d.measurement_date;
    this.daysBefore = d.days_before;
    this.utilization = d.utilization;
  }
}