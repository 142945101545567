import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SurgeryRoom } from '@app/data/room/room.model';

@Component({
  selector: 'ui-surgery-room-multi-picker',
  styleUrls: [ './room-multi-picker.component.scss' ],
  templateUrl: './room-multi-picker.component.html',
})
export class UISurgeryRoomMultiPickerComponent {
  @Input() public allRooms!: Array<SurgeryRoom>;
  @Input() public selectedRooms!: Array<string>;
  @Output() public onRoomAdded = new EventEmitter<string>();
  @Output() public onRoomRemoved = new EventEmitter<string>();

  public isSelected(roomLid: string) {
    if (this.selectedRooms) {
      for(let selectedLid of this.selectedRooms) {
        if (selectedLid == roomLid) return true;
      }
    }

    return false;
  }

  public toggle(roomLid: string) {
    if (this.isSelected(roomLid)) {
      this.onRoomRemoved.emit(roomLid);
    } else {
      this.onRoomAdded.emit(roomLid);
    }
    return false;
  }
}
