import { PhoneNumber } from "../phone/phone.model";

export class Patient {
  patientLid: string;
  orgLid: string;
  addressLid: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthday: string | undefined;
  age: number | undefined;
  gender: number | undefined;
  race: string | undefined;
  maritalStatus: number | undefined;
  locale: string | undefined;
  uniqueNumber: string | undefined;
  email: string | undefined;
  phoneHome: PhoneNumber | undefined;
  phoneWork: PhoneNumber | undefined;
  phoneCell: PhoneNumber | undefined;
  allergy: number | undefined;
  height: number | undefined;
  weight: number | undefined;
  bmi: number | undefined;
  primaryInsurancePlanLid: string | undefined;

  constructor(d: any) {
    this.patientLid = d.patient_lid;
    this.orgLid = d.org_lid;
    this.addressLid = d.address_lid;
    this.firstName = d.first_name;
    this.lastName = d.last_name;
    this.birthday = d.birthday;
    this.age = d.age;
    this.gender = d.gender;
    this.race = d.race;
    this.maritalStatus = d.marital_status;
    this.locale = d.locale;
    this.uniqueNumber = d.unique_number;
    this.email = d.email;
    this.phoneHome = d.phone_home ? new PhoneNumber(d.phone_home) : undefined;
    this.phoneWork = d.phone_work ? new PhoneNumber(d.phone_work) : undefined;
    this.phoneCell = d.phone_cell ? new PhoneNumber(d.phone_cell) : undefined;
    this.allergy = d.allergy;
    this.height = d.height;
    this.weight = d.weight;
    this.bmi = d.bmi;
    this.primaryInsurancePlanLid = d.primary_insurance_plan_lid;
  }
}

export class PatientId {
  orgLid: string;
  idType: string;
  id: string;
  patientLid: string;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.idType = d.id_type;
    this.id = d.id;
    this.patientLid = d.patient_lid;
  }
}

export class PatientIdType {
  patientIdTypeId: number;
  orgLid: string;
  idType: string;
  displayName: string;
  displayRank: number;

  constructor(d: any) {
    this.patientIdTypeId = d.patient_id_type_id;
    this.orgLid = d.org_lid;
    this.idType = d.id_type;
    this.displayName = d.display_name;
    this.displayRank = d.display_rank;
  }
}

export class Address {
  addressLid: string;
  streetAddress: string;
  locality: string;
  adminAreaLevel2: string;
  adminAreaLevel1: string;
  postalCode: string;
  country: string;
  countryISO: string;
  latitude: number;
  longitude: number;
  
  constructor(d: any) {
    this.addressLid = d.address_lid;
    this.streetAddress = d.street_address;
    this.locality = d.locality;
    this.adminAreaLevel2 = d.admin_area_level_2;
    this.adminAreaLevel1 = d.admin_area_level_1;
    this.postalCode = d.postal_code;
    this.country = d.country;
    this.countryISO = d.country_iso;
    this.latitude = d.latitude;
    this.longitude = d.longitude;
  }

  toString(): string {
    let parts = [];
    switch(this.country) {
      case "CHILE":
        if (this.streetAddress) parts.push(this.streetAddress);
        if (this.locality && this.postalCode) {
          parts.push(`${this.locality} ${this.postalCode}`);
        } else {
          if (this.locality) parts.push(this.locality);
          if (this.postalCode) parts.push(this.postalCode);
        }
        parts.push(this.country);
        break;

      default:
        if (this.streetAddress) parts.push(this.streetAddress);
        if (this.locality) parts.push(this.locality);
        if (this.adminAreaLevel1 && this.postalCode) {
          parts.push(`${this.adminAreaLevel1} ${this.postalCode}`);
        } else {
          if (this.adminAreaLevel1) parts.push(this.adminAreaLevel1);
          if (this.postalCode) parts.push(this.postalCode);
        }
        break;
    }

    return parts.join(', ');
  }
}

export class InsuranceCompany {
  companyLid: string;
  orgLid: string;
  name: string;
  addressLid: string | undefined;
  phoneNumber: PhoneNumber | undefined;

  constructor(d: any) {
    this.companyLid = d.company_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.addressLid = d.address_lid;
    this.phoneNumber = d.phone_number;
  }
}

export class InsurancePlan {
  planLid: string;
  orgLid: string;
  companyLid: string;
  name: string;
  planType: string | undefined;

  constructor(d: any) {
    this.planLid = d.plan_lid;
    this.orgLid = d.org_lid;
    this.companyLid = d.company_lid;
    this.name = d.name;
    this.planType = d.plan_type;
  }
}

export class EncounterInsurance {
  encounterInsuranceLid: string;
  patientLid: string;
  encounterLid: string;
  companyLid: string;
  planLid: string;
  memberNumber: string | undefined;
  groupName: string | undefined;
  groupNumber: string | undefined;
  policyNumber: string | undefined;
  agreementType: string | undefined;
  coverageType: string | undefined;
  from: string | undefined;
  to: string | undefined;
  insuredLid: string | undefined;

  constructor(d: any) {
    this.encounterInsuranceLid = d.encounter_insurance_lid;
    this.patientLid = d.patient_lid;
    this.encounterLid = d.encounter_lid;
    this.companyLid = d.company_lid;
    this.planLid = d.plan_lid;
    this.memberNumber = d.member_number;
    this.groupName = d.group_name;
    this.groupNumber = d.group_number;
    this.policyNumber = d.policy_number;
    this.agreementType = d.agreement_type;
    this.coverageType = d.coverage_type;
    this.from = d.from;
    this.to = d.to;
    this.insuredLid = d.insured_lid;
  }
}