import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { SurgeryMilestonesNew } from '../case/surgery.model';
import { IntervalNew, Range } from '../common.model';
import { Provider } from '../provider/provider.model';

@jsonObject
export class PatientIdDocument {
  @jsonMember(String, { name: 'id_type' })
  idType!: string;

  @jsonMember(String, { name: 'id' })
  id!: string;
}

@jsonObject
export class ProcedureCodeDocument {
  @jsonMember(String, { name: 'code_set' })
  codeSet!: string;

  @jsonMember(String, { name: 'code' })
  code!: string;
}

export class ProcedureDocument {
  procedureLid: string;
  name: string;
  frequency: number;

  constructor(d: any) {
    this.procedureLid = d.procedure_lid;
    this.name = d.name;
    this.frequency = d.frequency;
  }
}


export class ProviderRoleDocument {
  roleType: number;
  roleRef: string;
  roleRefText: string;

  constructor(d: any) {
    this.roleType = d.role_type;
    this.roleRef = d.role_ref; 
    this.roleRefText = d.role_ref_text;
  }
}

export class ProviderDocument {
  providerLid: string;
  firstName: string;
  lastName: string;
  credentials: Array<string>;
  roles: Array<ProviderRoleDocument>;

  constructor(d: any) {
    this.providerLid = d.provider_lid;
    this.firstName = d.first_name;
    this.lastName = d.last_name;
    this.credentials = d.credentials;
    this.roles = d.roles.map((r: any) => new ProviderRoleDocument(r));
  }

  getFullName(): string {
    const nameParts = new Array();
    if (this.firstName) nameParts.push(this.firstName);
    if (this.lastName) nameParts.push(this.lastName);

    return nameParts.join(' ');
  }

  getCredentials(): string | undefined {
    if (this.credentials)
      return this.credentials.join(', ');

    return undefined;
  }

  toProvider(): Provider {
    return new Provider({
      provider_lid: this.providerLid,
      first_name: this.firstName,
      last_name: this.lastName,
      credentials: this.credentials,
    })
  }
  
  static fromProvider(p: Provider): ProviderDocument {
    return new ProviderDocument({
      provider_lid: p.providerLid,
      first_name: p.firstName,
      last_name: p.lastName,
      credentials: p.credentials,
      roles: []
    });
  }
}

@jsonObject
export class SurgeryCaseEncounterDocument {
  @jsonMember(String, { name: 'encounter_lid' })
  encounterLid!: string;

  @jsonMember(String, { name: 'org_history_lid' })
  encounterHistoryLid!: string;

  @jsonMember(String, { name: 'encounter_id' })
  encounterId!: string;

  @jsonMember(Number, { name: 'encounter_class' })
  encounterClass: number | undefined;

  @jsonMember(Number)
  status: number | undefined;
}

@jsonObject
export class SurgeryCasePatientDocument {
  @jsonMember(String, { name: 'patient_lid' })
  patientLid!: string;

  @jsonMember(String, { name: 'first_name' })
  firstName: string | undefined;

  @jsonMember(String, { name: 'last_name' })
  lastName: string | undefined;

  @jsonMember(String)
  birthday: string | undefined;

  @jsonMember(Number)
  age: number | undefined;

  @jsonMember(Number)
  gender: number | undefined;

  @jsonMember(Number, { name: 'marital_status' })
  maritalStatus: number | undefined;

  @jsonMember(String)
  locale: string | undefined;

  @jsonMember(String, { name: 'unique_identifier' })
  uniqueIdentifier: string | undefined;

  @jsonArrayMember(PatientIdDocument, { name: 'patient_ids' })
  patientIds!: Array<PatientIdDocument>;
}

@jsonObject
export class SurgeryCaseProcedureDocument {
  @jsonMember(String, { name: 'procedure_lid' })
  procedureLid!: string;

  @jsonMember(String)
  name!: string;

  @jsonMember(Boolean)
  primary!: boolean;

  @jsonArrayMember(ProcedureCodeDocument, { name: 'procedure_codes' })
  procedureCodes!: Array<ProcedureCodeDocument>;
}

@jsonObject
export class SurgeryCaseProviderDocument {
  @jsonMember(String, { name: 'provider_lid' })
  providerLid!: string;

  @jsonMember(String, { name: 'first_name' })
  firstName: string | undefined;

  @jsonMember(String, { name: 'last_name' })
  lastName: string | undefined;

  @jsonArrayMember(String)
  credentials!: Array<string>;

  @jsonMember(String, { name: 'role_lid' })
  roleLid!: string;

  @jsonMember(String, { name: 'role_name' })
  roleName!: string;

  @jsonMember(Number, { name: 'role_std' })
  roleStd: number | undefined;

  @jsonMember(Boolean, { name: 'role_primary' })
  rolePrimary!: boolean;

  @jsonMember(Number, { name: 'role_display_rank' })
  roleDisplayRank!: number;
}

@jsonObject
export class SurgeryCaseDocument {
  @jsonMember(String, { name: 'case_lid' })
  caseLid!: string;

  @jsonMember(String, { name: 'history_lid' })
  historyLid!: string;

  @jsonMember(String, { name: 'org_lid' })
  orgLid!: string;

  @jsonMember(String, { name: 'case_id' })
  caseId!: string;

  @jsonMember(Number, { name: 'case_type' })
  caseType!: number;

  @jsonMember(Number, { name: 'schedule_type' })
  scheduleType!: number;

  @jsonMember(Number, { name: 'schedule_status' })
  scheduleStatus!: number;

  @jsonMember(String, { name: 'site_lid' })
  siteLid!: string;

  @jsonMember(SurgeryCaseEncounterDocument)
  encounter!: SurgeryCaseEncounterDocument;

  @jsonMember(SurgeryCasePatientDocument)
  patient!: SurgeryCasePatientDocument;

  @jsonMember(String, { name: 'specialty_lid' })
  specialtyLid: string | undefined;

  @jsonArrayMember(SurgeryCaseProcedureDocument)
  procedure!: Array<SurgeryCaseProcedureDocument>;

  @jsonArrayMember(SurgeryCaseProviderDocument)
  provider!: Array<SurgeryCaseProviderDocument>;

  @jsonMember(Number, { name: 'surgery_date' })
  surgeryDate!: number;

  @jsonMember(String, { name: 'room_lid' })
  roomLid: string | undefined;

  @jsonMember(Number, { name: 'peri_op_state' })
  periOpState: number | undefined;

  @jsonMember(Number)
  duration!: number;

  @jsonMember(Number, { name: 'scheduled_duration' })
  scheduledDuration!: number;

  @jsonMember(Number)
  scheduled: number | undefined;

  @jsonMember(Number, { name: 'setup_duration' })
  setupDuration: number | undefined;

  @jsonMember(Number, { name: 'cleanup_duration' })
  cleanupDuration: number | undefined;

  @jsonMember(SurgeryMilestonesNew)
  expected!: SurgeryMilestonesNew;

  @jsonMember(SurgeryMilestonesNew)
  actual!: SurgeryMilestonesNew;

  @jsonMember(Boolean)
  robotic!: boolean;

  @jsonArrayMember(String, { name: 'labels' })
  labels!: Array<string>;

  @jsonArrayMember(String, { name: 'delay_reason_categories' })
  delayReasonCategories!: Array<string>;

  @jsonArrayMember(String, { name: 'delay_reasons' })
  delayReasons!: Array<string>;

  @jsonArrayMember(String, { name: 'cancel_reason_categories' })
  cancelReasonCategories!: Array<string>;

  @jsonArrayMember(String, { name: 'cancel_reasons' })
  cancelReasons!: Array<string>;

  @jsonMember(Number)
  created!: number;

  @jsonMember(Number)
  modified!: number;
}


@jsonObject
export class SurgeryCaseProviderQuery {
  @jsonMember(String, { name: 'provider_lid' })
  providerLid!: string;

  @jsonMember(Number)
  role!: number;

  @jsonMember(Boolean)
  primary!: boolean;
}

@jsonObject
export class SurgeryCaseQuery {

  @jsonMember(String)
  text: string | undefined;

  @jsonMember(Number, { name: 'text_target' })
  textTarget: number | undefined;

  @jsonArrayMember(Number, { name: 'case_type_include' })
  caseTypeInclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'case_type_exclude' })
  caseTypeExclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'schedule_type_include' })
  scheduleTypeInclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'schedule_type_exclude' })
  scheduleTypeExclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'schedule_status_include' })
  scheduleStatusInclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'schedule_status_exclude' })
  scheduleStatusExclude: Array<number> | undefined;

  @jsonArrayMember(String, { name: 'site_lid_include' })
  siteLidInclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'site_lid_exclude' })
  siteLidExclude: Array<string> | undefined;

  @jsonArrayMember(Number, { name: 'encounter_class_include' })
  encounterClassInclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'encounter_class_exclude' })
  encounterClassExclude: Array<number> | undefined;

  @jsonArrayMember(String, { name: 'specialty_lid_include' })
  specialtyLidInclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'specialty_lid_exclude' })
  specialtyLidExclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'procedure_lid_include' })
  procedureLidInclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'procedure_lid_exclude' })
  procedureLidExclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'primary_procedure_lid_include' })
  primaryProcedureLidInclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'primary_procedure_lid_exclude' })
  primaryProcedureLidExclude: Array<string> | undefined;

  @jsonArrayMember(SurgeryCaseProviderQuery, { name: 'provider_include' })
  providerInclude: Array<SurgeryCaseProviderQuery> | undefined;

  @jsonArrayMember(SurgeryCaseProviderQuery, { name: 'provider_exclude' })
  providerExclude: Array<SurgeryCaseProviderQuery> | undefined;

  @jsonMember(IntervalNew, { name: 'surgery_date_interval' })
  surgeryDateInterval: IntervalNew | undefined;

  @jsonArrayMember(String, { name: 'room_lid_include' })
  roomLidInclude: Array<string> | undefined;

  @jsonArrayMember(String, { name: 'room_lid_exclude' })
  roomLidExclude: Array<string> | undefined;

  @jsonArrayMember(Number, { name: 'peri_op_state_include' })
  periOpStateInclude: Array<number> | undefined;

  @jsonArrayMember(Number, { name: 'peri_op_state_exclude' })
  periOpStateExclude: Array<number> | undefined;

  @jsonMember(Range, { name: 'duration_range' })
  durationRange: Range | undefined;

  @jsonMember(Range, { name: 'scheduled_duration_range' })
  scheduledDurationRange: Range | undefined;

  @jsonMember(IntervalNew, { name: 'scheduled_date_interval' })
  scheduledDateInterval: IntervalNew | undefined;

  @jsonMember(Boolean)
  robotic: boolean | undefined;
}

@jsonObject
export class SurgeryCaseQueryRequest {
  
  @jsonMember(Number)
  from: number | undefined;

  @jsonMember(Number)
  size: number | undefined;

  @jsonMember(SurgeryCaseQuery)
  query: SurgeryCaseQuery;

  constructor(q: SurgeryCaseQuery | undefined = undefined) {
    this.from = undefined;
    this.size = undefined;
    this.query = q ? q : new SurgeryCaseQuery();
  }
}

export class SurgeryBookingRequestDocument {
  bookingLid: string;
  orgLid: string;
  surgeonLid: string;
  bookingStatus: number;
  creatorLid: string;
  roomGroupLid: string | undefined;
  schedulerLid: string | undefined;
  submitted: number | undefined;
  reviewStarted: number | undefined;
  booked: number | undefined;
  caseId: string | undefined;
  encounterId: string | undefined;
  dateOfSurgery: number | undefined;
  patientFirstName: string | undefined;
  patientLastName: string | undefined;
  patientBirthday: string | undefined;
  patientGender: number | undefined;
  patientUniqueIdentifier: string | undefined;
  created: number;
  modified: number;

  constructor(d: any) {
    this.bookingLid = d.booking_lid;
    this.orgLid = d.org_lid;
    this.surgeonLid = d.surgeon_lid;
    this.bookingStatus = d.booking_status;
    this.creatorLid = d.creator_lid;
    this.roomGroupLid = d.room_group_lid;
    this.schedulerLid = d.scheduler_lid;
    this.submitted = d.submitted;
    this.reviewStarted = d.review_started;
    this.booked = d.booked;
    this.caseId = d.case_id;
    this.encounterId = d.encounter_id;
    this.dateOfSurgery = d.date_of_surgery;
    this.patientFirstName = d.patient_first_name;
    this.patientLastName = d.patient_last_name;
    this.patientBirthday = d.patient_birthday;
    this.patientGender = d.patient_gender;
    this.patientUniqueIdentifier = d.patient_unique_identifier;
    this.created = d.created;
    this.modified = d.modified;
  }
}

export class SearchSurgeryBookingRequestResponse {
  results: Array<SurgeryBookingRequestDocument>;
  providers: Map<string, Provider>;

  constructor(d: any) {
    this.results = d.results.map((r: any) => new SurgeryBookingRequestDocument(r));
    this.providers = new Map<string, Provider>();
    for(let p in d.providers) {
      this.providers.set(p, new Provider(d.providers[p]));
    }
  }
}

export class UserSearchResult {
  userId: string;
  displayName: string;
  emailHandles: Array<string>;

  constructor(d: any) {
    this.userId = d.user_lid;
    this.displayName = d.display_name;
    this.emailHandles = d.email_handles;
  }
}

