import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MenuItem, MenuAction } from '../../data/navigation/navigation.model';

@Component({
  selector: 'context-menu-control',
  styleUrls: [ './context-menu-control.component.scss' ],
  templateUrl: './context-menu-control.component.html',
})
export class ContextMenuControlComponent {
  @Input() items!: Array<MenuItem>;
  @Input() refs!: Array<any>;
  @Output() public itemSelected = new EventEmitter<MenuAction>();

  constructor(
    protected _translate: TranslateService,
  ){}

  public select(event: any, item: MenuItem) {
    if (this.itemSelected) {
      if (item.enabled) {
        if (!item.confirmed || confirm(this._translate.instant('ARE_YOU_SURE'))) {
          this.itemSelected.emit(new MenuAction(item.action, this.refs));
        }
      }
    }
    event.preventDefault();
  }
}