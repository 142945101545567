import { Component, Input } from '@angular/core';

import { Provider } from '@app/data/provider/provider.model';

@Component({
  selector: 'ui-surgery-provider-info',
  templateUrl: './surgery-provider-info.component.html'
})
export class UISurgeryProviderInfoComponent {

  @Input() orgLid?: string;
  @Input() roleStandard?: number;
  @Input() provider: Provider | undefined;
}