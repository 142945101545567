import { UserOrgRow } from '../user/user.model';

export class Provider {
  providerLid: string;
  orgLid: string;
  firstName: string;
  lastName: string;
  credentials: Array<string>;
  status: number;
  locale: string;
  syncOff: boolean;
  created: number;

  fullName: string;
  providerId: string; //TODO: deprecate
  isActive: boolean; //TODO: deprecate
  
  constructor(d: any) {
    this.providerLid = d.provider_lid;
    this.orgLid = d.org_lid;
    this.firstName = d.first_name
    this.lastName = d.last_name
    this.credentials = d.credentials;
    this.status = d.status;
    this.locale = d.locale;
    this.syncOff = d.sync_off;
    this.created = d.created;

    this.providerId = d.provider_lid
    this.isActive = false;
    
    let fullNameParts = new Array<string>();
    if (this.firstName) {
      fullNameParts.push(this.firstName);
    }
    if (this.lastName) {
      fullNameParts.push(this.lastName);
    }

    if (fullNameParts.length > 0) {
      this.fullName = fullNameParts.join(' ');
      if (this.credentials && this.credentials.length > 0) {
        this.fullName += ', ' + this.credentials.join(', ');
      }
    } else {
      this.fullName = 'Someone';
    }
  }

  toJson(): any {
    return {
      provider_lid: this.providerLid,
      first_name: this.firstName,
      last_name: this.lastName,
      credentials: this.credentials,
    }
  }

  public setActive(isActive: boolean) {
    this.isActive = isActive;
  }

  public firstInitialLast() {
    return `${this.firstName[0]}. ${this.lastName}`
  }

  getInitials(): string {
    return `${this.firstName ? this.firstName[0] : ''}${this.lastName ? this.lastName[0] : ''}`;
  }
}

export class ProviderId {
  public orgLid: string;
  public idType: string;
  public id: string;
  public providerLid: string;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.idType = d.id_type;
    this.id = d.id;
    this.providerLid = d.provider_lid;
  }
}

export class ProviderIdType {
  providerIdTypeId: number;
  orgLid: string;
  idType: string;
  displayName: string;
  displayRank: number;

  constructor(d: any) {
    this.providerIdTypeId = d.provider_id_type_id;
    this.orgLid = d.org_lid;
    this.idType = d.id_type;
    this.displayName = d.display_name;
    this.displayRank = d.display_rank;
  }
}

export class ProviderWithIds {
  ids: Array<ProviderId>;
  provider: Provider;

  constructor(d: any) {
    this.ids = d.ids.map((i: any) => new ProviderId(i));
    this.provider = new Provider(d.provider);
  }
}

export class ProviderRole {
  public providerRoleLid: string;
  public orgLid: string;
  public providerLid: string;
  public roleType: number;
  public roleRef: string | undefined;

  constructor(d: any) {
    this.providerRoleLid = d.provider_role_lid;
    this.orgLid = d.org_lid;
    this.providerLid = d.provider_lid;
    this.roleType = d.role_type;
    this.roleRef = d.role_ref;
  }
}

export class ProviderMergeHistory {
  public providerLid: string;
  public mergedToLid: string;
  public created: number;

  constructor(d: any) {
    this.providerLid = d.provider_lid;
    this.mergedToLid = d.merged_to_lid;
    this.created = d.created;
  }
}

export class ProviderScheduleRole {
  public scheduleRoleLid: string;
  public orgLid: string;
  public scheduleType: number;
  public name: string;
  public displayRank: number;

  constructor(d: any) {
    this.scheduleRoleLid = d.schedule_role_lid;
    this.orgLid = d.org_lid;
    this.scheduleType = d.schedule_type;
    this.name = d.name;
    this.displayRank = d.display_rank;
  }
}

export class ProviderSchedule {
  public scheduleLid: string;
  public providerLid: string;
  public orgLid: string;
  public siteLid: string;
  public start: number;
  public end: number;
  public scheduleType: number;
  public scheduleRoleLid: string | undefined;

  constructor(d: any) {
    this.scheduleLid = d.schedule_lid;
    this.providerLid = d.provider_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.start = d.start;
    this.end = d.end;
    this.scheduleType = d.schedule_type;
    this.scheduleRoleLid = d.schedule_role_lid;
  }
}

export class GetProviderResponse {
  public provider: Provider;
  public ids: Array<ProviderId>;
  public roles: Array<ProviderRole>
  public mergeHistory: ProviderMergeHistory | undefined;
  public userOrg: UserOrgRow | undefined;

  constructor(d: any) {
    this.provider = new Provider(d.provider);
    this.ids = d.ids.map((i: any) => new ProviderId(i));
    this.roles = d.roles.map((r: any) => new ProviderRole(r));
    if (d.merge_history) this.mergeHistory = new ProviderMergeHistory(d.merge_history);
    if (d.user_org) this.userOrg = new UserOrgRow(d.user_org);
  }
}

export class GetProvidersResponse {
  public providers: Array<Provider>;
  public page: number;
  public hasMore: boolean;

  constructor(d: any) {
    this.providers = d.providers.map((p: any) => new Provider(p));
    this.page = d.paging.page;
    this.hasMore = d.paging.has_more;
  }
}

export class GetProvidersCountResponse {
  public count: number;
  public pageSize: number;

  constructor(d: any) {
    this.count = d.count;
    this.pageSize = d.page_size;
  }
}

export class GetProviderScheduleByOrgResponse {
  public orgLid: string;
  public scheduleType: number;
  public date: string;
  public schedule: Array<ProviderSchedule>;
  public roles: Array<ProviderScheduleRole>;
  public providers: Map<string, Provider>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.scheduleType = d.schedule_type;
    this.date = d.date;
    this.schedule = d.schedule.map((s: any) => new ProviderSchedule(s));
    this.roles = d.roles.map((r: any) => new ProviderScheduleRole(r));

    this.providers = new Map<string, Provider>();
    for(let p in d.providers) {
      this.providers.set(p, new Provider(d.providers[p]));
    }
  }
}

export class AddProviderScheduleResponse {
  public schedule: ProviderSchedule;
  public provider: Provider;

  constructor(d: any) {
    this.schedule = new ProviderSchedule(d.schedule);
    this.provider = new Provider(d.provider);
  }
}