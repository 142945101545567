import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-control',
  styleUrls: [ './menu-control.component.scss' ],
  templateUrl: './menu-control.component.html'
})
export class MenuControlComponent {

  @Output() onClicked = new EventEmitter<void>();
  @Input() isOpened!: boolean;

  onMenuClicked(event: any) {
    this.isOpened = !this.isOpened;
    this.onClicked.emit();
    event.preventDefault();
    event.stopPropagation();
  }

}
