export class ProcedureId {
  orgLid: string;
  codeSet: string;
  code: string;
  procedureLid: string;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.codeSet = d.code_set;
    this.code = d.code;
    this.procedureLid = d.procedure_lid;
  }
}

export class Procedure {
  public procedureLid: string;
  public orgLid: string;
  public name: string;
  public specialtyLid: string;
  public robotic: boolean;

  constructor(d: any) {
    this.procedureLid = d.procedure_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.specialtyLid = d.specialty_lid;
    this.robotic = d.robotic;
  }
}

export class ProcedureWithIds {
  ids: Array<ProcedureId>;
  procedure: Procedure;

  constructor(d: any) {
    this.ids = d.ids.map((i: any) => new ProcedureId(i));
    this.procedure = new Procedure(d.procedure);
  }
}

export class ProcedureCodeSet {
  procedureCodeSetId: number;
  orgLid: string;
  codeSet: string;
  displayName: string;
  displayRank: number;

  constructor(d: any) {
    this.procedureCodeSetId = d.procedure_code_set_id;
    this.orgLid = d.org_lid;
    this.codeSet = d.code_set;
    this.displayName = d.display_name;
    this.displayRank = d.display_rank;
  }
}

export class ProcedureIdViewState {
  id: ProcedureId;
  codeSet: ProcedureCodeSet;

  constructor(d: any) {
    this.id = new ProcedureId(d.id);
    this.codeSet = new ProcedureCodeSet(d.code_set);
  }
}