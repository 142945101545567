import { Component, Input } from '@angular/core';

import { SurgeryCaseLabel, SurgeryLabel } from '@app/data/case/surgery.model';

@Component({
  selector: 'ui-surgery-label',
  templateUrl: './surgery-label.component.html',
})
export class UISurgeryLabelComponent {

  @Input() public caseLabel!: SurgeryCaseLabel;
  @Input() public label?: SurgeryLabel;
  @Input() public customClass: string = 'ms-1';
}