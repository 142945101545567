import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyDefined',
})
export class OnlyDefinedPipe implements PipeTransform {
  transform(input: any): any {
    if (!Array.isArray(input)) {
      return input;
    }

    return input.filter(x => x != null);
  }
}
