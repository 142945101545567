import { SurgeryRoom, SurgeryRoomPrimetime, RoomGroup, SurgeryRoomGroupMember, SurgeryRoomGroupSite, SurgeryRoomPrimeTimeRelease } from './room.model';
import { Site } from '../org/org.model';
import { Provider } from '../provider/provider.model';
import { SurgeryArea, SurgeryLocation } from '../case/surgery-area.model';

export class GetSurgeryRoomResponse {
  roomLid: string;
  room: SurgeryRoom;
  site: Site;
  primeTimes: Array<SurgeryRoomPrimetime>;
  primeTimeReleases: Array<SurgeryRoomPrimeTimeRelease>;
  providers: Array<Provider>;

  constructor(d: any) {
    this.roomLid = d.room_lid;
    this.room = new SurgeryRoom(d.room);
    this.site = new Site(d.site);
    this.primeTimes = d.prime_times.map((pt: any) => new SurgeryRoomPrimetime(pt));
    this.primeTimeReleases = d.prime_time_releases.map((ptr: any) => new SurgeryRoomPrimeTimeRelease(ptr));
    this.providers = d.providers.map((p: any) => new Provider(p));
  }
}


export class GetSurgeryRoomsResponse {
  orgLid: string;
  groupLid: string | undefined;
  rooms: Array<SurgeryRoom>;
  sites: Array<Site>;
  primeTimes: Array<SurgeryRoomPrimetime>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.groupLid = d.group_lid;
    this.rooms = d.rooms.map((r: any) => new SurgeryRoom(r));
    this.sites = d.sites.map((s: any) => new Site(s));
    this.primeTimes = d.prime_times.map((pt: any) => new SurgeryRoomPrimetime(pt));
  }

  toJson(): any {
    return {
      org_lid: this.orgLid,
      group_lid: this.groupLid,
      rooms: this.rooms.map((r: SurgeryRoom) => r.toJson()),
      sites: this.sites.map((s: Site) => s.toJson()),
      prime_times: this.primeTimes.map((pt: SurgeryRoomPrimetime) => pt.toJson())
    };
  }
}


export class GetSurgeryRoomGroupResponse {
  groupLid: string;
  group: RoomGroup;
  groupMembers: Array<SurgeryRoomGroupMember>;
  groupSites: Array<SurgeryRoomGroupSite>;
  rooms: Array<SurgeryRoom>;
  locations: Array<SurgeryLocation>;
  areas: Array<SurgeryArea>;
  sites: Array<Site>;

  constructor(d: any) {
    this.groupLid = d.group_lid;
    this.group = new RoomGroup(d.group);
    this.groupMembers = d.group_members.map((m: any) => new SurgeryRoomGroupMember(m));
    this.groupSites = d.group_sites.map((s: any) => new SurgeryRoomGroupSite(s));
    this.rooms = d.rooms.map((r: any) => new SurgeryRoom(r));
    this.locations = d.locations.map((l: any) => new SurgeryLocation(l));
    this.areas = d.areas.map((a: any) => new SurgeryArea(a));
    this.sites = d.sites.map((s: any) => new Site(s));
  }
}

export class AddSurgeryRoomGroupMemberResponse {
  groupMember: SurgeryRoomGroupMember;
  room: SurgeryRoom | undefined;
  location: SurgeryLocation | undefined;

  constructor(d: any) {
    this.groupMember = new SurgeryRoomGroupMember(d.group_member);
    this.room = d.room ? new SurgeryRoom(d.room) : undefined;
    this.location = d.location ? new SurgeryLocation(d.location) : undefined;
  }
}

export class AddSurgeryRoomGroupSiteResponse {
  groupSite: SurgeryRoomGroupSite;
  site: Site;

  constructor(d: any) {
    this.groupSite = new SurgeryRoomGroupSite(d.group_site);
    this.site = new Site(d.site);
  }
}

export class AddSurgeryPrimeTimeReleaseResponse {
  release: SurgeryRoomPrimeTimeRelease;
  primeTime: SurgeryRoomPrimetime
  room: SurgeryRoom;
  provider: Provider;

  constructor(d: any) {
    this.release = new SurgeryRoomPrimeTimeRelease(d.release);
    this.primeTime = new SurgeryRoomPrimetime(d.prime_time);
    this.room = new SurgeryRoom(d.room);
    this.provider = new Provider(d.provider);
  }
}