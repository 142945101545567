@import '../../base';

.app-daterange-picker {
  position: relative;
  //width: 800px;
  text-align: right;
  .trigger-bar {
    margin-top: -20px;
  }
  .separator {
    padding: 0 5px;
  }
  // Datepicker Trigger button
  .btn-secondary {
    font-size: 14px;
    padding: 5px 25px 5px 10px;
    position: relative;
    text-align: left;
    .fa {
      color: $color-cadet;
      position: absolute;
      top: 17px;
      right: 7px;
    }
    .muted {
      color: $color-light-grey;
      font-size: 12px;
    }
  }
  .btn-hidden {
    visibility: hidden;
    height: 1px;
    position: absolute;
    top: -15px;
    right: 5px;
  }
  // Datepicker Popup
  .popup {
    @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
    @include border-radius(.25rem);
    border: 1px solid $color-pale-aqua;
    position: absolute;
    background-color: $color-white;
    top: 50px;
    right: 0;
    width: 638px;
    .calendar-container {
      position: relative;
      height: 330px;
    }
    .tabs {
      border-bottom: 1px solid $color-pale-aqua;
      .tab {
        display: inline-block;
        color: $color-cadet;
        text-align: center;
        width: 310px;
        padding: 10px;
        font-size: 12px;
        &.active {
          text-decoration: underline;
          font-weight: 600;
        }
        &:last-child {
          border-left: 1px solid $color-pale-aqua;
        }
      }
    }
    .tab-container {
      position: relative;
      &.hidden {
        display: none;
      }
      .range-list {
        position: absolute;
        top: 12px;
        left: 10px;
        z-index: 10000;
        background-color: $color-white;
        text-align: left;
        font-size: 12px;
        &.is-setting {
          a.active {
            background-color: $color-white;
            color: $color-date-range-black;
          }
        }
        a {
          color: $color-date-range-black;
          font-family: Arial;
          display: block;
          padding: 4px 8px;
          width: 136px;
          text-decoration: none;
          &:hover {
            background-color: $color-date-range-grey;
          }
          &.active {
            background-color: $color-date-range-blue;
            color: $color-white;
          }
        }
      }
    }
    &.hidden {
      display: none;
    }
  }
}