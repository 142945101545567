import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Observable, Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../app.service';
import { SearchService } from '@app/data/search/search.service';
import { ProviderDocument } from '../../data/search/search.model';

@Component({
  selector: 'app-provider-search',
  styleUrls: [ './provider-search.component.scss' ],
  templateUrl: './provider-search.component.html',
  providers: [SearchService]
})
export class ProviderSearchComponent implements OnInit {

  @Input() public exclusionList?: Array<string>;
  @Input() public role!: number;
  @Output() public onProviderSelected = new EventEmitter<ProviderDocument>();

  private _orgLid!: string;

  public placeholder: string;
  public searchStr: string = '';
  public typeaheadLoading?: boolean;
  public typeaheadNoResults?: boolean;
  public searchObservable: Observable<Array<ProviderDocument>>;
  
  constructor(
    protected _translate: TranslateService,
    private _appState: AppState,
    private _searchService: SearchService
  ){
    this.placeholder = this._translate.instant('SEARCH');
    this.searchObservable = new Observable((observer: Observer<string>) => {
      observer.next(this.searchStr);
    }).pipe(
      switchMap((term: string) => {
        if (term) {
          return this._search(term)
        }
 
        return of([]);
      })
    );
  }

  ngOnInit() {
    this._orgLid = this._appState.get('orgId');
  }

  public reset() {
    this.searchStr = '';
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
 
  public onSelect(e: TypeaheadMatch): void {
    if (e) {
      const selected: ProviderDocument = e.item;
      this.onProviderSelected.emit(selected);
    } else {
      this.onProviderSelected.emit(undefined);
    }
  }

  private _search(term: string): Observable<Array<ProviderDocument>> {
    return this._searchService.searchProvider(this._orgLid, term, this.exclusionList ? this.exclusionList : [], this.role).pipe(
      map((x) => x.results)
    );
  }
}