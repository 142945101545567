import { Component, Input, OnInit } from '@angular/core';

import { AppState } from '@app/app.service';
import { OrgCacheService } from '@app/data/org-cache.service';

import { RoomGroup } from '@app/data/room/room.model';

@Component({
  selector: 'ui-surgery-room-group-name',
  template: '{{ groupName }}',
})
export class UISurgeryRoomGroupNameComponent implements OnInit {

  @Input() groupLid!: string;

  private _orgId!: string;
  
  public groupName: string | undefined = undefined;
  
  constructor(
    private _appState: AppState,
    private _orgCacheService: OrgCacheService,
  ){}

  ngOnInit(): void {
    this._orgId = this._appState.get('orgId');
    this._orgCacheService.getSurgeryRoomGroups(this._orgId).subscribe({
      next: (rooms: Array<RoomGroup>) => {
        const match = rooms.find((r) => r.groupId === this.groupLid);
        if (match) {
          this.groupName = match.name;
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}