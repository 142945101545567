<div dropdown>
  <button id="button-room-group" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" aria-controls="dropdown-room-group">
    {{ currentGroupName }}
  </button>
  <ul id="dropdown-room-group" *dropdownMenu class="dropdown-menu" aria-labelledby="button-room-group">
    <li role="menuitem">
      <a href="#" class="dropdown-item" (click)="selectGroup('0')">{{ 'UI.ROOM_GROUP_PICKER.ALL_ROOMS' | translate }}</a>
    </li>
    <li role="menuitem" *ngFor="let group of roomGroups">
      <a href="#" class="dropdown-item" (click)="selectGroup(group.groupId)">{{group.name}}</a>
    </li>
  </ul>
</div>