<div class="app-date-picker">
  <input 
    type="button"
    class="btn btn-secondary"
    bsDatepicker 
    #datepicker="bsDatepicker"
    [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'MMMM D, YYYY', selectFromOtherMonth: true, customTodayClass: 'date-picker-today' }"
    [(ngModel)]="date" (ngModelChange)="updateCurrentDate()"
  />
  <i class="fa fa-calendar" aria-hidden="true"></i>
</div>