import {Pipe, PipeTransform} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SurgeryBlockAssignment } from '@app/data/org/org.model';

const DAY_OF_WEEK_MAP: any = {
  1: 'BLOCK.MONDAY',
  2: 'BLOCK.TUESDAY',
  3: 'BLOCK.WEDNESDAY',
  4: 'BLOCK.THURSDAY',
  5: 'BLOCK.FRIDAY',
  6: 'BLOCK.SATURDAY',
  7: 'BLOCK.SUNDAY'
}

@Pipe({name: 'blockAssignment'})
export class BlockAssignmentPipe implements PipeTransform {
  constructor(
    private _translate: TranslateService,
  ){}

  transform(assignment: SurgeryBlockAssignment, part: string): any {
    switch(part) {
      case 'weeks':
        switch (assignment.ruleType) {
          case 1:
            if (assignment.weekFrequency == 1) {
              return this._translate.instant('BLOCK.EVERY_WEEK');
            } else {
              return this._translate.instant('BLOCK.EVERY_N_WEEK', { number: assignment.weekFrequency });
            }
            
          case 2:
            if (assignment.weeksOfMonth.findIndex(x => x === false) === -1) {
              return this._translate.instant('BLOCK.EVERY_WEEK');
            } else {
              const commaList = assignment.weeksOfMonth
              .map((v, i) => v ? i + 1 : 0)
              .filter(x => x > 0)
              .join(', ')
              return this._translate.instant('BLOCK.WEEK_N_MONTH', { number: commaList });
            }
    
          case 3:
            return '';
        }
        break;

      case 'days_of_week':
        return assignment.daysOfWeek
          .map((v, i) => v ? i + 1 : 0)
          .filter(x => x > 0)
          .map(v => (DAY_OF_WEEK_MAP[v] ? this._translate.instant(DAY_OF_WEEK_MAP[v]) : 'Unknown'))
          .join(', ');

      default:
        return '';
    }
  }
}
