import { Component, Input, Output, EventEmitter} from '@angular/core';
import { NewsActor } from '../../data/news/news.model';

@Component({
  selector: 'news-headline',
  styleUrls: [ './headline.component.scss' ],
  templateUrl: './headline.component.html'
})
export class NewsHeadlineComponent {

  @Input() type!: number;
  @Input() isPresenting: boolean = false;
  @Input() category!: string;
  @Input() actor?: NewsActor;
  @Input() data!: any;
  @Input() isInDetail: boolean = false;
  @Output() onCaseHeadlineClicked = new EventEmitter<string>();

  showDetail(caseId: string) {
    this.onCaseHeadlineClicked.emit(caseId);
    return false;
  }

}
