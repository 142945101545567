import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'mapValues'})
export class MapValuesPipe implements PipeTransform {
  transform(map: Map<any, any>, compareFn?: (a: any, b: any) => number): any[] {
    let ret: Array<any> = [];

    map.forEach((val, key) => {
      ret.push({
          key: key,
          value: val
      });
    });

    if (compareFn) {
      ret.sort(compareFn);
    }

    return ret;
  }
}
