export class AppId {
  id: number;

  private static URL_APP_ID_MAP: any = {
    'management': 1,
    'board': 2,
    'shift': 3,
    'analytics': 4,
    'surgery': 5,
    'entity': 6,
  }

  constructor(id: number) {
    this.id = id;
  }

  static FromUrlRoot(r: string): AppId | undefined {
    if (this.URL_APP_ID_MAP[r]) {
      return new AppId(this.URL_APP_ID_MAP[r]);
    } else {
      return undefined;
    }
  }

  getAppName(): string {
    switch(this.id) {
      case 1:
        return 'Management';
        
      case 2:
        return 'Board';
        
      case 3:
        return 'Shift';

      case 4:
        return 'Analytics';

      case 5:
        return 'Surgery';

      case 6:
        return 'Entity';

      default:
        console.error('Unknown app id ' + this.id + ' encountered!');
        return 'Leap Rail';
    }
  }

  getAppPermission(): Array<string> { 
    switch(this.id) {
      case 1:
        return ['ManagerApp'];
        
      case 2:
        return ['BoardApp'];
        
      case 3:
        return ['ShiftApp'];

      case 4:
        return ['AnalyticsApp'];

      case 5:
        return ['SurgeryApp'];

      case 6:
        return ['EntityApp'];

      default:
        console.error('Unknown app id ' + this.id + ' encountered!');
        return [];
    }
  }

  getRouterLink(): Array<string> {
    switch(this.id) {
      case 1:
        return ['/', 'management'];

      case 2:
        return ['/', 'board'];

      case 3:
        return ['/', 'shift'];

      case 4:
        return ['/', 'analytics'];

      case 5:
        return ['/', 'surgery'];

      case 6:
        return ['/', 'entity'];

      default:
        console.error('Unknown app id ' + this.id + ' encountered!');
        return ['/'];
    }
  }
}
