import {Pipe, PipeTransform} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Metric } from '@app/data/analytics/analytics-enums.model';

/*
 * Displays name of an analytics metric
 *
 * Usage:
 *   metric | displayAnalyticsMetricEnum
 * Example:
 *   {{ AverageCaseDuration | displayAnalyticsMetricEnum }}
*/
@Pipe({name: 'displayAnalyticsMetricEnum'})
export class DisplayAnalyticsMetricEnumPipe implements PipeTransform {
  constructor(
    private _translate: TranslateService
  ){}

  transform(metric: Metric | undefined): string {
    switch(metric) {
      case Metric.Cases: return 'Cases';
      case Metric.Procedures: return 'Procedures';
      case Metric.Minutes: return 'Minutes';
      case Metric.AverageCaseDuration: return 'Average Case Duration';
      case Metric.AverageCaseCost: return 'Average Case Cost';
      case Metric.TotalCost: return 'Total Cost';
      case Metric.AverageCaseCharges: return 'Average Case Charges';
      case Metric.TotalCharges: return 'Total Charges';
      case Metric.AverageCaseActualPayments: return 'Average Case Actual Payments';
      case Metric.TotalActualPayments: return 'Total Actual Payments';
      case Metric.ContributionMargin: return 'Contribution Margin';
      case Metric.ContributionMarginPerMinute: return 'Margin Per Minute';
      case Metric.CasesWithDirectCosts: return 'Cases with Cost Info';
      case Metric.CasesWithCharges: return 'Cases with Charge Info';
      case Metric.CasesWithActualPayments: return 'Cases with Actual Payment Info';

      case Metric.DurationAccuracy: return 'Case Duration Accuracy';
      case Metric.AverageOverScheduled: return 'Average Over Scheduled';
      case Metric.AverageUnderScheduled: return 'Average Under Scheduled';
      case Metric.LRDurationAccuracy: return 'LR Case Duration Accuracy';
      case Metric.LRAverageOverScheduled: return 'Average LR Over Scheduled';
      case Metric.LRAverageUnderScheduled: return 'Average LR Under Scheduled';
      case Metric.DelayMinutes: return 'Delay Minutes';
      case Metric.AddOnRate: return 'Add-On Rate';
      case Metric.CancellationRate: return 'Cancellation Rate';
      case Metric.Cancellations: return 'Total Cancels';
      case Metric.DayOfSurgeryCancellationRate: return 'Day of Surgery Cancellation Rate';
      case Metric.DayOfSurgeryCancels: return 'Day of Surgery Cancels';
      case Metric.PriorToDayOfSurgeryCancellationRate: return 'Prior to Day of Surgery Cancellation Rate';
      case Metric.PriorToDayOfSurgeryCancels: return 'Prior to Day of Surgery Cancels';
      case Metric.ProcedureDurationAccuracy: return 'Procedure Duration Accuracy';
      case Metric.AverageProcedureOverScheduled: return 'Average Procedure Over Scheduled';
      case Metric.AverageProcedureUnderScheduled: return 'Average Procedure Under Scheduled';
      case Metric.LRProcedureDurationAccuracy: return 'LR Procedure Duration Accuracy';
      case Metric.LRAverageProcedureOverScheduled: return 'Average LR Procedure Over Scheduled';
      case Metric.LRAverageProcedureUnderScheduled: return 'Average LR Procedure Under Scheduled';

      case Metric.FirstCaseOnTimeStart: return this._translate.instant('METRIC.FIRST_CASE_ON_TIME_START');
      case Metric.FirstCaseOnTimeStartNGRate: return 'FCOTS without Grace Period';
      case Metric.FirstCaseTotalDelay: return 'First Case Total Delay';
      case Metric.FirstCaseAverageDelay: return 'First Case Average Delay';
      case Metric.SubsequentCaseOnTimeStart: return this._translate.instant('METRIC.SUBSEQUENT_CASE_ON_TIME_START');
      case Metric.SubsequentCaseTotalDelay: return 'Subsequent Case Total Delay';
      case Metric.SubsequentCaseAverageDelay: return 'Subsequent Case Average Delay';
      case Metric.AfterHourCases: return 'After Hours Cases';
      case Metric.AfterHourMinutes: return 'After Hours Minutes';
      case Metric.TurnoverTimeRoom: return this._translate.instant('METRIC.TURNOVER_ROOM');
      case Metric.TurnoverTimeSurgeon: return this._translate.instant('METRIC.TURNOVER_SURGEON');
      case Metric.TurnoverTimeFlipRoom: return this._translate.instant('METRIC.TURNOVER_FLIP_ROOM');
      case Metric.TurnoverTimeFlipSurgeon: return this._translate.instant('METRIC.TURNOVER_FLIP_SURGEON');
      case Metric.PreProcedureGaps: return 'Pre-Procedure Gaps';
      case Metric.PostProcedureGaps: return 'Post-Procedure Gaps';
      case Metric.PACU1LengthOfStay: return 'PACU I Length of Stay';
      case Metric.PACU2LengthOfStay: return 'PACU II Length of Stay';
      case Metric.FirstCaseTotal: return 'Total First Cases';
      case Metric.FirstCaseDelayedStartCount: return 'Delayed First Cases';
      case Metric.SubsequentCaseDelayedStartCount: return 'Delayed Subsequent Cases';

      case Metric.BlockUtilization: return 'Block Utilization';
      case Metric.TotalBlockMinutes: return 'Total Block Minutes';
      case Metric.UsedBlockMinutes: return 'Used Block Minutes';
      case Metric.ReleasedBlockMinutes: return 'Released Block Minutes';
      case Metric.ReleasedBlockPercent: return 'Released Block Rate';
      case Metric.BlockTurnoverCredit: return 'Turnover Credit Minutes';
      case Metric.BlockAllocation: return 'Block Allocation';

      case Metric.Utilization: return 'Utilization';
      case Metric.StaffedMinutesTotal: return 'Staffed Minutes';
      case Metric.StaffedMinutesUsed: return 'Staffed Minutes Used';
      case Metric.StaffedMinutesReleased: return 'Staffed Minutes Released';
      case Metric.StaffedMinutesTurnoverCredit: return 'Staffed Minutes Turnover Credit';

      case Metric.PrimeTimeUtilization: return 'Prime Time Utilization';
      case Metric.PrimeTimeMinutesTotal: return 'Prime Time Total Minutes';
      case Metric.PrimeTimeMinutesUsed: return 'Prime Time Used Minutes';
      case Metric.PrimeTimeMinutesReleased: return 'Prime Time Released Minutes';
      case Metric.PrimeTimeMinutesReleasedPercent: return 'Prime Time Released Rate';

      case Metric.SSICases: return 'SSI Cases';
      case Metric.SSIRate: return 'SSI Rate';

      case Metric.PrefCardCount: return 'Preference Cards';
      case Metric.PrefCardQuantityOpen: return 'Open Quantity';
      case Metric.PrefCardQuantityHold: return 'Hold Quantity';
      case Metric.PrefCardQuantityUsed: return 'Used Quantity';
      case Metric.PrefCardQuantityUnused: return 'Unused Quantity';
      case Metric.PrefCardQuantityOverused: return 'Overused Quantity';
      case Metric.PrefCardCostCard: return 'Card Cost';
      case Metric.PrefCardCostUsed: return 'Used Cost';
      case Metric.PrefCardCostUnused: return 'Unused Cost';
      case Metric.PrefCardCostOverused: return 'Overused Cost';
      case Metric.PrefCardRateUnused: return 'Unused Rate';
      case Metric.PrefCardRateOverused: return 'Overused Rate';
    }
    
    return 'Unknown';
  }
}
