export enum Metric {
  Cases = 101,
  Procedures = 102,
  Minutes = 103,
  AverageCaseDuration = 104,
  AverageCaseCost = 105,
  TotalCost = 106,
  AverageCaseCharges = 107,
  TotalCharges = 108,
  AverageCaseActualPayments = 109,
  TotalActualPayments = 110,
  ContributionMargin = 111,
  ContributionMarginPerMinute = 112,
  CasesWithDirectCosts = 113,
  CasesWithCharges = 114,
  CasesWithActualPayments = 115,

  DurationAccuracy = 201,
  AverageOverScheduled = 202,
  AverageUnderScheduled = 203,
  LRDurationAccuracy = 204,
  LRAverageOverScheduled = 205,
  LRAverageUnderScheduled = 206,
  DelayMinutes = 207,
  AddOnRate = 208,
  CancellationRate = 209,
  DayOfSurgeryCancels = 210,
  PriorToDayOfSurgeryCancels = 211,
  AddOnPrimeTimeRate =  212,
  AddOnNonPrimeTimeRate = 213,
  CanceledCases = 214,
  WeekdayCases = 215,
  WeekdayAccurateCases = 216,
  Cancellations = 217,
  DayOfSurgeryCancellationRate = 218,
  PriorToDayOfSurgeryCancellationRate = 219,
  CasesIncludingCanceled = 220,
  ProcedureDurationAccuracy = 221,
  AverageProcedureOverScheduled = 222,
  AverageProcedureUnderScheduled = 223,
  LRProcedureDurationAccuracy = 224,
  LRAverageProcedureOverScheduled = 225,
  LRAverageProcedureUnderScheduled = 226,

  FirstCaseOnTimeStart = 301,
  FirstCaseAverageDelay = 302,
  SubsequentCaseOnTimeStart = 303,
  SubsequentCaseAverageDelay = 304,
  AfterHourCases = 305,
  AfterHourMinutes = 306,
  TurnoverTimeRoom = 307,
  TurnoverTimeSurgeon = 308,
  PreProcedureGaps = 309,
  PostProcedureGaps = 310,
  PACU1LengthOfStay = 311,
  PACU2LengthOfStay = 312,
  FirstCaseTotal = 313,
  FirstCaseOnTimeStartCount = 314,
  FirstCaseOnTimeStartNGCount = 315,
  FirstCaseOnTimeStartNGRate = 316,
  FirstCaseDelayedStartCount = 317,
  SubsequentCaseDelayedStartCount = 318,
  FirstCaseTotalDelay = 319,
  SubsequentCaseTotalDelay = 320,
  ExpandedFirstCaseOnTimeStart = 321,
  ExpandedFirstCaseAverageDelay = 322,
  ExpandedFirstCaseTotal = 323,
  ExpandedFirstCaseOnTimeStartCount = 324,
  ExpandedFirstCaseOnTimeStartNGCount = 325,
  ExpandedFirstCaseOnTimeStartNGRate = 326,
  ExpandedFirstCaseDelayedStartCount = 327,
  ExpandedFirstCaseTotalDelay = 328,
  TurnoverTimeFlipRoom = 329,
  TurnoverTimeFlipSurgeon = 330,

  BlockUtilization = 401,
  TotalBlockMinutes = 402,
  UsedBlockMinutes = 403,
  ReleasedBlockMinutes = 404,
  BlockAllocation = 405,
  BlockProviderCount = 406,
  BlockProviderLids = 407,
  MinutesOutOfBlock = 408,
  MinutesPrimeTime = 409,
  MinutesNonPrimeTime = 410,
  BlockCount = 411,
  BlockTurnoverCredit = 412,
  ReleasedBlockPercent = 413,

  Utilization = 501,
  StaffedMinutesTotal = 502,
  StaffedMinutesUsed = 503,
  StaffedMinutesReleased = 504,
  StaffedMinutesTurnoverCredit = 505,

  SSICases = 601,
  SSIRate = 602,

  PrimeTimeUtilization = 701,
  PrimeTimeMinutesTotal = 702,
  PrimeTimeMinutesUsed = 703,
  PrimeTimeMinutesReleased = 704,
  PrimeTimeMinutesReleasedPercent = 705,
  PrimeTimeMinutesTurnoverCredit = 706,
  PrimeTimeRoomCount = 707,

  PrefCardCount = 801,
  PrefCardCaseCount = 802,
  PrefCardProcedureCount = 803,
  PrefCardQuantityOpen = 804,
  PrefCardQuantityHold = 805,
  PrefCardQuantityUsed = 806,
  PrefCardQuantityUnused = 807,
  PrefCardQuantityOverused = 808,
  PrefCardCostCard = 809,
  PrefCardCostUsed = 810,
  PrefCardCostUnused = 811,
  PrefCardCostOverused = 812,
  PrefCardRateUnused = 813,
  PrefCardRateOverused = 814,
}

export enum SurgeryMetricType {
  Contribution = 'contribution',
  Weekday = 'weekday',
  WeekdayElectiveNonAddOn = 'weekday_elective_non_addon',
  WeekdayIncomplete = 'weekday_incomplete',
  Block = 'block',
  Utilization = 'utilization',
  Quality = 'quality',
  PrimeTime = 'prime_time',
  PrefCard = 'pref_card',

  /* composite types that don't exist on the backend */
  Efficiency = 'efficiency',
  Scheduling = 'scheduling',

  /* subtypes that don't exist on the backend */
  StaffingScenario = 'staffing_scenario',
}

export enum GroupBy {
  DayOfWeek = 1,
  YearMonth = 2,
  Specialty = 3,
  ScheduleType = 4,
  Surgeon = 5,
  Anesthesiologist = 6,
  Procedure = 7,
  Room = 8,
  Block = 9,
  Site = 10,
  EncounterClass = 11,
  Scrub = 12,
  Circulator = 13,
  RoomEnterHour = 14,
  PACU1EnterHour = 15,
  PACU2EnterHour = 16,
  AnesthesiaType = 17,
  PACU1RN = 18,
  PACU2RN = 19,
  PatientPostalCode = 20,
  Payor = 21,
  CancelReason = 22,
  CancelAvoidable = 23,
  Day = 24,
  DelayReason = 25,
  DelayReasonCategory = 26,
  CancelReasonCategory = 27,
  PreferenceCard = 28,
  ItemCategory = 29,
  ItemSubCategory = 30,

  PreviousPeriod = 1000,
}

export function getGroupByName(g: GroupBy): string {
  switch(g) {
    case GroupBy.Day: return 'Date';
    case GroupBy.DayOfWeek: return 'Day of week';
    case GroupBy.YearMonth: return 'Year-Month';
    case GroupBy.Specialty: return 'Specialty';
    case GroupBy.ScheduleType: return 'Schedule Type';
    case GroupBy.Surgeon: return 'Surgeon';
    case GroupBy.Anesthesiologist: return 'Anesthesiologist';
    case GroupBy.Procedure: return 'Procedure';
    case GroupBy.Room: return 'Room';
    case GroupBy.Block: return 'Block';
    case GroupBy.Site: return 'Site';
    case GroupBy.EncounterClass: return 'Encounter Class';
    case GroupBy.Scrub: return 'Scrub';
    case GroupBy.Circulator: return 'Circulator';
    case GroupBy.RoomEnterHour: return 'Room Enter Hour';
    case GroupBy.PACU1EnterHour: return 'PACU I Enter Hour';
    case GroupBy.PACU2EnterHour: return 'PACU II Enter Hour';
    case GroupBy.AnesthesiaType: return 'Anesthesia Type';
    case GroupBy.PACU1RN: return 'PACU I RN';
    case GroupBy.PACU2RN: return 'PACU II RN';
    case GroupBy.PatientPostalCode: return 'Patient Postal Code';
    case GroupBy.Payor: return 'Payor';
    case GroupBy.CancelReason: return 'Cancel Reason';
    case GroupBy.CancelAvoidable: return 'Cancel Avoidable';
    case GroupBy.DelayReason: return 'Delay Reason';
    case GroupBy.DelayReasonCategory: return 'Delay Reason Category';
    case GroupBy.CancelReasonCategory: return 'Cancel Reason Category';
    case GroupBy.PreferenceCard: return 'Preference Card';
    case GroupBy.ItemCategory: return 'Item Category';
    case GroupBy.ItemSubCategory: return 'Item Sub Category';

    case GroupBy.PreviousPeriod: return 'Time Period';
    default: return 'Unknown';
  }
}
