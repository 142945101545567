import {Pipe, PipeTransform} from '@angular/core';

import { getGroupByName, GroupBy } from '@app/data/analytics/analytics-enums.model';

/*
 * Displays name of an analytics metric
 *
 * Usage:
 *   metric | displayAnalyticsGroupByEnum
 * Example:
 *   {{ AverageCaseDuration | displayAnalyticsGroupByEnum }}
*/
@Pipe({name: 'displayAnalyticsGroupByEnum'})
export class DisplayAnalyticsGroupByEnumPipe implements PipeTransform {
  transform(metric: GroupBy): string {
    return getGroupByName(metric);
  }
}
