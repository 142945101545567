import { CaseSmsFollower } from './case.sms-follower.model';

export class ProviderMedium {
  mediumLid: string;
  providerLid: string;
  mediumType: number;
  mediumAddress: string;
  status: number;

  constructor(d: any) {
    this.mediumLid = d.medium_lid;
    this.providerLid = d.provider_lid;
    this.mediumType = d.medium_type;
    this.mediumAddress = d.medium_address;
    this.status = d.status;
  }
}

export class SurgeryBlockSubscriber {
  subscriptionLid: string;
  assignmentLid: string;
  assignmentDate: string;
  providerLid: string;
  minimumAvailability: number;

  constructor(d: any) {
    this.subscriptionLid = d.subscription_lid;
    this.assignmentLid = d.block_assignment_lid;
    this.assignmentDate = d.block_assignment_date;
    this.providerLid = d.provider_lid;
    this.minimumAvailability = d.min_availability;
  }
}

export class GetSurgeryCaseSmsFollowersResponse {
  caseLid: string;
  caseHash: string;
  followers: Array<CaseSmsFollower>;
  followPhoneNumber: Map<string, string>;

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.caseHash = d.case_hash;
    this.followers = d.followers.map((f: any) => new CaseSmsFollower(f));
    this.followPhoneNumber = d.follow_phone_number;
  }
}