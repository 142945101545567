export class SurgeryBlockCreditMat {
  assignmentLid: string;
  caseLid: string;
  caseDuration: number;
  creditType: number;
  creditPercent: number | undefined;

  constructor(d: any) {
    this.assignmentLid = d.assignment_lid;
    this.caseLid = d.case_lid;
    this.caseDuration = d.case_duration;
    this.creditType = d.credit_type;
    this.creditPercent = d.credit_percent;
  }
}


export class SurgeryBlockManualCredit {
  creditLid: string;
  assignmentLid: string;
  creditFor: string;
  creditCase: string;
  creditedBy: string;
  creditPercent: number;
  creditNotes: string | undefined;
  blockLid: string;
  orgLid: string;

  constructor(d: any) {
    this.creditLid = d.credit_lid;
    this.assignmentLid = d.assignment_lid;
    this.creditFor = d.credit_for;
    this.creditCase = d.credit_case;
    this.creditedBy = d.credited_by;
    this.creditPercent = d.credit_percent;
    this.creditNotes = d.credit_notes;
    this.blockLid = d.block_lid;
    this.orgLid = d.org_lid;
  }
}

export class SurgeryBlockUtilizationConfig {
  orgLid: string;
  siteLid: string | undefined;
  threshold1: number;
  threshold2: number;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.threshold1 = d.threshold1;
    this.threshold2 = d.threshold2;
  }
}