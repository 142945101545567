import moment from 'moment';
import { Provider } from "../provider/provider.model";
import { SurgeryBlockSubscriber } from "../notification/notification.model";
import { SurgeryRoom } from "../room/room.model";
import { LONG_TIME_FORMAT, NUMERIC_DATE_FORMAT } from '../timer/timer.service';
import { SurgeryBlockUtilizationPrediction } from '../oracle/oracle.model';
import { SurgeryBlockCreditMat, SurgeryBlockManualCredit } from './org.surgery.block.model';
import { SurgeryCase } from '../case/surgery.model';

export class Organization {
  orgLid: string;
  name: string;
  handle: string;
  // avatar: Avatar | undefined;
  timezone: string;
  afterHours: string;
  disabledPeriopStates: Array<number>;
  defaultLocale: string;
  countryCode: string;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.handle = d.handle;
    this.timezone = d.timezone;
    this.afterHours = d.after_hours;
    this.disabledPeriopStates = d.disabled_periop_states;
    this.defaultLocale = d.default_locale;
    this.countryCode = d.country_code;
  }
}

export class Site {
  public siteLid: string;
  public orgLid: string;
  public code: string;
  public siteName: string;
  public siteType: string;
  public facility: string;
  public department: string;

  constructor(d: any) {
    this.siteLid = d.site_lid;
    this.orgLid = d.org_lid;
    this.code = d.code;
    this.siteName = d.site_name;
    this.siteType = d.site_type;
    this.facility = d.facility;
    this.department = d.department;
  }

  toJson(): any {
    return {
      site_lid: this.siteLid,
      org_lid: this.orgLid,
      code: this.code,
      site_name: this.siteName,
      site_type: this.siteType,
      facility: this.facility,
      department: this.department
    };
  }
}

export class SurgeryBlockRelease {
  type: number;
  cutoff: Date;
  value: number;

  getCutoffString(): string {
    return moment(this.cutoff).format(LONG_TIME_FORMAT);
  }

  constructor(d: any) {
    this.type = d.type;
    this.cutoff = d.cutoff instanceof Date ? d.cutoff : SurgeryBlockRelease.GetDateFromHours(d.cutoff);
    this.value = d.value;
  }

  private static GetDateFromHours(time: string): Date {
    const m = moment(time, LONG_TIME_FORMAT);
    return m.toDate();
  }

  private static GetDateFromDate(date: string): Date {
    const m = moment(date, NUMERIC_DATE_FORMAT);
    return m.toDate();
  }
}

export class SurgeryBlock {
  blockLid: string;
  orgLid: string;
  name: string;
  status: number;
  voluntaryRelease: SurgeryBlockRelease | undefined;
  automaticRelease: SurgeryBlockRelease | undefined;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.status = d.status;
    this.voluntaryRelease = d.voluntary_release ? new SurgeryBlockRelease(d.voluntary_release) : undefined;
    this.automaticRelease = d.automatic_release ? new SurgeryBlockRelease(d.automatic_release) : undefined;
  }

  static Create(orgLid: string): SurgeryBlock {
    return new SurgeryBlock({
      block_lid: undefined,
      org_lid: orgLid,
      name: undefined,
      status: 1,
    });
  }
}

const DAY_OF_WEEK_MAP: any = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun'
}

export class SurgeryBlockAssignment {
  assignmentLid: string;
  blockLid: string;
  orgLid: string;
  status: number;
  ruleType: number;
  weekFrequency: number;
  weeksOfMonth: Array<boolean>;
  daysOfWeek: Array<boolean>;
  dates: Array<string>;
  from: Date;
  to: Date;
  roomLid: string;
  effectiveFrom: Date;
  effectiveTo: Date | undefined;

  isTodayWithinEffective(): boolean {
    const now = moment();
    const effectiveFromMoment = moment(this.effectiveFrom);
    
    if (now.isBefore(effectiveFromMoment)) {
      return false
    }

    const effectiveToMoment = moment(this.effectiveTo);
    if (this.effectiveTo && effectiveToMoment.isBefore(now)) {
      return false;
    }

    return true;
  }

  getWeeksString(): string {
    switch (this.ruleType) {
      case 1:
        return this.weekFrequency.toString();
        
      case 2:
        return this.weeksOfMonth
          .map((v, i) => v ? i + 1 : 0)
          .filter(x => x > 0)
          .join(',');

      case 3:
        return '';

      default:
        return '';
    }
  }

  getDaysOfWeekString(forHuman: boolean): string {
    return this.daysOfWeek
      .map((v, i) => v ? i + 1 : 0)
      .filter(x => x > 0)
      .map(v => forHuman ? (DAY_OF_WEEK_MAP[v] ? DAY_OF_WEEK_MAP[v] : 'Unknown') : v)
      .join(forHuman ? ', ' : ',');
  }

  getFromString(): string {
    return moment(this.from).format(LONG_TIME_FORMAT);
  }

  getToString(): string {
    return moment(this.to).format(LONG_TIME_FORMAT);
  }

  getEffectiveFrom(): string {
    return moment(this.effectiveFrom).format(NUMERIC_DATE_FORMAT);
  }

  getEffectiveTo(): string {
    if (!this.effectiveTo) return '';

    return moment(this.effectiveTo).format(NUMERIC_DATE_FORMAT);
  }

  constructor(d: any) {
    this.assignmentLid = d.assignment_lid;
    this.blockLid = d.block_lid;
    this.orgLid = d.org_lid;
    this.status = d.status;
    this.ruleType = d.rule_type;
    this.weekFrequency = 1;
    this.weeksOfMonth = [false, false, false, false, false];
    switch (this.ruleType) {
      case 1:
        this.weekFrequency = d.weeks[0];
        break;

      case 2:
        if (d.weeks) d.weeks.forEach((w: any) => this.weeksOfMonth[w-1] = true);
        break;
    }
    this.daysOfWeek = [false, false, false, false, false, false, false];
    if (d.days_of_week) d.days_of_week.forEach((d: any) => this.daysOfWeek[d-1] = true);
    this.dates = d.dates;
    this.from = d.from instanceof Date ? d.from : SurgeryBlockAssignment.GetDateFromHours(d.from);
    this.to = d.to instanceof Date ? d.to : SurgeryBlockAssignment.GetDateFromHours(d.to);
    this.roomLid = d.room_lid;
    this.effectiveFrom = d.effective_from instanceof Date ? d.effective_from : SurgeryBlockAssignment.GetDateFromDate(d.effective_from);
    if (d.effective_to) this.effectiveTo = d.effective_to instanceof Date ? d.effective_to : SurgeryBlockAssignment.GetDateFromDate(d.effective_to);
  }

  static Create(orgLid: string, blockLid: string): SurgeryBlockAssignment {
    return new SurgeryBlockAssignment({
      assignment_lid: undefined,
      block_lid: blockLid,
      org_lid: orgLid,
      status: 1,
      rule_type: 1,
      weeks: [1],
      days_of_week: [],
      dates: [],
      from: '7:30',
      to: '15:30',
      room_lid: undefined,
      effective_from: new Date(),
      effective_to: undefined
    });
  }

  private static GetDateFromHours(time: string): Date {
    const m = moment(time, LONG_TIME_FORMAT);
    return m.toDate();
  }

  private static GetDateFromDate(date: string): Date {
    const m = moment(date, NUMERIC_DATE_FORMAT);
    return m.toDate();
  }
}

export class SurgeryBlockProvider {
  blockProviderLid: string;
  blockLid: string;
  providerLid: string;
  orgLid: string;
  status: number;
  effectiveFrom: Date;
  effectiveTo: Date | undefined;

  getEffectiveFrom(): string {
    return moment(this.effectiveFrom).format(NUMERIC_DATE_FORMAT);
  }

  getEffectiveTo(): string {
    if (!this.effectiveTo) return '';

    return moment(this.effectiveTo).format(NUMERIC_DATE_FORMAT);
  }

  constructor(d: any) {
    this.blockProviderLid = d.block_provider_lid;
    this.blockLid = d.block_lid;
    this.providerLid = d.provider_lid;
    this.orgLid = d.org_lid;
    this.status = d.status;
    this.effectiveFrom = d.effective_from instanceof Date ? d.effective_from : SurgeryBlockProvider.GetDateFromDate(d.effective_from);
    this.effectiveTo = d.effective_to instanceof Date ? d.effective_to : SurgeryBlockProvider.GetDateFromDate(d.effective_to);
  }

  static Create(orgLid: string, blockLid: string): SurgeryBlockProvider {
    return new SurgeryBlockProvider({
      block_provider_lid: undefined,
      block_lid: blockLid,
      provider_lid: undefined,
      org_lid: orgLid,
      status: 1,
      effective_from: undefined,
      effective_to: undefined
    });
  }

  private static GetDateFromDate(date: string | undefined): Date | undefined {
    if (date) {
      const m = moment(date, NUMERIC_DATE_FORMAT);
      return m.toDate();
    }

    return undefined;
  }
}

export class SurgeryBlockManager {
  blockLid: string;
  managerLid: string;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.managerLid = d.manager_lid;
  }
}

export class SurgeryVoluntaryBlockRelease {
  assignmentLid: string;
  releasedFor: string;
  releasedFrom: string;
  releasedTo: string;
  releasedBy: string;
  releaseNotes: string;
  creditType: number;

  constructor(d: any) {
    this.assignmentLid = d.assignment_lid;
    this.releasedFor = d.released_for;
    this.releasedFrom = d.released_from;
    this.releasedTo = d.released_to;
    this.releasedBy = d.released_by;
    this.releaseNotes = d.release_notes;
    this.creditType = d.credit_type;
  }
}

export class SurgeryBlockMat {
  date: string;
  timezone: string;
  block: SurgeryBlock;
  assignment: SurgeryBlockAssignment;
  providerLids: Array<string>;
  automaticRelease: boolean;
  voluntaryReleases: Array<SurgeryVoluntaryBlockRelease>;
  manualCredits: Array<SurgeryBlockManualCredit>;
  utilizationPrediction: SurgeryBlockUtilizationPrediction | undefined;

  constructor(d: any) {
    this.date = d.date;
    this.timezone = d.timezone;
    this.block = new SurgeryBlock(d.block);
    this.assignment = new SurgeryBlockAssignment(d.assignment);
    this.providerLids = d.provider_lids;
    this.automaticRelease = d.automatic_release;
    this.voluntaryReleases = d.voluntary_releases.map((vr: any) => new SurgeryVoluntaryBlockRelease(vr));
    this.manualCredits = d.manual_credits.map((mc: any) => new SurgeryBlockManualCredit(mc));
    this.utilizationPrediction = d.utilization_prediction ? new SurgeryBlockUtilizationPrediction(d.utilization_prediction) : undefined;
  }
}

export class SurgeryBlockUtilization {
  inBlockMinutes: number;
  inBlockCases: number;
  blockMinutes: number;
  releaseMinutes: number;
  turnover: number;
  value: number;
  score: number;

  constructor(d: any) {
    this.inBlockMinutes = d.in_block_minutes;
    this.inBlockCases = d.in_block_cases;
    this.blockMinutes = d.block_minutes;
    this.releaseMinutes = d.release_minutes;
    this.turnover = d.turnover;
    this.value = d.value;
    this.score = d.score;
  }
}

export class CancelReasonCategory {
  categoryLid: string;
  orgLid: string;
  cancelType: number;
  name: string
  
  constructor(d: any) {
    this.categoryLid = d.category_lid;
    this.orgLid = d.org_lid;
    this.cancelType = d.cancel_type;
    this.name = d.name;
  }
}

export class CancelReason {
  reasonLid: string;
  orgLid: string;
  cancelType: number;
  reasonName: string;
  status: number;
  avoidable: boolean;

  constructor(d: any) {
    this.reasonLid = d.reason_lid;
    this.orgLid = d.org_lid;
    this.cancelType = d.cancel_type;
    this.reasonName = d.reason_name;
    this.status = d.status;
    this.avoidable = d.avoidable;
  }
}

export class SurgerySpecialty {
  specialtyLid: string;
  orgLid: string;
  name: string

  constructor(d: any) {
    this.specialtyLid = d.specialty_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
  }
}


export class GetSurgeryBlockResponse {
  orgLid: string;
  blockLid: string;
  block: SurgeryBlock;
  assignments: Array<SurgeryBlockAssignment>;
  blockProviders: Array<SurgeryBlockProvider>;
  managers: Array<SurgeryBlockManager>;
  providers: Array<Provider>;
  rooms: Array<SurgeryRoom>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.blockLid = d.block_lid;
    this.block = new SurgeryBlock(d.block);
    this.assignments = d.assignments.map((a: any) => new SurgeryBlockAssignment(a));
    this.blockProviders = d.block_providers.map((bp: any) => new SurgeryBlockProvider(bp));
    this.managers = d.managers.map((m: any) => new SurgeryBlockManager(m));
    this.providers = d.providers.map((p: any) => new Provider(p));
    this.rooms = d.rooms.map((r: any) => new SurgeryRoom(r));
  }
}

export class GetSurgeryBlockReleasesResponse {
  blockLid: string;
  startDate: string;
  days: number;
  releases: Array<SurgeryVoluntaryBlockRelease>;
  assignments: Map<string, SurgeryBlockAssignment>;
  providers: Map<string, Provider>;
  rooms: Map<string, SurgeryRoom>;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.startDate = d.start_date;
    this.days = d.days;
    this.releases = d.releases.map((r: any) => new SurgeryVoluntaryBlockRelease(r));

    this.assignments = new Map<string, SurgeryBlockAssignment>();
    for(let r in d.assignments) {
      this.assignments.set(r, new SurgeryBlockAssignment(d.assignments[r]));
    }

    this.providers = new Map<string, Provider>();
    for(let r in d.providers) {
      this.providers.set(r, new Provider(d.providers[r]));
    }
   
    this.rooms = new Map<string, SurgeryRoom>();
    for(let r in d.rooms) {
      this.rooms.set(r, new SurgeryRoom(d.rooms[r]));
    }
  }
}

export class GetSurgeryBlockMatsResponse {
  blockLid: string;
  startDate: string;
  days: number;
  materialized: Array<SurgeryBlockMat>;
  providers: Map<string, Provider>;
  rooms: Map<string, SurgeryRoom>;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.startDate = d.start_date;
    this.days = d.days;
    this.materialized = d.materialized.map((m: any) => new SurgeryBlockMat(m));

    this.providers = new Map<string, Provider>();
    for(let r in d.providers) {
      this.providers.set(r, new Provider(d.providers[r]));
    }
   
    this.rooms = new Map<string, SurgeryRoom>();
    for(let r in d.rooms) {
      this.rooms.set(r, new SurgeryRoom(d.rooms[r]));
    }
  }
}

export class GetSurgeryBlocksForOrgResponse {
  orgLid: string;
  groupLid: string | undefined;
  blocks: Array<SurgeryBlock>;
  assignments: Array<SurgeryBlockAssignment>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.groupLid = d.group_lid;
    this.blocks = d.blocks.map((b: any) => new SurgeryBlock(b));
    this.assignments = d.assignments.map((a: any) => new SurgeryBlockAssignment(a));
  }
}

export class GetSurgeryBlockAssignmentOnDateResponse {
  date: string;
  isHoliday: boolean;
  isAutoReleased: boolean;
  canVoluntaryRelease: boolean;
  utilization: SurgeryBlockUtilization | undefined;
  room: SurgeryRoom;
  block: SurgeryBlock;
  assignment: SurgeryBlockAssignment;
  blockProviders: Array<SurgeryBlockProvider>;
  managers: Array<SurgeryBlockManager>;
  subscribers: Array<SurgeryBlockSubscriber>;
  releases: Array<SurgeryVoluntaryBlockRelease>;
  manualCredits: Array<SurgeryBlockManualCredit>;
  assignmentCases: Array<SurgeryCase>;
  assignmentCaseCredits: Array<SurgeryBlockCreditMat>;
  providers: Array<Provider>;

  constructor(d: any) {
    this.date = d.date;
    this.isHoliday = d.is_holiday;
    this.isAutoReleased = d.is_auto_released;
    this.canVoluntaryRelease = d.can_voluntary_release;
    this.utilization = d.utilization ? new SurgeryBlockUtilization(d.utilization) : undefined;
    this.room = new SurgeryRoom(d.room);
    this.block = new SurgeryBlock(d.block);
    this.assignment = new SurgeryBlockAssignment(d.assignment);
    this.blockProviders = d.block_providers.map((bp: any) => new SurgeryBlockProvider(bp));
    this.managers = d.managers.map((m: any) => new SurgeryBlockManager(m));
    this.subscribers = d.subscribers.map((s: any) => new SurgeryBlockSubscriber(s));
    this.releases = d.releases.map((r: any) => new SurgeryVoluntaryBlockRelease(r));
    this.manualCredits = d.manual_credits.map((c: any) => new SurgeryBlockManualCredit(c));
    this.assignmentCases = d.assignment_cases.map((c: any) => new SurgeryCase(c));
    this.assignmentCaseCredits = d.assignment_case_credits.map((c: any) => new SurgeryBlockCreditMat(c));
    this.providers = d.providers.map((p: any) => new Provider(p));
  }
}

export class AddOrUpdateSurgeryBlockProviderResponse {
  blockProvider: SurgeryBlockProvider;
  provider: Provider;

  constructor(d: any) {
    this.blockProvider = new SurgeryBlockProvider(d.block_provider);
    this.provider = new Provider(d.provider);
  }
}

export class AddSurgeryBlockManagerResponse {
  manager: SurgeryBlockManager;
  provider: Provider;

  constructor(d: any) {
    this.manager = new SurgeryBlockManager(d.manager);
    this.provider = new Provider(d.provider);
  }
}

export class AddSurgeryBlockReleaseResponse {
  release: SurgeryVoluntaryBlockRelease;
  assignment: SurgeryBlockAssignment;
  provider: Provider;
  room: SurgeryRoom;

  constructor(d: any) {
    this.release = new SurgeryVoluntaryBlockRelease(d.release);
    this.assignment = new SurgeryBlockAssignment(d.assignment);
    this.provider = new Provider(d.provider);
    this.room = new SurgeryRoom(d.room);
  }
}