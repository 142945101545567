import { Component, Input } from '@angular/core';

import { SurgeryPatient } from '@app/data/case/surgery.model';

@Component({
  selector: 'ui-surgery-patient-info',
  templateUrl: './surgery-patient-info.component.html'
})
export class UISurgeryPatientInfoComponent {

  @Input() patient: SurgeryPatient | undefined;
}