<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="onCancel()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row mb-3">
      <div class="col">
        <textarea rows="3" class="form-control" id="comment" name="comment" formControlName="comment"></textarea>
      </div>
    </div>
    <div class="row mb-3" >
      <div class="col button-row d-flex justify-content-end">
        <button type="button" class="btn px-5" [ngClass]="cancelClass" (click)="onCancel()">{{ cancelName }}</button>
        <button type="submit" class="btn px-5 ms-2" [ngClass]="actionClass" [disabled]="form.invalid">{{ actionName }}</button>
      </div>
    </div>
  </form>
</div>
  