<ng-template #customSurgeryCaseTemplate let-surgery="item">
  <div [style.width.px]="inputRef.nativeElement.clientWidth - 40">
    <div class="row">
      <div class="col-sm-12 col-md">
        {{ surgery.caseId }}
        <span class="small" [ngSwitch]="surgery.scheduleStatus">
          <span class="text-white ms-1 px-2 bg-secondary" *ngSwitchCase="1" tooltip="{{ 1 | displayEnum:'SurgeryScheduleStatus' }}">P</span>
          <span class="text-white ms-1 px-2 bg-success" *ngSwitchCase="2" tooltip="{{ 2 | displayEnum:'SurgeryScheduleStatus' }}">S</span>
          <span class="text-white ms-1 px-2 bg-danger" *ngSwitchCase="3" tooltip="{{ 3 | displayEnum:'SurgeryScheduleStatus' }}">C</span>
        </span>
        <span class="small" class="text-white ms-1 px-2 bg-info" *ngIf="surgery.caseType === 2" tooltip="{{ 2 | displayEnum:'SurgeryCaseType' }}">A</span>
        <span class="small" [ngSwitch]="surgery.scheduleType">
          <span class="text-white ms-1 px-2 bg-primary" *ngSwitchCase="1" tooltip="{{ 1 | displayEnum:'SurgeryScheduleType' }}">L</span>
          <span class="text-white ms-1 px-2 bg-warning" *ngSwitchCase="2" tooltip="{{ 2 | displayEnum:'SurgeryScheduleType' }}">U</span>
          <span class="text-white ms-1 px-2 bg-danger" *ngSwitchCase="3" tooltip="{{ 3 | displayEnum:'SurgeryScheduleType' }}">E</span>
        </span>
        <!-- <span class="ms-1 px-2 case-periop-state-color" [ui-surgery-state-style]="{ status: surgery.scheduleStatus, state: surgery.periOpState, external: false }" *ngIf="surgery.periOpState">{{ surgery.periOpState | displayEnum:'PerioperativeState' }}</span> -->
      </div>
      <div class="col-sm-12 col-md small text-md-right">
        <ng-container *ngIf="surgery.actual.enterOR">{{ (surgery.actual.enterOR  | amFromUnix) | amDateFormat:'YYYY-MM-DD HH:mm' }}</ng-container>
        <ng-container *ngIf="!surgery.actual.enterOR && surgery.expected.enterOR">{{ (surgery.expected.enterOR  | amFromUnix) | amDateFormat:'YYYY-MM-DD HH:mm' }}</ng-container>
        <ng-container *ngIf="!surgery.actual.enterOR && !surgery.expected.enterOR && surgery.surgeryDate">{{ (surgery.surgeryDate  | amFromUnix) | amDateFormat:'YYYY-MM-DD HH:mm' }}</ng-container>
      </div>
    </div>
    <div class="row small">
      <div class="col-sm-12 col-md text-truncate">
        <span *ngFor="let provider of surgery.provider">
          <ul class="comma-list" *ngIf="provider.roleStd === 1 && provider.rolePrimary">
            <li>{{ provider.firstName }} {{ provider.lastName }}</li>
            <li *ngFor="let credential of provider.credentials">{{ credential }}</li>
          </ul>
        </span>
      </div>
      <div class="col-sm-12 col-md text-md-right text-truncate">
        <ng-container [ngSwitch]="surgery.encounter.encounterClass">
          <span class="text-white ms-1 px-2 bg-info" *ngSwitchCase="1" tooltip="{{ 1 | displayEnum:'EncounterClass' }}">I</span>
          <span class="text-white ms-1 px-2 bg-info" *ngSwitchCase="2" tooltip="{{ 2 | displayEnum:'EncounterClass' }}">O</span>
          <span class="text-white ms-1 px-2 bg-info" *ngSwitchCase="3" tooltip="{{ 3 | displayEnum:'EncounterClass' }}">E</span>
        </ng-container>
        {{ surgery.patient.firstName | slice:0:1 }}{{ surgery.patient.lastName | slice:0:1 }} - {{ surgery.patient.birthday | age }}{{ surgery.patient.gender | displayEnum:'Gender' | slice:0:1 }}
      </div>
    </div>
    <div class="row small">
      <div class="col-sm-12 col-md text-truncate">
        <span *ngFor="let procedure of surgery.procedure"><span *ngIf="procedure.primary">{{ procedure.name }}</span></span>
      </div>
    </div>
  </div>
</ng-template>
 
<input #input [(ngModel)]="searchStr"
  [typeahead]="searchObservable"
  [typeaheadItemTemplate]="customSurgeryCaseTemplate"
  (typeaheadLoading)="changeTypeaheadLoading($event)"
  [typeaheadMinLength]="0"
  (typeaheadOnSelect)="onSelect($event)"
  [typeaheadAsync]="true"
  typeaheadOptionField="caseId"
  placeholder="{{ placeholder }}"
  class="form-control" 
  [disabled]="disabled">
