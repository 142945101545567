import { Component, Input } from '@angular/core';
import { Breadcrumb } from '@app/data/navigation/navigation.model';

@Component({
  selector: 'generic-action-bar',
  styleUrls: [ './generic-action-bar.component.scss' ],
  templateUrl: './generic-action-bar.component.html'
})
export class GenericActionBarComponent {

  @Input() breadcrumbs: Array<Breadcrumb> = [];

  actionBarOpen = false;
}
