import {Pipe, PipeTransform} from '@angular/core';

/*
 * Returns the label of a record in a map looked up based on value
 *
 * Usage:
 *   value | mapLookup:map:label_getter
 * Example:
 *   {{ '1000' | mapLookup:providers:getProviderName }}
*/
@Pipe({name: 'mapLookup'})
export class MapLookupPipe implements PipeTransform {
  transform<T>(value: T | undefined, map: Map<T, any> | undefined, labelGetter: (arg0: any) => string, unknown: string = 'Unknown'): string {
    if (value === undefined) return unknown;
    if (map === undefined) return unknown;

    const obj = map.get(value);
    if (obj) {
      return labelGetter(obj);
    } else {
      return unknown;
    }
  }
}
