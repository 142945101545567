import { SurgerySpecialty } from "./org.model";
import { Procedure, ProcedureIdViewState } from "./procedure.model";

export class GetSpecialitiesResponse {
  orgLid: string;
  specialties: Array<SurgerySpecialty>;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.specialties = d.specialties.map((s: any) => new SurgerySpecialty(s));
  }
}

export class LookupProcedureResponse {
  procedure: Procedure;
  specialty: SurgerySpecialty | undefined;

  constructor(d: any) {
    this.procedure = new Procedure(d.procedure);
    this.specialty = d.specialty ? new SurgerySpecialty(d.specialty) : undefined;
  }
}

export class EditProcedureResponse {
  procedure: Procedure;
  procedureIds: ProcedureIdViewState;
  specialty: SurgerySpecialty | undefined;

  constructor(d: any) {
    this.procedure = new Procedure(d.procedure);
    this.procedureIds = d.procedure_ids.map((i: any) => new ProcedureIdViewState(i));
    this.specialty = d.specialty ? new SurgerySpecialty(d.specialty) : undefined;
  }
}

export class GetProceduresResponse {
  public procedures: Array<Procedure>;
  public specialties: Array<SurgerySpecialty>;
  public page: number;
  public hasMore: boolean;

  constructor(d: any) {
    this.procedures = d.procedures.map((p: any) => new Procedure(p));
    this.specialties = d.specialties.map((s: any) => new SurgerySpecialty(s));
    this.page = d.page;
    this.hasMore = d.has_more;
  }
}

export class GetProceduresCountResponse {
  public count: number;
  public pageSize: number;

  constructor(d: any) {
    this.count = d.count;
    this.pageSize = d.page_size;
  }
}