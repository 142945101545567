import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MomentModule } from 'ngx-moment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/framework/shared.module';

import { CommentModalComponent } from './comment-modal';
import { GenericActionBarComponent } from './generic-action-bar';
import { GenericHeaderComponent } from './generic-header';
import { SidebarComponent } from './sidebar';
import { MenuControlComponent } from './menu-control';
import { DatePickerComponent } from './date-picker';
import { DateRangePickerComponent } from './daterange-picker';
import { PeriodPickerComponent } from './period-picker';
import { ProcedureSearchComponent } from './procedure-search';
import { ProviderSearchComponent } from './provider-search';
import { RolePickerComponent } from './role-picker';
import { RoomGroupPickerComponent } from './room-group-picker';
import { SurgeryCaseSearchComponent } from './surgery-case-search/surgery-case-search.component';
import { SpecialtyPickerComponent } from './specialty-picker/specialty-picker.component';
import { HelpScoutComponent } from './helpscout';
import { FooterComponent } from './footer';
import { NewsHeadlineComponent } from './news-headline';
import { ContextMenuControlComponent } from './context-menu-control';
import { PdfViewerModalComponent } from './pdf-viewer-modal';
import { UISurgeryLabelComponent } from './surgery-label/surgery-label.component';
import { UISurgeryPatientInfoComponent } from './surgery-patient-info/surgery-patient-info.component';
import { UISurgeryProviderInfoComponent } from './surgery-provider-info/surgery-provider-info.component';
import { UISurgeryRoomGroupNameComponent } from './surgery-room-group-name/surgery-room-group-name.component';
import { UISurgeryRoomMultiPickerComponent } from './surgery-room-multi-picker/room-multi-picker.component';
import { UISurgeryStateLabelComponent } from './surgery-state-label/surgery-state-label.component';
import { UISurgeryStateStyleDirective } from './surgery-state-style/surgery-state-style.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MomentModule,
    NgxPermissionsModule.forChild(),
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    TranslateModule,

    SharedModule,
  ],
  declarations: [
    CommentModalComponent,
    ContextMenuControlComponent,
    GenericActionBarComponent,
    GenericHeaderComponent,
    SidebarComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    PdfViewerModalComponent,
    PeriodPickerComponent,
    ProcedureSearchComponent,
    ProviderSearchComponent,
    RolePickerComponent,
    RoomGroupPickerComponent,
    SpecialtyPickerComponent,
    SurgeryCaseSearchComponent,
    FooterComponent,
    HelpScoutComponent,
    MenuControlComponent,
    NewsHeadlineComponent,
    UISurgeryLabelComponent,
    UISurgeryPatientInfoComponent,
    UISurgeryProviderInfoComponent,
    UISurgeryRoomGroupNameComponent,
    UISurgeryRoomMultiPickerComponent,
    UISurgeryStateLabelComponent,
    UISurgeryStateStyleDirective,
  ],
  exports: [
    CommentModalComponent,
    ContextMenuControlComponent,
    GenericActionBarComponent,
    GenericHeaderComponent,
    SidebarComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    PdfViewerModalComponent,
    PeriodPickerComponent,
    ProcedureSearchComponent,
    ProviderSearchComponent,
    RolePickerComponent,
    RoomGroupPickerComponent,
    SpecialtyPickerComponent,
    SurgeryCaseSearchComponent,
    FooterComponent,
    HelpScoutComponent,
    MenuControlComponent,
    NewsHeadlineComponent,
    UISurgeryLabelComponent,
    UISurgeryPatientInfoComponent,
    UISurgeryProviderInfoComponent,
    UISurgeryRoomGroupNameComponent,
    UISurgeryRoomMultiPickerComponent,
    UISurgeryStateLabelComponent,
    UISurgeryStateStyleDirective,
  ]
})
export class UIModule {
}
