import moment from 'moment';
import { SHORT_TIME_FORMAT, LONG_TIME_FORMAT, NUMERIC_DATE_FORMAT, SHORT_DATE_FORMAT, DATE_TIME_FORMAT } from '../timer/timer.service';

const CATEGORY_MAP: any = {
  1: 'info',
  2: 'warn',
  3: 'error',
  4: 'recommendation',
  5: 'prediction'
}

const DATA_KEY: any = {
  1: 'case_lid',
  2: 'case_id',
  3: 'room',
  4: 'primary_surgeon_last_name',
  5: 'primary_anesthesia_name',
  6: 'periop_state',
  7: 'reason',
  8: 'duration',
  9: 'scheduled',
  10: 'expected',
  11: 'new_room',
  12: 'new_start',
  13: 'other_case_lid',
  14: 'other_case_id',
  15: 'other_case_start',
  16: 'other_case_end',
  17: 'case_lids',
  18: 'case_ids',
  19: 'phone_number',
  20: 'provider_lid',
  21: 'provider_full_name',
  22: 'provider_role',
  23: 'note',
  24: 'periop_state_timestamp',
  25: 'periop_state_label',
  26: 'new_duration',
  27: 'label_lid',
  28: 'label_name',
  29: 'label_initial',
  30: 'label_color',
  31: 'message_lid',
  32: 'message_body',
  33: 'message_recipients',
  34: 'provider_role_name',
}

const SURGERY_ROLE_MAP: any = {
  1: 'Surgeon',
  2: 'Anesthesiologist',
  3: 'Scrub',
  4: 'Circulator',
  100: 'Other provider'
}

export class NewsActor {
  actorLid: string;
  name: string;

  constructor(d: any) {
    this.actorLid = d.actor_lid;
    this.name = d.name;
  }
}

export class News {
  newsFeedId: string;
  actor: NewsActor | undefined;
  data: any;
  type: number;
  category: string;
  fromNow: string;
  occurredTime: string;
  occurredDate: string;
  occurredDateTime: string;
  class: string | undefined;
  isNew: boolean = false;
  isCaseSpecific: boolean = true;
  occurred: any;

  private _getPeriOpStateLabel(state: number, labels: any) {
    if (labels && labels instanceof Array) {
      for(let label of labels) {
        if (label.state == state) {
          return label.internalName;
        }
      }
    }
    return "Unknown";
  }

  private _parseData(data: any, labels: any) {
    let _data: any = {};
    for (let key in data) {
      _data[DATA_KEY[key]] = data[key];
    }
    if (_data.expected) _data.surgery_time = moment.unix(_data.expected).format(SHORT_TIME_FORMAT);
    if (_data.new_start) {
      let _newStartBase = moment.unix(_data.new_start);
      _data.new_start_time = _newStartBase.format(SHORT_TIME_FORMAT);
      _data.new_start_date = _newStartBase.format(NUMERIC_DATE_FORMAT);
    }
    if (_data.case_ids) {
      _data.cases = _data.case_ids.split(',');
      _data.caseIds = _data.case_lids.split(',');
    }
    if (_data.periop_state) _data.op_state_label = _data.periop_state_label ? _data.periop_state_label : this._getPeriOpStateLabel(_data.periop_state, labels);
    if (_data.periop_state_timestamp) {
      let _periop_state_timestamp_time = moment.unix(_data.periop_state_timestamp)
      let today = moment().startOf('day');
      if (_periop_state_timestamp_time < today) {
        _data.periop_state_timestamp_label = _periop_state_timestamp_time.format(SHORT_TIME_FORMAT) + ' (' + _periop_state_timestamp_time.format(SHORT_DATE_FORMAT) + ')';
      } else {
        _data.periop_state_timestamp_label = _periop_state_timestamp_time.format(SHORT_TIME_FORMAT);
      }
    }
    if (!_data.provider_role_name && _data.provider_role) _data.provider_role_name = SURGERY_ROLE_MAP[_data.provider_role]
    return _data;
  }

  public updateTimeDisplay() {
    let diff = Math.floor(moment.duration(moment().diff(this.occurred)).asMinutes());
    this.isNew = diff <= 1
    this.fromNow = this.occurred.fromNow();
  }

  public convertTo(newsClass: string, data: any) {
    this.data = data
    this.class = newsClass
  }

  constructor(d: any, labels: any) {
    this.occurred = moment.unix(d.occurred);
    this.newsFeedId = d.news_feed_lid ? d.news_feed_lid : d.condensed_news_feed_lid;
    this.actor = d.actor ? new NewsActor(d.actor) : undefined;
    this.data = this._parseData(d.data, labels);
    this.type = d.type;
    if (this.type == 12) this.isCaseSpecific = false;
    this.category = CATEGORY_MAP[d.category];
    this.fromNow = this.occurred.fromNow();
    this.occurredTime = this.occurred.format(SHORT_TIME_FORMAT);
    this.occurredDate = this.occurred.format(SHORT_DATE_FORMAT);
    this.occurredDateTime = this.occurred.format(DATE_TIME_FORMAT);
    this.updateTimeDisplay();
  }

}

