import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { AgePipe } from './pipes/age.pipe';
import { BlockAssignmentPipe } from './pipes/block-assignment-pipe';
import { CapFirstPipe } from './pipes/cap-first-pipe';
import { DisplayAnalyticsGroupByEnumPipe } from './pipes/display-analytics-group-by-enum.pipe';
import { DisplayAnalyticsMetricEnumPipe } from './pipes/display-analytics-metric-enum.pipe';
import { AnalyticsSurgeryMetricDisplayPipe } from './pipes/analytics-surgery-metric-display.pipe';
import { DisplayEnumPipe } from './pipes/display-enum.pipe';
import { InstanceofPipe } from './pipes/instanceof.pipe';
import { FromUnixFormatPipe } from './pipes/am-from-unix-format.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MapLookupPipe } from './pipes/map-lookup.pipe';
import { MapLookupGenericPipe } from './pipes/map-lookup-generic.pipe';
import { MapLookupNumberPipe } from './pipes/map-lookup-number.pipe';
import { MapValuesPipe } from './pipes/map-values.pipe';
import { phoneNumberPipe } from './pipes/phone-number-pipe';
import { SafePipe } from './pipes/safe.pipe';
import { String2NumberPipe } from './pipes/string-to-number.pipe';
import { TranslateCut } from './pipes/translate-cut.pipe';
import { TruncatePipe } from './pipes/truncate-pipe';
import { OnlyDefinedPipe } from './pipes/only-defined.pipe';
import { ObjectExpressPipe } from './pipes/object-express.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AgePipe,
    BlockAssignmentPipe,
    CapFirstPipe,
    DisplayAnalyticsGroupByEnumPipe,
    DisplayAnalyticsMetricEnumPipe,
    AnalyticsSurgeryMetricDisplayPipe,
    DisplayEnumPipe,
    InstanceofPipe,
    FromUnixFormatPipe,
    JoinPipe,
    MapLookupPipe,
    MapLookupGenericPipe,
    MapLookupNumberPipe,
    MapValuesPipe,
    OnlyDefinedPipe,
    phoneNumberPipe,
    SafePipe,
    String2NumberPipe,
    TranslateCut,
    TruncatePipe,
    ObjectExpressPipe,
  ],
  exports: [
    AgePipe,
    BlockAssignmentPipe,
    CapFirstPipe,
    DisplayAnalyticsGroupByEnumPipe,
    DisplayAnalyticsMetricEnumPipe,
    AnalyticsSurgeryMetricDisplayPipe,
    DisplayEnumPipe,
    InstanceofPipe,
    FromUnixFormatPipe,
    JoinPipe,
    MapLookupPipe,
    MapLookupGenericPipe,
    MapLookupNumberPipe,
    MapValuesPipe,
    OnlyDefinedPipe,
    phoneNumberPipe,
    SafePipe,
    String2NumberPipe,
    TranslateCut,
    TruncatePipe,
    ObjectExpressPipe,
  ]
})
export class SharedModule{
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule
    };
}
}