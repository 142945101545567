import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, ParamMap, Params } from "@angular/router";

import { Subscription, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Breadcrumb } from '../../data/navigation/navigation.model';
import { AppState } from '../../app.service';

export enum QueryParamKey {
  Date = 'date',
  Fullscreen = 'fs',
  RoomGroup = 'groupLid',
  ShowUnassigned = 'sa',
  StatusFilter = 'csf',
  OrderByState = 'obs',
  SideBarConfig = 'sbc',
  ColumnSize = 'cs', // 1-xs 2-sm 3-md 4-lg 5-xl
}

@Directive()
export abstract class BasePageComponent implements OnInit, OnDestroy {
  public isSideBarOpened = false;
  public isLoaded = false;
  public breadcrumbs: Array<Breadcrumb> = [];
  
  protected _orgLid!: string;
  protected _baseUrl: string = '';
  protected _title: string = '';
  protected navSub?: Subscription;

  protected pageParams: Params = {};
  protected pageQueryParams: Params = {};
  protected pageFragment: string | null = null;

  public onAppClicked() {
    this.isSideBarOpened = false;
  }

  public onHeaderMenuClicked() {
    this.isSideBarOpened = !this.isSideBarOpened;
  }

  constructor(
    protected _titleService: Title,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _translate: TranslateService,
    protected _appState: AppState,
  ){
    this._orgLid = this._appState.get('orgId');
  }

  public ngOnInit() {
    this._orgLid = this._appState.get('orgId');
    this._titleService.setTitle(this._title);
    
    if (this.navSub) this.navSub.unsubscribe();
    this.navSub = combineLatest([this._route.params, this._route.queryParamMap, this._route.fragment]).subscribe(([params, queryParams, fragment]) => {
      this.pageParams = params;
      this.pageQueryParams = {};
      queryParams.keys.forEach(key => {
        this.pageQueryParams[key] = queryParams.get(key);
      });
      this.pageFragment = fragment;
      this._getParamsFromUrl(params, queryParams, fragment);
      this._pageInit();
      this.isLoaded = true;
    });
  }

  ngOnDestroy() {
    if (this.navSub) this.navSub.unsubscribe();
    this._pageDestroy();
  }

  protected _pageInit() {}
  protected _pageDestroy() {}
  protected _getParamsFromUrl(params: Params, queryParams: ParamMap, fragment: string | null) {}
  protected _buildParamsForUrl(): Array<string> {
    return [];
  }
  protected _buildFragmentForUrl(): string | undefined {
    return undefined;
  }

  protected updateUrl() {
    let params = this._buildParamsForUrl();
    let fragment = this._buildFragmentForUrl();
    
    let queryString = '';
    if (params.length > 0) {
      queryString = `?${params.join('&')}`;
    }

    let fragmentString = '';
    if (fragment && fragment.trim.length > 0) {
      fragmentString = `#${fragment}`;
    }

    const url = `${this._baseUrl}${queryString}${fragmentString}`;
    this._router.navigateByUrl(url);
  }
}