<nav class="navbar fixed-top navbar-expand-lg generic-app-header" data-bs-theme="dark">
  <app-menu-control (onClicked)="menuClicked()" [isOpened]="isOpened"></app-menu-control>
  <a class="navbar-brand logo-{{appId.id}}" [routerLink]="appId.getRouterLink()"></a>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav">
      <li class="nav-item me-auto">
        <span class="nav-link text-white">&nbsp;</span>
      </li>
    </ul>
  </div>
</nav>