<div class="app-sidebar" [ngClass]="{ 'opened': isOpened }">
  <div class="sidebar-container">
    <p class="title">{{ 'UI.SIDEBAR.APPLICATIONS' | translate }}</p>
    <ul class="app-list">
      <li *ngxPermissionsOnly="['SurgerySidebarMenu']">
        <a [routerLink]=" ['/', 'surgery'] ">
          <i class="surgery-app-icon"></i>
          <span class="app-name">{{ 'UI.SIDEBAR.SURGERY' | translate }}</span>
        </a>
      </li>
      <li *ngxPermissionsOnly="['BoardSidebarMenu']">
        <a [routerLink]=" ['/board'] ">
          <i class="board-app-icon"></i>
          <span class="app-name">{{ 'UI.SIDEBAR.BOARD' | translate }}</span>
        </a>
      </li>
      <li *ngxPermissionsOnly="['ShiftSidebarMenu']">
        <a [routerLink]=" ['/shift'] ">
          <i class="shift-app-icon"></i>
          <span class="app-name">{{ 'UI.SIDEBAR.SHIFT' | translate }}</span>
        </a>
      </li>
      <li *ngxPermissionsOnly="['AnalyticsSidebarMenu']">
        <a [routerLink]=" ['/analytics'] ">
          <i class="analytics-app-icon"></i>
          <span class="app-name">{{ 'UI.SIDEBAR.ANALYTICS' | translate }}</span>
        </a>
      </li>
      <li *ngxPermissionsOnly="['ManagerSidebarMenu']">
        <a [routerLink]=" ['/management'] ">
          <i class="shift-app-icon"></i>
          <span class="app-name">{{ 'UI.SIDEBAR.MANAGEMENT' | translate }}</span>
        </a>
      </li>
    </ul>
    
    <div class="container bottom-section">
      <div class="row">
        <div class="col-9 text-white">
          V {{ appVersion }}
        </div>
        <div class="col-3">
          <a class="text-white" [routerLink]="['/', 'status']">{{ 'UI.SIDEBAR.STATUS' | translate }}</a>
        </div>
      </div>
      <div class="row py-2 border-top border-white">
        <div class="col-9 text-truncate">
          <a class="btn btn-light btn-sm w-100" *ngIf="myProvider" [routerLink]="['/', 'entity', 'provider', myProvider.providerLid]">
            {{ myProvider.fullName }}
          </a>
        </div>
        <div class="col-3">
          <button class="btn btn-danger btn-sm" (click)="logout()">
            <i class="fa fa-power-off" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>