import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { AppState } from '@app/app.service';
import { AuthService } from '@app/data/auth/auth.service';
import { Provider } from '@app/data/provider/provider.model';

// @ts-ignore
import packageObj from '../../../../package.json';

@Component({
  selector: 'app-sidebar',
  styleUrls: [ './sidebar.component.scss' ],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnChanges {

  @Input() isOpened?: boolean;
  
  public appVersion: string;
  public myProvider?: Provider;

  constructor(
    private _appState: AppState,
    private _authService: AuthService,
  ){
    this.appVersion = packageObj.version;
  }

  logout() {
    this._authService.logout();
  }

  ngOnInit(){
    this._authService.getOrg().then(org => {
      if (org) {
        this.myProvider = org.provider;
      }
    },
    (err) => {
      console.error(err);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpened']) {
      this.isOpened = changes['isOpened'].currentValue;
    }
  }

}
