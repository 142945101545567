import moment from 'moment';
import { SHORT_TIME_FORMAT } from '../timer/timer.service';
import { PatientId } from '../patient/patient.model';
import { SurgeryLocationAssignment } from './surgery-area.model';
import { Procedure } from '../org/procedure.model';
import { jsonMember, jsonObject } from 'typedjson';

export class SurgeryEncounter {
  encounterLid: string;
  historyLid: string;
  orgLid: string;
  patientLid: string;
  patientAddressLid: string;
  patientMaritalStatus: number;
  patientHeight: number;
  patientWeight: number;
  patientBMI: number;
  encounterId: string;
  encounterClass: number;
  status: number;
  start: number;
  arrived: number;
  transferred: number;
  discharged: number;
  locationType: string;
  locationFacility: string;
  locationDepartment: string;
  locationRoom: string;
  locationBed: string;
  encounterReason: string;
  cancelReason: string;
  noShowReason: string;
  primaryInsurancePlanId: string;
  dischargeStatusLid: string;
  dischargeLocationType: string;
  dischargeLocationFacility: string;
  dischargeLocationDepartment: string;
  dischargeLocationRoom: string;
  lengthOfStay: number;

  public getLocationDisplay() {
    let parts = [];
    if (this.locationDepartment) parts.push(this.locationDepartment);
    if (this.locationRoom) parts.push(this.locationRoom);
    if (this.locationBed) parts.push(this.locationBed);
    return parts.join(' / ');
  }

  constructor(d: any) {
    this.encounterLid = d.encounter_lid;
    this.historyLid = d.history_lid;
    this.orgLid = d.org_lid;
    this.patientLid = d.patient_lid;
    this.patientAddressLid = d.patient_address_lid;
    this.patientMaritalStatus = d.patient_marital_status;
    this.patientHeight = d.patient_height;
    this.patientWeight = d.patient_weight;
    this.patientBMI = d.patient_bmi;
    this.encounterId = d.encounter_id;
    this.encounterClass = d.encounter_class;
    this.status = d.status;
    this.start = d.start;
    this.arrived = d.arrived;
    this.transferred = d.transferred;
    this.discharged = d.discharged;
    this.locationType = d.location_type;
    this.locationFacility = d.location_facility;
    this.locationDepartment = d.location_department;
    this.locationRoom = d.location_room;
    this.locationBed = d.location_bed;
    this.encounterReason = d.encounter_reason;
    this.cancelReason = d.cancel_reason;
    this.noShowReason = d.no_show_reason;
    this.primaryInsurancePlanId = d.primary_insurance_plan_id;
    this.dischargeStatusLid = d.discharge_status_lid;
    this.dischargeLocationType = d.discharge_location_type;
    this.dischargeLocationFacility = d.discharge_location_facility;
    this.dischargeLocationDepartment = d.discharge_location_department;
    this.dischargeLocationRoom = d.discharge_location_room;
    this.lengthOfStay = d.length_of_stay;
  }
}

export class SurgeryPatient {
  patientLid: string;
  orgLid: string;
  firstName: string;
  lastName: string;
  birthday: string;
  age: number;
  gender: number;
  allergy: number;
  ids: Array<PatientId>;

  getInitials(): string {
    return `${this.firstName ? this.firstName[0] : ''}${this.lastName ? this.lastName[0] : ''}`;
  }

  getFullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  constructor(d: any) {
    this.patientLid = d.patient_lid;
    this.orgLid = d.org_lid;
    this.firstName = d.first_name;
    this.lastName = d.last_name;
    this.birthday = d.birthday;
    this.age = d.age;
    this.gender = d.gender;
    this.allergy = d.allergy;
    this.ids = d.ids.map((i: any) => new PatientId(i));
  }
}

export class SurgeryProcedure {
  caseLid: string;
  procedureLid: string;
  primary: boolean;
  detail: string;
  modifiers: Array<string>;
  anesthesiaType: number | undefined;
  woundClass: number | undefined;

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.procedureLid = d.procedure_lid;
    this.primary = d.primary;
    this.detail = d.detail;
    this.modifiers = d.modifiers;
    this.anesthesiaType = d.anesthesia_type ? d.anesthesia_type : undefined;
    this.woundClass = d.wound_class ? d.wound_class : undefined;
  }
}

export class SurgeryProcedureViewState {
  surgeryProcedure: SurgeryProcedure;
  procedure: Procedure;

  constructor(surgeryProcedure: SurgeryProcedure, procedure: Procedure) {
    this.surgeryProcedure = surgeryProcedure;
    this.procedure = procedure;
  }
}

export class SurgeryProviderWithRole {
  caseProviderLid: string;
  caseLid: string;
  providerLid: string;
  roleLid: string;
  orgLid: string;
  roleName: string;
  displayRank: number;
  roleStandard: number | undefined;
  primary: boolean;

  constructor(d: any) {
    this.caseProviderLid = d.case_provider_lid;
    this.caseLid = d.case_lid;
    this.providerLid = d.provider_lid;
    this.roleLid = d.role_lid;
    this.orgLid = d.org_lid;
    this.roleName = d.role_name;
    this.displayRank = d.display_rank;
    this.roleStandard = d.role_standard;
    this.primary = d.primary;
  }
}

export class SurgeryProviderRole {
  providerRoleLid: string;
  orgLid: string;
  name: string;
  displayRank: number;
  roleStandard: number | undefined;
  primary: boolean;

  constructor(d: any) {
    this.providerRoleLid = d.provider_role_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.displayRank = d.display_rank;;
    this.roleStandard = d.role_standard;
    this.primary = d.primary;
  }
}

export class SurgeryNote {
  noteLid: string;
  authorLid: string;
  body: string;
  created: moment.Moment;

  constructor(d: any) {
    this.noteLid = d.note_lid;
    this.authorLid = d.author_lid;
    this.body = d.body;
    this.created = moment.unix(d.created);
  }
}

export class SurgeryCaseLabel {
  caseLabelLid: string;
  caseLid: string;
  labelLid: string;
  created: moment.Moment;

  constructor(d: any) {
    this.caseLabelLid = d.case_label_lid;
    this.caseLid = d.case_lid;
    this.labelLid = d.label_lid;
    this.created = moment.unix(d.created);
  }
}

export class SurgeryMilestones {
  checkedIn: moment.Moment | undefined = undefined;
  arriveSWA: moment.Moment | undefined = undefined;
  transReq: moment.Moment | undefined = undefined;
  arrivePreOp: moment.Moment | undefined = undefined;
  exitPreOp: moment.Moment | undefined = undefined;
  enterOR: moment.Moment | undefined = undefined;
  anesthesiaStart: moment.Moment | undefined = undefined;
  surgeryStart: moment.Moment | undefined = undefined;
  closeStart: moment.Moment | undefined = undefined;
  surgeryStop: moment.Moment | undefined = undefined;
  anesthesiaStop: moment.Moment | undefined = undefined;
  exitOR: moment.Moment | undefined = undefined;
  enterPACU1: moment.Moment | undefined = undefined;
  exitPACU1: moment.Moment | undefined = undefined;
  enterPACU2: moment.Moment | undefined = undefined;
  exitPACU2: moment.Moment | undefined = undefined;

  getState(s: number): moment.Moment | undefined {
    let ts: moment.Moment;
    switch(s) {
      case 1: return undefined;
      case 2: return this.checkedIn;
      case 3: return this.arriveSWA;
      case 4: return this.transReq;
      case 5: return this.arrivePreOp;
      case 6: return this.enterOR;
      case 7: return this.anesthesiaStart;
      case 8: return this.surgeryStart;
      case 9: return this.surgeryStop;
      case 10: return this.anesthesiaStop;
      case 11: return this.exitOR;
      case 12: return this.enterPACU1;
      case 13: return this.exitPACU1;
      case 14: return this.enterPACU2;
      case 15: return this.exitPACU2;
      case 16: return this.exitPreOp;
      case 17: return this.closeStart;
    }

    return undefined;
  }

  constructor(d: any) {
    if (d.checked_in) this.checkedIn = moment.unix(d.checked_in);
    if (d.swa_enter) this.arriveSWA = moment.unix(d.swa_enter);
    if (d.transport_requested) this.transReq = moment.unix(d.transport_requested);
    if (d.pre_op_enter) this.arrivePreOp = moment.unix(d.pre_op_enter);
    if (d.pre_op_exit) this.exitPreOp = moment.unix(d.pre_op_exit);
    if (d.room_enter) this.enterOR = moment.unix(d.room_enter);
    if (d.anesthesia_start) this.anesthesiaStart = moment.unix(d.anesthesia_start);
    if (d.surgery_start) this.surgeryStart = moment.unix(d.surgery_start);
    if (d.close_start) this.closeStart = moment.unix(d.close_start);
    if (d.surgery_stop) this.surgeryStop = moment.unix(d.surgery_stop);
    if (d.anesthesia_stop) this.anesthesiaStop = moment.unix(d.anesthesia_stop);
    if (d.room_exit) this.exitOR = moment.unix(d.room_exit);
    if (d.enter_pacu1) this.enterPACU1 = moment.unix(d.enter_pacu1);
    if (d.exit_pacu1) this.exitPACU1 = moment.unix(d.exit_pacu1);
    if (d.enter_pacu2) this.enterPACU2 = moment.unix(d.enter_pacu2);
    if (d.exit_pacu2) this.exitPACU2 = moment.unix(d.exit_pacu2);
  }
}

@jsonObject
export class SurgeryMilestonesNew {
  @jsonMember(Number, { name: 'checked_in' })
  checkedIn: number | undefined;

  @jsonMember(Number, { name: 'swa_enter' })
  arriveSWA: number | undefined;

  @jsonMember(Number, { name: 'transport_requested' })
  transReq: number | undefined;

  @jsonMember(Number, { name: 'pre_op_enter' })
  arrivePreOp: number | undefined;

  @jsonMember(Number, { name: 'pre_op_exit' })
  exitPreOp: number | undefined;

  @jsonMember(Number, { name: 'room_enter' })
  enterOR: number | undefined;

  @jsonMember(Number, { name: 'anesthesia_start' })
  anesthesiaStart: number | undefined;

  @jsonMember(Number, { name: 'surgery_start' })
  surgeryStart: number | undefined;

  @jsonMember(Number, { name: 'close_start' })
  closeStart: number | undefined;

  @jsonMember(Number, { name: 'surgery_stop' })
  surgeryStop: number | undefined;

  @jsonMember(Number, { name: 'anesthesia_stop' })
  anesthesiaStop: number | undefined;

  @jsonMember(Number, { name: 'room_exit' })
  exitOR: number | undefined;

  @jsonMember(Number, { name: 'enter_pacu1' })
  enterPACU1: number | undefined;

  @jsonMember(Number, { name: 'exit_pacu1' })
  exitPACU1: number | undefined;

  @jsonMember(Number, { name: 'enter_pacu2' })
  enterPACU2: number | undefined;

  @jsonMember(Number, { name: 'exit_pacu2' })
  exitPACU2: number | undefined;

  getState(s: number): number | undefined {
    switch(s) {
      case 1: return undefined;
      case 2: return this.checkedIn;
      case 3: return this.arriveSWA;
      case 4: return this.transReq;
      case 5: return this.arrivePreOp;
      case 6: return this.enterOR;
      case 7: return this.anesthesiaStart;
      case 8: return this.surgeryStart;
      case 9: return this.surgeryStop;
      case 10: return this.anesthesiaStop;
      case 11: return this.exitOR;
      case 12: return this.enterPACU1;
      case 13: return this.exitPACU1;
      case 14: return this.enterPACU2;
      case 15: return this.exitPACU2;
      case 16: return this.exitPreOp;
      case 17: return this.closeStart;
    }

    return undefined;
  }

  constructor(d: any) {
    if (d == undefined) return;

    if (d.checked_in) this.checkedIn = d.checked_in;
    if (d.swa_enter) this.arriveSWA = d.swa_enter;
    if (d.transport_requested) this.transReq = d.transport_requested;
    if (d.pre_op_enter) this.arrivePreOp = d.pre_op_enter;
    if (d.pre_op_exit) this.exitPreOp = d.pre_op_exit;
    if (d.room_enter) this.enterOR = d.room_enter;
    if (d.anesthesia_start) this.anesthesiaStart = d.anesthesia_start;
    if (d.surgery_start) this.surgeryStart = d.surgery_start;
    if (d.close_start) this.closeStart = d.close_start;
    if (d.surgery_stop) this.surgeryStop = d.surgery_stop;
    if (d.anesthesia_stop) this.anesthesiaStop = d.anesthesia_stop;
    if (d.room_exit) this.exitOR = d.room_exit;
    if (d.enter_pacu1) this.enterPACU1 = d.enter_pacu1;
    if (d.exit_pacu1) this.exitPACU1 = d.exit_pacu1;
    if (d.enter_pacu2) this.enterPACU2 = d.enter_pacu2;
    if (d.exit_pacu2) this.exitPACU2 = d.exit_pacu2;
  }
}

export class Surgery {
  caseLid: string;
  orgLid: string;
  siteLid: string;
  encounter: SurgeryEncounter | undefined;
  caseId: string;
  caseType: number;
  scheduleType: number;
  scheduleStatus: number;
  patient: SurgeryPatient | undefined;
  specialtyLid: number;
  procedureLids: Array<string>;
  procedures: Map<string, SurgeryProcedure>;
  primarySurgeonLid: string;
  primaryAnesthesiaLid: string;
  displayProviderLids: Array<string>;
  dateOfSurgery: moment.Moment;
  localDateOfSurgery: string;
  localTimeOfSurgery: string;
  roomLid: string;
  periOpState: number;
  duration: number;
  scheduledDuration: number;
  notes: Array<SurgeryNote> | undefined;
  labels: Array<SurgeryCaseLabel> | undefined;
  locationAssignments: Array<SurgeryLocationAssignment> | undefined;
  scheduled: moment.Moment | undefined;
  setupDuration: number | undefined;
  cleanupDuration: number | undefined;
  delayed: boolean;
  delayMinutes: number;
  expected: SurgeryMilestones | undefined;
  actual: SurgeryMilestones | undefined;
  robotic: boolean;
  timezone: string;

  scheduledExitOR: moment.Moment;

  isPerioperativeStateActual(state: number): boolean {
    if (this.scheduleStatus === 1) return true;

    const actual = this.actual?.getState(state);
    if (actual) {
      return true;
    }

    return false;
  }

  getPerioperativeStateLabel(state: number): string {
    if (this.scheduleStatus === 1) return '';

    const actual = this.actual?.getState(state);
    if (actual) {
      return actual.format(SHORT_TIME_FORMAT);
    } else {
      const expected = this.expected?.getState(state);
      if (expected) {
        return expected.format(SHORT_TIME_FORMAT) + ' E';
      }

      return '';
    }
  }

  getRoomEnterEstimate(maskPending = true): moment.Moment | undefined {
    if (maskPending && this.scheduleStatus === 1) {
      return undefined;
    }

    if (this.actual && this.actual.enterOR) {
      return this.actual.enterOR
    } else if (this.expected && this.expected.enterOR) {
      return this.expected.enterOR;
    } else {
      return this.dateOfSurgery;
    }
  }

  getPrimaryProcedure(): SurgeryProcedure | undefined {
    if (this.procedureLids && this.procedureLids.length > 0) {
      return this.procedures.get(this.procedureLids[0]);
    }

    return undefined;
  }

  getPrimaryProcedureForDisplay(maxLength: number | undefined = undefined): string {
    const primary = this.getPrimaryProcedure();
    if (primary) {
      const parts = new Array<string>();
      parts.push(primary.detail)
      if (primary.modifiers && primary.modifiers.length > 0) {
        parts.push(primary.modifiers.join(', '))
      }

      return maxLength ? parts.join(' / ').substr(0, maxLength) : parts.join(' / ');
    }

    return maxLength ? 'Unknown'.substr(0, maxLength) : 'Unknown';
  }

  getPeriOpStateInternalName(periopStateLabels: Array<PerioperativeStateLabel>): string {
    const label = periopStateLabels.find(l => l.state === this.periOpState)
    if (label) {
      return label.internalName
    }

    return 'Unknown';
  }

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    if (d.encounter) this.encounter = new SurgeryEncounter(d.encounter);
    this.caseId = d.case_id;
    this.caseType = d.case_type;
    this.scheduleType = d.schedule_type;
    this.scheduleStatus = d.schedule_status;
    if (d.patient) this.patient = new SurgeryPatient(d.patient);
    this.specialtyLid = d.specialty_lid;
    this.procedureLids = d.procedure_lids;
    this.procedures = new Map<string, SurgeryProcedure>();
    for(let p in d.procedures) {
      this.procedures.set(p, new SurgeryProcedure(d.procedures[p]));
    }
    this.primarySurgeonLid = d.primary_surgeon_lid;
    this.primaryAnesthesiaLid = d.primary_anesthesia_lid;
    this.displayProviderLids = d.display_provider_lids;
    this.dateOfSurgery = moment.unix(d.date_of_surgery);
    this.localDateOfSurgery = d.local_date_of_surgery;
    this.localTimeOfSurgery = d.local_time_of_surgery;
    this.roomLid = d.room_lid;
    this.periOpState = d.peri_op_state;
    this.duration = d.duration;
    this.scheduledDuration = d.scheduled_duration;
    if (d.notes) this.notes = d.notes.map((n: any) => new SurgeryNote(n));
    if (d.labels) this.labels = d.labels.map((l: any) => new SurgeryCaseLabel(l));
    if (d.location_assignments) this.locationAssignments = d.location_assignments.map((a: any) => new SurgeryLocationAssignment(a));
    if (d.scheduled) this.scheduled = moment.unix(d.scheduled);
    this.setupDuration = d.setup_duration;
    this.cleanupDuration = d.cleanup_duration;
    this.delayed = d.delayed;
    this.delayMinutes = d.delay_minutes;
    if (d.expected) this.expected = new SurgeryMilestones(d.expected);
    if (d.actual) this.actual = new SurgeryMilestones(d.actual);
    this.robotic = d.robotic;
    this.timezone = d.timezone;

    this.scheduledExitOR = this.dateOfSurgery.clone().add(this.scheduledDuration, 'm');
  }
}

export class PerioperativeStateLabel {
  state: number;
  internalName: string;
  internalColorText: string;
  internalColorBackground: string;
  externalName: string;
  externalColorText: string;
  externalColorBackground: string;

  constructor(d: any) {
    this.state = d.state;
    this.internalName = d.internal_name;
    this.internalColorText = d.internal_color_text;
    this.internalColorBackground = d.internal_color_background;
    this.externalName = d.external_name;
    this.externalColorText = d.external_color_text;
    this.externalColorBackground = d.external_color_background;
  }
}

export class PerioperativeStateLabelMat {
  label: PerioperativeStateLabel;
  actual: boolean;

  constructor(label: PerioperativeStateLabel, actual: boolean) {
    this.label = label;
    this.actual = actual;
  }
}

export class SurgeryLabel {
  labelLid: string;
  orgLid: string;
  name: string;
  initial: string;
  color: string;
  status: number;
  displayRank: number;

  constructor(d: any) {
    this.labelLid = d.label_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.initial = d.initial;
    this.color = '#' + d.color;
    this.status = d.status;
    this.displayRank = d.display_rank;
  }
}

export class SurgeryCase {
  caseLid: string;
  orgLid: string;
  caseId: string;
  caseType: number;
  scheduleType: number;
  scheduleStatus: number;
  siteLid: string;
  encounterLid: string;
  patientLid: string;
  specialtyLid: string | undefined;
  procedureLids: Array<string>;
  primarySurgeonLid: string | undefined;
  primaryAnesthesiaLid: string | undefined;
  displayProviderLids: Array<string>;
  dateOfSurgery: number;
  roomLid: string | undefined;
  periOpState: number;
  duration: number;
  scheduledDuration: number;
  scheduled: number | undefined;
  delayed: boolean;
  expected: SurgeryMilestones | undefined;
  actual: SurgeryMilestones | undefined;

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.orgLid = d.org_lid;
    this.caseId = d.case_id;
    this.caseType = d.case_type;
    this.scheduleType = d.schedule_type;
    this.scheduleStatus = d.schedule_status;
    this.siteLid = d.site_lid;
    this.encounterLid = d.encounter_lid;
    this.patientLid = d.patient_lid;
    this.specialtyLid = d.specialty_lid;
    this.procedureLids = d.procedure_lids;
    this.primarySurgeonLid = d.primary_surgeon_lid;
    this.primaryAnesthesiaLid = d.primary_anesthesia_lid;
    this.displayProviderLids = d.display_provider_lids;
    this.dateOfSurgery = d.date_of_surgery;
    this.roomLid = d.room_lid;
    this.periOpState = d.peri_op_state;
    this.duration = d.duration;
    this.scheduledDuration = d.scheduled_duration;
    this.scheduled = d.scheduled;
    this.delayed = d.delayed;
    this.expected = d.expected ? new SurgeryMilestones(d.expected) : undefined;
    this.actual = d.actual ? new SurgeryMilestones(d.actual) : undefined;
  }
}

export class SurgeryCaseAvailability {
  caseLid: string;
  surgeonLid: string;
  roomLid: string;
  dateOfSurgery: string;
  from: string;
  to: string;
  primeTimeLid: string | undefined;
  blockLid: string | undefined;
  blockAssignmentLid: string | undefined;
  enclosesCurrentScheduled: boolean;
  enclosesCurrentExpected: boolean;

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.surgeonLid = d.surgeon_lid;
    this.roomLid = d.room_lid;
    this.dateOfSurgery = d.date_of_surgery;
    this.from = d.from;
    this.to = d.to;
    this.primeTimeLid = d.prime_time_lid;
    this.blockLid = d.block_lid;
    this.blockAssignmentLid = d.block_assignment_lid;
    this.enclosesCurrentScheduled = d.encloses_current_scheduled;
    this.enclosesCurrentExpected = d.encloses_current_expected;
  }
}

export class SurgeryCaseCancel {
  caseLid: string;
  reasonLid: string | undefined;
  noteLid: string | undefined;
  canceledBy: string | undefined;

  constructor(d: any) {
    this.caseLid = d.case_lid;
    this.reasonLid = d.reason_lid;
    this.noteLid = d.note_lid;
    this.canceledBy = d.canceled_by;
  }
}

export class SurgeryOrgSettings {
  orgLid: string;
  scheduleEval: boolean;
  directBooking: boolean;
  directCharting: boolean;
  estimateTurnover: boolean;
  defaultTurnover: number;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.scheduleEval = d.schedule_eval;
    this.directBooking = d.direct_booking;
    this.directCharting = d.direct_charting;
    this.estimateTurnover = d.estimate_turnover;
    this.defaultTurnover = d.default_turnover;
  }
}

export class DelayReasonCategory {
  categoryLid: string;
  orgLid: string;
  cancelType: number;
  name: string
  
  constructor(d: any) {
    this.categoryLid = d.category_lid;
    this.orgLid = d.org_lid;
    this.cancelType = d.cancel_type;
    this.name = d.name;
  }
}

export class DelayReason {
  reasonLid: string;
  orgLid: string;
  reasonName: string;
  status: number;
  
  constructor(d: any) {
    this.reasonLid = d.reason_lid;
    this.orgLid = d.org_lid;
    this.reasonName = d.reason_name;
    this.status = d.status;
  }
}