import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'amFromUnixFormat' })
export class FromUnixFormatPipe implements PipeTransform {
  transform(value: number | string | undefined, format: string, unknown: string = ''): any {
    if (value === undefined) {
      return unknown;
    }

    const m = typeof value === 'string' ? moment.unix(parseInt(value, 10)) : moment.unix(value);
    return m.format(format);
  }
}
