import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GetSurgeryBlockResponse, GetSurgeryBlocksForOrgResponse, GetSurgeryBlockAssignmentOnDateResponse, SurgeryBlockAssignment, SurgeryBlockProvider, AddOrUpdateSurgeryBlockProviderResponse, SurgeryBlockManager, AddSurgeryBlockManagerResponse, SurgeryBlock, Site, GetSurgeryBlockMatsResponse, GetSurgeryBlockReleasesResponse, AddSurgeryBlockReleaseResponse, CancelReason } from './org.model';
import { ORG_API_URL, DataService } from '../data.service';
import { CustomQueryEncoderHelper } from '../data.model';
import { Vendor } from './vendor.model';
import { GetSupplyItemResponse } from './supply.model';
import { AddSurgeryPrimeTimeReleaseResponse } from '../room/room-response.model';
import { EditProcedureResponse, GetProceduresCountResponse, GetProceduresResponse, GetSpecialitiesResponse, LookupProcedureResponse } from './org.message';
import { ProcedureWithIds } from './procedure.model';
import { LocalDateRange } from '../common.model';
import { AddSurgeryBlockManualCreditResponse, GetSurgeryBlockManualCreditsByBlockCountResponse, GetSurgeryBlockManualCreditsByBlockResponse } from './org.surgery.block.message';
import { SurgeryBlockUtilizationConfig } from './org.surgery.block.model';
import { PerioperativeStateLabel } from '../case/surgery.model';

@Injectable()
export class OrgService {

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService
  ) {};

  public getSites(orgLid: string): Observable<Array<Site>> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/site`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => response['data'].map((s: any) => new Site(s))),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addOrUpdateSurgeryBlock(block: SurgeryBlock): Observable<SurgeryBlock> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        name: block.name,
        status: block.status.toString(),
      }
    });

    if (block.voluntaryRelease) {
      preparedParams = preparedParams.append('voluntary_release_type', block.voluntaryRelease.type.toString());
      preparedParams = preparedParams.append('voluntary_release_cutoff', block.voluntaryRelease.getCutoffString());
      preparedParams = preparedParams.append('voluntary_release_value', block.voluntaryRelease.value.toString());
    }

    if (block.automaticRelease) {
      preparedParams = preparedParams.append('automatic_release_type', block.automaticRelease.type.toString());
      preparedParams = preparedParams.append('automatic_release_cutoff', block.automaticRelease.getCutoffString());
      preparedParams = preparedParams.append('automatic_release_value', block.automaticRelease.value.toString());
    }

    let remoteCall: Observable<Object>;
    if (block.blockLid) {
      remoteCall = this._httpClient.post(`${ORG_API_URL}/${block.orgLid}/surgery/block/${block.blockLid}`, preparedParams, this._dataService.getApiRequestHeader());
    } else {
      remoteCall = this._httpClient.put(`${ORG_API_URL}/${block.orgLid}/surgery/block`, preparedParams, this._dataService.getApiRequestHeader());
    }
    return remoteCall.pipe(
        map((response: any) => new SurgeryBlock(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlock(orgLid: string, blockLid: string): Observable<GetSurgeryBlockResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetSurgeryBlockResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlockMats(orgLid: string, blockLid: string, startDate: string, days: number): Observable<GetSurgeryBlockMatsResponse> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        start_date: startDate,
        days: days.toString(),
      }
    });

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/materialize`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new GetSurgeryBlockMatsResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlocksForOrg(orgLid: string, groupLid: string | undefined): Observable<GetSurgeryBlocksForOrgResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
    });
    if (groupLid) preparedParams = preparedParams.set('group_lid', groupLid);

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new GetSurgeryBlocksForOrgResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlockAssignmentOnDate(orgLid: string, blockLid: string, assignmentLid: string, date: string): Observable<GetSurgeryBlockAssignmentOnDateResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/on/${date}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetSurgeryBlockAssignmentOnDateResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addOrUpdateSurgeryBlockAssignment(assignment: SurgeryBlockAssignment): Observable<SurgeryBlockAssignment> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        status: assignment.status.toString(),
        rule_type: assignment.ruleType.toString(),
        weeks: assignment.getWeeksString(),
        dates: assignment.dates.join(','),
        days_of_week: assignment.getDaysOfWeekString(false),
        from: assignment.getFromString(),
        to: assignment.getToString(),
        room_lid: assignment.roomLid,
        effective_from: assignment.getEffectiveFrom()
      }
    });
    if (assignment.effectiveTo) preparedParams = preparedParams.append('effective_to', assignment.getEffectiveTo());
    let remoteCall: Observable<Object>;
    if (assignment.assignmentLid) {
      remoteCall = this._httpClient.post(`${ORG_API_URL}/${assignment.orgLid}/surgery/block/${assignment.blockLid}/assignment/${assignment.assignmentLid}`, preparedParams, this._dataService.getApiRequestHeader());
    } else {
      remoteCall = this._httpClient.put(`${ORG_API_URL}/${assignment.orgLid}/surgery/block/${assignment.blockLid}/assignment`, preparedParams, this._dataService.getApiRequestHeader());
    }
    return remoteCall.pipe(
        map((response: any) => new SurgeryBlockAssignment(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addOrUpdateSurgeryBlockProvider(blockProvider: SurgeryBlockProvider): Observable<AddOrUpdateSurgeryBlockProviderResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        status: blockProvider.status.toString(),
        provider_lid: blockProvider.providerLid,
        effective_from: blockProvider.getEffectiveFrom()
      }
    });
    if (blockProvider.effectiveTo) preparedParams = preparedParams.append('effective_to', blockProvider.getEffectiveTo());
    let remoteCall: Observable<Object>;
    if (blockProvider.blockProviderLid) {
      remoteCall = this._httpClient.post(`${ORG_API_URL}/${blockProvider.orgLid}/surgery/block/${blockProvider.blockLid}/provider/${blockProvider.blockProviderLid}`, preparedParams, this._dataService.getApiRequestHeader());
    } else {
      remoteCall = this._httpClient.put(`${ORG_API_URL}/${blockProvider.orgLid}/surgery/block/${blockProvider.blockLid}/provider`, preparedParams, this._dataService.getApiRequestHeader());
    }
    return remoteCall.pipe(
        map((response: any) => new AddOrUpdateSurgeryBlockProviderResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addSurgeryBlockManager(orgLid: string, manager: SurgeryBlockManager): Observable<AddSurgeryBlockManagerResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        manager_lid: manager.managerLid,
      }
    });
    return this._httpClient.put(`${ORG_API_URL}/${orgLid}/surgery/block/${manager.blockLid}/manager`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new AddSurgeryBlockManagerResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  removeSurgeryBlockManager(orgLid: string, blockLid: string, managerLid: string) {
    return this._httpClient.delete(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/manager/${managerLid}`, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addSurgeryBlockRelease(orgLid: string, blockLid: string, assignmentLid: string, date: string, from: string, to: string, notes: string): Observable<AddSurgeryBlockReleaseResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        from: from,
        to: to,
      }
    });
    if (notes) preparedParams = preparedParams.set('notes', notes);

    return this._httpClient.put(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/on/${date}/release`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new AddSurgeryBlockReleaseResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  removeSurgeryBlockRelease(orgLid: string, blockLid: string, assignmentLid: string, date: string, from: string, to: string) {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        date: date,
        from: from,
        to: to
      }
    });

    return this._httpClient.delete(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/release`, this._dataService.getApiRequestHeader(preparedParams, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlockReleases(orgLid: string, blockLid: string, startDate: string, days: number): Observable<GetSurgeryBlockReleasesResponse> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        start_date: startDate,
        days: days.toString(),
      }
    });

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/release`, this._dataService.getApiRequestHeader(preparedParams))
    .pipe(
      map((response: any) => new GetSurgeryBlockReleasesResponse(response['data'])),
      catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
    );
  }

  addSurgeryBlockManualCredit(orgLid: string, blockLid: string, assignmentLid: string, date: string, caseLid: string, percent: number, notes: string | undefined): Observable<AddSurgeryBlockManualCreditResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        date: date,
        case_lid: caseLid,
        percent: percent.toString(),
      }
    });
    if (notes) preparedParams = preparedParams.set('notes', notes);

    return this._httpClient.put(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/credit`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new AddSurgeryBlockManualCreditResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  deleteSurgeryBlockManualCredit(orgLid: string, blockLid: string, assignmentLid: string, creditLid: string) {
    return this._httpClient.delete(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/credit/${creditLid}`, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryBlockManualCreditsByBlock(orgLid: string, blockLid: string, creditForFromTo: LocalDateRange | undefined, page: number): Observable<GetSurgeryBlockManualCreditsByBlockResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        page: page.toString(),
      }
    });
    if (creditForFromTo) preparedParams = preparedParams.set('credit_for_from', creditForFromTo.start);
    if (creditForFromTo) preparedParams = preparedParams.set('credit_for_to', creditForFromTo.end);

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/credit`, this._dataService.getApiRequestHeader(preparedParams))
    .pipe(
      map((response: any) => new GetSurgeryBlockManualCreditsByBlockResponse(response['data'])),
      catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
    );
  }

  getSurgeryBlockManualCreditsByBlockCount(orgLid: string, blockLid: string, creditForFromTo: LocalDateRange | undefined): Observable<GetSurgeryBlockManualCreditsByBlockCountResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {}
    });
    if (creditForFromTo) preparedParams = preparedParams.set('credit_for_from', creditForFromTo.start);
    if (creditForFromTo) preparedParams = preparedParams.set('credit_for_to', creditForFromTo.end);

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/${blockLid}/credit/count`, this._dataService.getApiRequestHeader(preparedParams))
    .pipe(
      map((response: any) => new GetSurgeryBlockManualCreditsByBlockCountResponse(response['data'])),
      catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
    );
  }

  getSurgeryBlockUtilizationConfig(orgLid: string, groupLid: string | undefined): Observable<SurgeryBlockUtilizationConfig> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
    });
    if (groupLid) preparedParams = preparedParams.set('group_lid', groupLid);
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/block/utilization/config`, this._dataService.getApiRequestHeader(preparedParams))
    .pipe(
      map((response: any) => new SurgeryBlockUtilizationConfig(response['data'])),
      catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
    );
  }

  getVendor(orgLid: string, vendorLid: string): Observable<Vendor> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/vendor/${vendorLid}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new Vendor(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSupplyItem(orgLid: string, itemLid: string): Observable<GetSupplyItemResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/supply/item/${itemLid}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetSupplyItemResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getCancelReasons(orgLid: string, cancelType: number): Observable<Array<CancelReason>> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        cancel_type: cancelType.toString(),
      }
    });

    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/cancel_reason`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => response['data'].map((cr: any) => new CancelReason(cr))),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addSurgeryPrimeTimeRelease(orgLid: string, roomLid: string, date: string, from: string, to: string, notes: string): Observable<AddSurgeryPrimeTimeReleaseResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        date: date,
        from: from,
        to: to,
        credit_type: "1",
      }
    });
    if (notes) preparedParams = preparedParams.set('notes', notes);

    return this._httpClient.put(`${ORG_API_URL}/${orgLid}/surgery/room/${roomLid}/prime_time/release`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new AddSurgeryPrimeTimeReleaseResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  removeSurgeryPrimeTimeRelease(orgLid: string, roomLid: string, releaseLid: string) {
    return this._httpClient.delete(`${ORG_API_URL}/${orgLid}/surgery/room/${roomLid}/prime_time/release/${releaseLid}`, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public getSpecialities(orgLid: string): Observable<GetSpecialitiesResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/surgery/specialty`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetSpecialitiesResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public addProcedure(orgLid: string, name: string, specialtyLid: string, robotic: boolean): Observable<ProcedureWithIds> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        name: name,
        specialty_lid: specialtyLid,
        robotic: robotic,
      }
    });
    
    return this._httpClient.put(`${ORG_API_URL}/${orgLid}/procedure`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new ProcedureWithIds(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public editProcedure(orgLid: string, procedureLid: string, name: string, specialtyLid: string, robotic: boolean): Observable<EditProcedureResponse> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        name: name,
        specialty_lid: specialtyLid,
        robotic: robotic,
      }
    });
    
    return this._httpClient.post(`${ORG_API_URL}/${orgLid}/procedure/${procedureLid}`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new EditProcedureResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public lookupProcedure(orgLid: string, procedureLid: string): Observable<LookupProcedureResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/procedure/${procedureLid}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new LookupProcedureResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public getProcedures(orgLid: string, page: number): Observable<GetProceduresResponse> {
    const preparedParams = new HttpParams({ 
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        page: page.toString()
      }
    });
    
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/procedure`, this._dataService.getApiRequestHeader(preparedParams))
      .pipe(
        map((response: any) => new GetProceduresResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public getProceduresCount(orgLid: string): Observable<GetProceduresCountResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgLid}/procedure/count`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetProceduresCountResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }
}