import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@app/app.service';
import { OrgCacheService } from '@app/data/org-cache.service';

import { RoomGroup } from '@app/data/room/room.model';

@Component({
  selector: 'room-group-picker',
  styleUrls: [ './room-group-picker.component.scss' ],
  templateUrl: './room-group-picker.component.html',
})

export class RoomGroupPickerComponent implements OnInit {
  @Input() public currentGroupLid?: string;
  @Input() public allowedGroupLids?: Array<string> = undefined;
  @Output() public onRoomGroupSelected = new EventEmitter<string>(); 

  private _orgId!: string;
  
  public roomGroups?: RoomGroup[];
  public currentGroupName: string;

  private _allRoomsTranslation: string;

  constructor(
    private _appState: AppState,
    private _orgCacheService: OrgCacheService,
    private _translate: TranslateService,
  ){
    this._allRoomsTranslation = this._translate.instant('UI.ROOM_GROUP_PICKER.ALL_ROOMS');
    this.currentGroupName = this._allRoomsTranslation;
  }

  public selectGroup(groupLid: string) {
    if (groupLid === '0') {
      this.currentGroupName = this._allRoomsTranslation;
      this.onRoomGroupSelected.emit(undefined);
    } else {
      let selected = this.roomGroups?.find(g => g.groupId === groupLid);
      if (selected) {
        this.currentGroupName = selected.name;
        this.onRoomGroupSelected.emit(groupLid);
      }
    }
    return false;
  }

  private _getRoomGroups() {
    this._orgCacheService.getSurgeryRoomGroups(this._orgId).subscribe({
      next: (roomGroups: Array<RoomGroup>) => {
        if (this.allowedGroupLids === undefined) {
          this.roomGroups = roomGroups;
        } else {
          this.roomGroups = roomGroups.filter(g => this.allowedGroupLids?.indexOf(g.groupId) !== -1)
        }
        
        let selected = this.roomGroups.find(g => g.groupId === this.currentGroupLid);
        if (selected) {
          this.currentGroupName = selected.name;
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  ngOnInit() {
    this._orgId = this._appState.get('orgId');
    this._getRoomGroups();
  }
}