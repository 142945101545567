export class SurgeryArea {
  areaLid: string;
  orgLid: string;
  siteLid: string;
  areaName: string;
  inStates: Array<number>;

  constructor(d: any) {
    this.areaLid = d.area_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.areaName = d.area_name;
    this.inStates = d.in_states;
  }

  toJson(): any {
    return {
      area_lid: this.areaLid,
      org_lid: this.orgLid,
      site_lid: this.siteLid,
      area_name: this.areaName,
      in_states: this.inStates,
    }
  }
}

export class SurgeryLocation {
  locationLid: string;
  orgLid: string;
  siteLid: string;
  areaLid: string;
  locationName: string;

  constructor(d: any) {
    this.locationLid = d.location_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.areaLid = d.area_lid;
    this.locationName = d.location_name;
  }

  toJson(): any {
    return {
      location_lid: this.locationLid,
      org_lid: this.orgLid,
      site_lid: this.siteLid,
      area_lid: this.areaLid,
      location_name: this.locationName,
    }
  }
}

export class SurgeryLocationAssignment {
  assignmentLid: string;
  orgLid: string;
  siteLid: string;
  areaLid: string;
  locationLid: string;
  caseLid: string;
  dateOfSurgery: number;
  inState: number;
  outState: number;
  providerLid: string | undefined;

  constructor(d: any) {
    this.assignmentLid = d.assignment_lid;
    this.orgLid = d.org_lid;
    this.siteLid = d.site_lid;
    this.areaLid = d.area_lid;
    this.locationLid = d.location_lid;
    this.caseLid = d.case_lid;
    this.dateOfSurgery = d.date_of_surgery;
    this.inState = d.in_state;
    this.outState = d.out_state;
    this.providerLid = d.provider_lid;
  }
}

export class GetSurgeryLocationsByAreaResponse {
  area: SurgeryArea;
  locations: Array<SurgeryLocation>;

  constructor(d: any) {
    this.area = new SurgeryArea(d.area);
    this.locations = d.locations.map((l: any) => new SurgeryLocation(l));
  }
}