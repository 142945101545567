import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { AppState } from '@app/app.service';
import { DataService, IDENTITY_API_URL } from '../data.service';
import { OrgCacheService } from '../org-cache.service';

import { LoginUser, LoginUserCache, LoginUserOrg, SingleSignOn } from './auth.model';
import { AppId } from './app-id.model';
import { CustomQueryEncoderHelper } from '../data.model';

@Injectable()
export class AuthService  {

  private _org: LoginUserOrg | undefined = undefined;
  private _orgDefer: Promise<Object | undefined> | undefined = undefined;
  private _idleMonitorOn = false;

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService,
    private _cookieService: CookieService,
    private _appState: AppState,
    private _router: Router,
    private _idle: Idle,
    private _keepalive: Keepalive,
    private _orgCacheService: OrgCacheService,
  ) {};

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isAuthenticated()) {
      return true;
    }
    this.redirectToLogin(state);
    return false;
  }

  public redirectToLogin(state: RouterStateSnapshot) {
    this._router.navigate([this._dataService.getPreferredLogin()], { queryParams: { returnUrl: state.url }});
  }

  public login(email: string, password: string): Observable<LoginUser> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        email: email,
        password: password
      }
    });
    return this._httpClient.post(`${IDENTITY_API_URL}/login`, preparedParams, this._dataService.getAuthRequestHeader())
      .pipe(
        map((response: any) => {
          const r = new LoginUser(response['data']);
          this.startIdleMonitor(r.accessToken.expires);
          return r;
        }),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public logoutApi() {
    return this._httpClient.post(`${IDENTITY_API_URL}/logout`, null, this._dataService.getAuthRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public loginAdmin(token: string, user2Imitate: string | null, role2Imitate: string | null): Observable<LoginUser> {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        token: token,
      }
    });

    if (user2Imitate) preparedParams = preparedParams.set('user_to_imitate', user2Imitate);
    if (role2Imitate) preparedParams = preparedParams.set('role_to_imitate', role2Imitate);

    return this._httpClient.post(`${IDENTITY_API_URL}/login_admin`, preparedParams, this._dataService.getAuthRequestHeader())
      .pipe(
        map((response: any) => {
          const r = new LoginUser(response['data']);
          this.startIdleMonitor(r.accessToken.expires);
          return r;
        }),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public getAppPath(appId: AppId): string {
    return appId.getRouterLink().join('');
  }

  public requestPasswordReset(orgLid: string, userLid: string) {
    return this._httpClient.post(`${IDENTITY_API_URL}/org/${orgLid}/user/${userLid}/reset_password_request`, null, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public changePassword(email: string, password: string, newPassword: string): Observable<LoginUser> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        email: email,
        current_password: password,
        new_password: newPassword
      }
    });
    return this._httpClient.post(`${IDENTITY_API_URL}/change_password`, preparedParams, this._dataService.getAuthRequestHeader())
      .pipe(
        map((response: any) => {
          const r = new LoginUser(response['data']);
          this.startIdleMonitor(r.accessToken.expires);
          return r;
        }),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public changePasswordWithToken(token: string, newPassword: string): Observable<string> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        token: token,
        new_password: newPassword
      }
    });
    return this._httpClient.post(`${IDENTITY_API_URL}/change_password_token`, preparedParams, this._dataService.getAuthRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public getSingleSignOnDomain(domain: string): Observable<SingleSignOn> {
    return this._httpClient.get(`${IDENTITY_API_URL}/sso/domain/${domain}`, this._dataService.getAuthRequestHeader())
      .pipe(
        map((response: any) => new SingleSignOn(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  public singleSignOnLogin(domain: string, redirectUri: string, code: string): Observable<LoginUser> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        redirect_uri: redirectUri,
        code: code
      }
    });
    return this._httpClient.post(`${IDENTITY_API_URL}/sso/domain/${domain}/login`, preparedParams, this._dataService.getAuthRequestHeader())
      .pipe(
        map((response: any) => {
          const r = new LoginUser(response['data']);
          this.startIdleMonitor(r.accessToken.expires);
          return r;
        }),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  private startIdleMonitor(expires: number) {
    if (this._idleMonitorOn) return;

    if (this._idle.isRunning()) {
      // make sure service isn't already running
      this._idle.stop();
    }

    let timeout = expires - moment().unix();
    if (timeout < 61) timeout = 61;
    this._idle.setIdle(timeout - 60);
    this._idle.setTimeout(15);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.onTimeout.subscribe(() => this.logout());
    this._keepalive.interval(timeout - 30);
    this._keepalive.request(new HttpRequest('POST', `${IDENTITY_API_URL}/activity_ping`, null, this._dataService.getAuthRequestHeader(undefined, 'text')));
    this._idle.watch();
    this._keepalive.ping();
    this._idleMonitorOn = true;
  }

  public getUser(): LoginUserCache | undefined {
    const cachedUser = this._cookieService.get('lrUser');
    if (!cachedUser) {
      // this.logout();
      this._router.navigateByUrl(this._dataService.getPreferredLogin());
      return undefined;
    }
    return new LoginUserCache(JSON.parse(cachedUser));
  }

  public getOrg(): Promise<LoginUserOrg | undefined> {
    return new Promise((resolve, reject) => {
      if (this._org) {
        resolve(this._org);
      } else {
        if (!this._orgDefer) {
          this._orgDefer = this._httpClient.get(`${IDENTITY_API_URL}/me`, this._dataService.getAuthRequestHeader()).toPromise();
        }
        this._orgDefer.then((response: any) => {
          const user: LoginUserCache | undefined = this.getUser();
          if (user) {
            let r = new LoginUser(response['data']);
            this.startIdleMonitor(r.accessToken.expires)
            this._org = this.extractOrg(r, user);
            resolve(this._org);
          }
        }, (response) => {
          if (response.status === 401) {
            this._orgDefer = undefined;
            reject(response);
          }
        });
      }
    });
  }

  private extractOrg(response: LoginUser, user: LoginUserCache): LoginUserOrg | undefined {
    let _org: LoginUserOrg | undefined;
    const filterOrgList = response.orgs.filter((o) => {
      return o.orgLid === user.org.orgLid;
    });
    _org = filterOrgList.length ? filterOrgList[0] : undefined;
    return _org;
  }

  public isAuthenticated() {
    return !!this._dataService.getXsrfToken();
  }

  public isAppEnabled(appId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const orgJson = this._appState.get('org');
      if (orgJson) {
        const org: LoginUserOrg = new LoginUserOrg(orgJson);
        resolve(org.orgAppIds.indexOf(appId) > -1);
      } else {
        this.getOrg().then((o: LoginUserOrg | undefined) => {
          if (o) {
            resolve(o.orgAppIds.indexOf(appId) > -1);
          } else {
            resolve(false);
          }
        },
        (reason: any) => {
          reject(reason);
        });
      }
    });
  }

  public logout() {
    this.logoutApi().subscribe({
      next: () => {
        this._org = undefined;
        this._orgDefer = undefined;
        this._orgCacheService.reset();
        this._dataService.logout();
        this._idle.stop();
        this._idleMonitorOn = false;
      },
      error: (error) => {
        console.error(error);
        this._org = undefined;
        this._orgDefer = undefined;
        this._orgCacheService.reset();
        this._dataService.logout();
        this._idle.stop();
        this._idleMonitorOn = false;
      }
    });
  }
}
