import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, Observer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@app/app.service';
import { SearchService } from '@app/data/search/search.service';

import { SurgeryCaseQueryRequest, SurgeryCaseDocument } from '@app/data/search/search.model';
import { SearchSurgeryCaseResponse } from '@app/data/search/search.message';

@Component({
  selector: 'app-surgery-case-search',
  templateUrl: './surgery-case-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SurgeryCaseSearchComponent),
      multi: true
    },
    SearchService
  ]
})
export class SurgeryCaseSearchComponent implements ControlValueAccessor, OnInit {

  @ViewChild('input') inputRef!: ElementRef;

  @Input() public baseRequest!: SurgeryCaseQueryRequest;
  @Input() public exclusions: Array<string> = [];

  public placeholder: string;
  public searchStr: string = '';
  public typeaheadLoading?: boolean;
  public typeaheadNoResults: boolean = false;
  public searchObservable: Observable<Array<SurgeryCaseDocument>>;

  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;

  private _orgLid!: string;

  constructor(
    protected _translate: TranslateService,
    private _appState: AppState,
    private _searchService: SearchService
  ) {
    this.placeholder = this._translate.instant('SEARCH');
    this.searchObservable = new Observable((observer: Observer<string>) => {
      // Runs on every search
      observer.next(this.searchStr);
    }).pipe(
      mergeMap((term: string) => this._search(term))
    );
  }

  ngOnInit() {
    this._orgLid = this._appState.get('orgId');
  }

  writeValue(v: any): void {
    this.searchStr = v;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getIndustryName(industryName: string) {
    return industryName;
  }

  public reset() {
    this.searchStr = '';
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
 
  public onSelect(e: TypeaheadMatch): void {
    if (e) {
      const selected: SurgeryCaseDocument = e.item;
      this.onChange(selected);
    } else {
      this.onChange(undefined);
    }
  }

  private _search(term: string): Observable<Array<SurgeryCaseDocument>> {
    let request = this.baseRequest;
    request.query.text = term;
    return this._searchService.searchSurgeryCase(this._orgLid, request).pipe(
      map((r: SearchSurgeryCaseResponse | undefined) => r ? r.results : [])
    );
  }
}
