import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { AppState } from '@app/app.service';
import { OrgCacheService } from '@app/data/org-cache.service';

import { GetPerioperativeStatesResponse } from '@app/data/org/org.surgery.message';

export interface UISurgeryStateConfig {
  status: number; // scheduleStatus
  state: number | undefined; // perioperativeState
  external: boolean;
}

@Directive({
  selector: '[ui-surgery-state-style]',
})
export class UISurgeryStateStyleDirective implements OnInit {
    // @Input({ required: true, alias: 'ui-surgery-state-style' }) state: UISurgeryStateStyleConfig;
    @Input('ui-surgery-state-style') config!: UISurgeryStateConfig;

    private _orgId!: string;

    constructor(
      private el: ElementRef,
      private _appState: AppState,
      private _orgCacheService: OrgCacheService,
    ){}

  ngOnInit(): void {
    this._orgId = this._appState.get('orgId');
    this._orgCacheService.getSurgeryStates(this._orgId).subscribe({
      next: (response: GetPerioperativeStatesResponse) => {
        let match = undefined;
        if (this.config.status === 3) {
          match = response.canceled;
        } else {
          match = response.states.find((s) => s.state === this.config.state);
        }
        if (match) {
          this.el.nativeElement.style.backgroundColor = '#' + (this.config.external ? match.externalColorBackground : match.internalColorBackground);
          this.el.nativeElement.style.color = '#' + (this.config.external ? match.externalColorText : match.internalColorText);
        } else {
          this.el.nativeElement.style.backgroundColor = '#2c405a';
          this.el.nativeElement.style.color = '#ffffff';
        }
      },
      error: (error) => {
        console.error(error);
        this.el.nativeElement.style.backgroundColor = '#2c405a';
        this.el.nativeElement.style.color = '#ffffff';
      }
    });
  }
}
