<ng-template #customProcedureTemplate let-procedure="item">
  <div class="row">
    <div class="col">
      {{ procedure.name }} 
    </div>
  </div>
</ng-template>
 
<input [(ngModel)]="searchStr"
  [typeahead]="searchObservable"
  [typeaheadItemTemplate]="customProcedureTemplate"
  (typeaheadLoading)="changeTypeaheadLoading($event)"
  (typeaheadOnSelect)="onSelect($event)"
  typeaheadOptionField="name"
  placeholder="{{ placeholder }}"
  class="form-control">
