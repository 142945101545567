export class CaseSmsFollower {
  followerLid: string;
  caseLid: string;
  phoneNumber: string;
  label: string;
  locale: string;
  status: number;

  constructor(data: any) {
    this.followerLid = data.follower_lid;
    this.caseLid = data.case_lid;
    this.phoneNumber = data.phone_number;
    this.label = data.label;
    this.locale = data.locale;
    this.status = data.status;
  }
}