import { NavigationExtras } from "@angular/router";

export class Breadcrumb {
  label: string;
  commands: Array<String> | undefined;
  extras: NavigationExtras | undefined;

  constructor(label: string, commands?: Array<String>, extras?: NavigationExtras) {
    this.label = label;
    this.commands = commands;
    this.extras = extras;
  }
}

export class MenuItem {
  label: string;
  action: string;
  enabled: boolean
  confirmed: boolean

  constructor(label: string, action: string, enabled: boolean, confirmed: boolean) {
    this.label = label;
    this.action = action;
    this.enabled = enabled;
    this.confirmed = confirmed;
  }
}

export class MenuAction {
  action: string;
  refs: Array<any>;

  constructor(action: string, refs: Array<any>) {
    this.action = action;
    this.refs = refs;
  }
}