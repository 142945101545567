import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable, catchError, map, shareReplay } from "rxjs";

import { DataService, ORG_API_URL } from "./data.service";

import { GetPerioperativeStatesResponse } from "./org/org.surgery.message";
import { RoomGroup } from "./room/room.model";

@Injectable()
export class OrgCacheService {

  private surgeryRoomGroupsCache$: Map<string, Observable<Array<RoomGroup>>>;
  private surgeryStatesCache$: Map<string, Observable<GetPerioperativeStatesResponse>>;

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService
  ) {
    this.surgeryRoomGroupsCache$ = new Map<string, Observable<Array<RoomGroup>>>();
    this.surgeryStatesCache$ = new Map<string, Observable<GetPerioperativeStatesResponse>>();
  };

  private _getSurgeryRoomGroups(orgId: string): Observable<Array<RoomGroup>> {
    return this._httpClient.get(`${ORG_API_URL}/${orgId}/surgery/group`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => response['data'].map(function(groupData: any){
          return new RoomGroup(groupData);
        })),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryRoomGroups(orgId: string): Observable<Array<RoomGroup>> {
    if (this.surgeryRoomGroupsCache$.has(orgId)) {
      return this.surgeryRoomGroupsCache$.get(orgId)!;
    } else {
      const surgeryRoomGroups$ = this._getSurgeryRoomGroups(orgId).pipe(
        shareReplay(1)
      );
      this.surgeryRoomGroupsCache$.set(orgId, surgeryRoomGroups$);
      return surgeryRoomGroups$;
    }
  }

  private _getSurgeryStates(orgId: string): Observable<GetPerioperativeStatesResponse> {
    return this._httpClient.get(`${ORG_API_URL}/${orgId}/surgery/state`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetPerioperativeStatesResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getSurgeryStates(orgId: string): Observable<GetPerioperativeStatesResponse> {
    if (this.surgeryStatesCache$.has(orgId)) {
      return this.surgeryStatesCache$.get(orgId)!;
    } else {
      const surgeryStates$ = this._getSurgeryStates(orgId).pipe(
        shareReplay(1)
      );
      this.surgeryStatesCache$.set(orgId, surgeryStates$);
      return surgeryStates$;
    }
  }

  public reset() {
    this.surgeryRoomGroupsCache$.clear();
    this.surgeryStatesCache$.clear();
  }
}