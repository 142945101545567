import { PerioperativeStateLabel } from "../case/surgery.model";

export class GetPerioperativeStatesResponse {
    orgLid: string;
    states: Array<PerioperativeStateLabel>;
    canceled: PerioperativeStateLabel;

    constructor(d: any) {
        this.orgLid = d.org_lid;
        this.states = d.states.map((s: any) => new PerioperativeStateLabel(s));
        this.canceled = new PerioperativeStateLabel(d.canceled);
    }
}
