import {Pipe, PipeTransform} from '@angular/core';

import { Metric } from '@app/data/analytics/analytics-enums.model';
import { SurgeryMetric } from '@app/data/analytics/surgery-metric.model';


/*
 * Turn an metric enum into it's formatted value
 *
 * Usage:
 *   metric | analyticsSurgeryMetricDisplay:record
 * Example:
 *   {{ AverageCaseDuration | analyticsSurgeryMetricDisplay:record }}
*/
@Pipe({name: 'analyticsSurgeryMetricDisplay'})
export class AnalyticsSurgeryMetricDisplayPipe implements PipeTransform {

  transform(metric: Metric, record: (string | SurgeryMetric) | Array<string | SurgeryMetric> | undefined): string {
    if (!record) return 'n/a';
    
    let _record = undefined;
    if (Array.isArray(record)) {
      _record = record[1] as SurgeryMetric;
    } else {
      _record = record as SurgeryMetric;
    }

    const value = _record ? _record.get(metric) : undefined;
    if (value === null || value === undefined) return 'n/a';

    switch(metric) {
      case Metric.Cases: 
      case Metric.Procedures: 
      case Metric.Minutes: 
      case Metric.AverageCaseDuration: 
      case Metric.CasesWithDirectCosts:
      case Metric.CasesWithCharges:
      case Metric.CasesWithActualPayments:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });
      
      case Metric.AverageCaseCost: 
      case Metric.TotalCost: 
      case Metric.AverageCaseCharges: 
      case Metric.TotalCharges: 
      case Metric.AverageCaseActualPayments: 
      case Metric.TotalActualPayments: 
      case Metric.ContributionMargin: 
      case Metric.ContributionMarginPerMinute: 
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

      case Metric.DurationAccuracy:
      case Metric.LRDurationAccuracy:
      case Metric.AddOnRate:
      case Metric.AddOnPrimeTimeRate:
      case Metric.AddOnNonPrimeTimeRate:
      case Metric.CancellationRate:
      case Metric.DayOfSurgeryCancellationRate:
      case Metric.PriorToDayOfSurgeryCancellationRate:
        case Metric.ProcedureDurationAccuracy:
        case Metric.LRProcedureDurationAccuracy:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });

      case Metric.AverageOverScheduled:
      case Metric.AverageUnderScheduled:
      case Metric.LRAverageOverScheduled:
      case Metric.LRAverageUnderScheduled:
      case Metric.DelayMinutes:
      case Metric.Cancellations:
      case Metric.DayOfSurgeryCancels:
      case Metric.PriorToDayOfSurgeryCancels:
      case Metric.AverageProcedureOverScheduled:
      case Metric.AverageProcedureUnderScheduled:
      case Metric.LRAverageProcedureOverScheduled:
      case Metric.LRAverageProcedureUnderScheduled:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });

      case Metric.FirstCaseOnTimeStart:
      case Metric.FirstCaseOnTimeStartNGRate:
      case Metric.SubsequentCaseOnTimeStart:
      case Metric.ExpandedFirstCaseOnTimeStart:
      case Metric.ExpandedFirstCaseOnTimeStartNGRate:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });

      case Metric.FirstCaseTotalDelay:
      case Metric.FirstCaseAverageDelay:
      case Metric.SubsequentCaseTotalDelay:
      case Metric.SubsequentCaseAverageDelay:
      case Metric.AfterHourCases:
      case Metric.AfterHourMinutes:
      case Metric.TurnoverTimeRoom:
      case Metric.TurnoverTimeSurgeon:
      case Metric.TurnoverTimeFlipRoom:
      case Metric.TurnoverTimeFlipSurgeon:
      case Metric.PreProcedureGaps:
      case Metric.PostProcedureGaps:
      case Metric.PACU1LengthOfStay:
      case Metric.PACU2LengthOfStay:
      case Metric.FirstCaseTotal:
      case Metric.FirstCaseOnTimeStartCount:
      case Metric.FirstCaseOnTimeStartNGCount:
      case Metric.FirstCaseDelayedStartCount:
      case Metric.SubsequentCaseDelayedStartCount:
      case Metric.ExpandedFirstCaseTotalDelay:
      case Metric.ExpandedFirstCaseAverageDelay:
      case Metric.ExpandedFirstCaseTotal:
      case Metric.ExpandedFirstCaseOnTimeStartCount:
      case Metric.ExpandedFirstCaseOnTimeStartNGCount:
      case Metric.ExpandedFirstCaseDelayedStartCount:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });

      case Metric.BlockUtilization:
      case Metric.ReleasedBlockPercent:
        let bu = (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });
        if ((_record?.get(Metric.BlockCount) as number) > 1) bu = bu + ' ^';
        return bu;

      case Metric.BlockAllocation:
      case Metric.BlockProviderLids:
        throw new Error(`metric ${metric} is an array and can't be displayed using this pipe!`);

      case Metric.TotalBlockMinutes:
      case Metric.UsedBlockMinutes:
      case Metric.ReleasedBlockMinutes:
      case Metric.BlockTurnoverCredit:
        let bm = value.toLocaleString('en-US', { maximumFractionDigits: 1 });
        if ((_record?.get(Metric.BlockCount) as number) > 1) bm = bm + ' ^';
        return bm;

      case Metric.BlockProviderCount:
      case Metric.MinutesOutOfBlock:
      case Metric.MinutesPrimeTime:
      case Metric.MinutesNonPrimeTime:
        let bm2 = value.toLocaleString('en-US', { maximumFractionDigits: 1 });
        const pCount = _record?.get(Metric.BlockProviderCount);
        if ((pCount as number) > 1) bm2 = bm2 + ' *';
        return bm2;

      case Metric.Utilization:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });

      case Metric.StaffedMinutesTotal:
      case Metric.StaffedMinutesUsed:
      case Metric.StaffedMinutesReleased:
      case Metric.StaffedMinutesTurnoverCredit:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });

      case Metric.PrimeTimeUtilization:
      case Metric.PrimeTimeMinutesReleasedPercent:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });

      case Metric.PrimeTimeMinutesTotal:
      case Metric.PrimeTimeMinutesUsed:
      case Metric.PrimeTimeMinutesReleased:
      case Metric.PrimeTimeMinutesTurnoverCredit:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });
        
      case Metric.SSICases: 
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });

      case Metric.SSIRate:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });

      case Metric.PrefCardCount:
      case Metric.PrefCardCaseCount:
      case Metric.PrefCardProcedureCount:
      case Metric.PrefCardQuantityOpen:
      case Metric.PrefCardQuantityHold:
      case Metric.PrefCardQuantityUsed:
      case Metric.PrefCardQuantityUnused:
      case Metric.PrefCardQuantityOverused:
        return value.toLocaleString('en-US', { maximumFractionDigits: 1 });

      case Metric.PrefCardCostCard:
      case Metric.PrefCardCostUsed:
      case Metric.PrefCardCostUnused:
      case Metric.PrefCardCostOverused:
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

      case Metric.PrefCardRateUnused:
      case Metric.PrefCardRateOverused:
        return (value as number / 100).toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });
        
      default:
        throw new Error(`metric ${metric} not implemented!`);
    }
  }
}
