import moment from 'moment';
import { AppId } from '../auth/app-id.model';

export class OrgUser {
  userId: string;
  name: string;
  defaultAppId: AppId;
  roles: Array<string>;
  
  constructor(d: any) {
    this.userId = d.user_lid;
    this.name = d.display_name;
    this.defaultAppId = new AppId(+d.default_app_id);
    this.roles = d.roles;
  }
}

export class OrgRole {
  roleId: string;
  name: string;

  constructor(d: any) {
    this.roleId = d.role_lid;
    this.name = d.name;
  }
}

export class UserRow {
  userLid: string;
  userType: number;
  userStatus: number;
  emailStatus: number;
  phoneStatus: number;
  passwordStatus: number;
  displayName: string;
  handle: string;
  created: moment.Moment;
  modified: moment.Moment;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.userType = d.user_type;
    this.userStatus = d.user_status;
    this.emailStatus = d.email_status;
    this.phoneStatus = d.phone_status;
    this.passwordStatus = d.password_status;
    this.displayName = d.display_name;
    this.handle = d.handle;
    this.created = moment.unix(d.created);
    this.modified = moment.unix(d.modified);
  }
}

export class UserOrgRow {
  userLid: string;
  orgLid: string;
  defaultAppId: AppId;
  providerLid: string;
  loginTimeout: number;
  maxLoginTimeout: number;
  accessExpires: moment.Moment | undefined;
  created: moment.Moment;
  modified: moment.Moment;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.orgLid = d.org_lid;
    this.defaultAppId = new AppId(+d.default_app_id);
    this.providerLid = d.provider_lid;
    this.loginTimeout = d.login_timeout;
    this.maxLoginTimeout = d.max_login_timeout;
    this.accessExpires = d.access_expires ? moment.unix(d.access_expires) : undefined;
    this.created = moment.unix(d.created);
    this.modified = moment.unix(d.modified);
  }
}

export class UserPasswordRow {
  userLid: string;
  passwordVersion: number;
  changeAfter: moment.Moment;
  created: moment.Moment;
  modified: moment.Moment;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.passwordVersion = d.password_version;
    this.changeAfter = moment.unix(d.change_after);
    this.created = moment.unix(d.created);
    this.modified = moment.unix(d.modified);
  }
}

export class UserRoleRow {
  userLid: string;
  orgLid: string;
  roleLid: string;
  created: moment.Moment;
  modified: moment.Moment;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.orgLid = d.org_lid;
    this.roleLid = d.role_lid;
    this.created = moment.unix(d.created);
    this.modified = moment.unix(d.modified);
  }
}