export class SurgeryRoom {
  roomLid: string;
  orgLid: string;
  siteLid: string;
  name: string;
  status: number;
  robotic: boolean;
  timezone: string;

  shortName: string;
  

  constructor(roomData:any) {
    this.roomLid = roomData.room_lid;
    this.orgLid = roomData.org_lid;
    this.siteLid = roomData.site_lid;
    this.name = roomData.name;
    this.status = roomData.status;
    this.robotic = roomData.robotic;
    this.timezone = roomData.timezone;

    this.shortName = roomData.name.length > 12 ? roomData.name.substring(0, 9) + '...' : roomData.name;
  }

  toJson(): any {
    return {
      room_lid: this.roomLid,
      org_lid: this.orgLid,
      site_lid: this.siteLid,
      name: this.name,
      status: this.status,
      robotic: this.robotic,
      timezone: this.timezone
    };
  }
}

export class RoomGroup {
  groupId: string;
  name: string;

  constructor(d: any) {
    this.name = d.name;
    this.groupId = d.group_lid;
  }
}

export class SurgeryRoomGroupMember {
  groupLid: string;
  roomLid: string;
  areaLid: string | undefined;
  displayRank: number;

  constructor(d: any) {
    this.groupLid = d.group_lid;
    this.roomLid = d.room_lid;
    this.areaLid = d.area_lid ? d.area_lid : undefined;
    this.displayRank = d.display_rank;
  }
}

export class SurgeryRoomGroupSite {
  groupLid: string;
  siteLid: string;
  displayRank: number;

  constructor(d: any) {
    this.groupLid = d.group_lid;
    this.siteLid = d.site_lid;
    this.displayRank = d.display_rank;
  }
}

export class SurgeryRoomPrimetime {
  primeTimeLid: string;
  roomLid: string;
  orgLid: string;
  status: number;
  ruleType: number;
  weeks: Array<number>;
  dates: Array<string>;
  daysOfWeek: Array<number>;
  from: string;
  to: string;
  effectiveFrom: string;
  effectiveTo: string | undefined;

  constructor(d: any) {
    this.primeTimeLid = d.prime_time_lid;
    this.roomLid = d.room_lid;
    this.orgLid = d.org_lid;
    this.status = d.status;
    this.ruleType = d.rule_type;
    this.weeks = d.weeks;
    this.dates = d.dates;
    this.daysOfWeek = d.days_of_week;
    this.from = d.from;
    this.to = d.to;
    this.effectiveFrom = d.effective_from;
    this.effectiveTo = d.effective_to;
  }

  toJson(): any {
    return {
      prime_time_lid: this.primeTimeLid,
      room_lid: this.roomLid,
      org_lid: this.orgLid,
      status: this.status,
      rule_type: this.ruleType,
      weeks: this.weeks,
      dates: this.dates,
      days_of_week: this.daysOfWeek,
      from: this.from,
      to: this.to,
      effective_from: this.effectiveFrom,
      effective_to: this.effectiveTo
    };
  }
}

export class SurgeryRoomPrimeTimeRelease {
  releaseLid: string;
  primeTimeLid: string;
  releasedFor: string;
  releasedFrom: string;
  releasedTo: string;
  releasedBy: string;
  releaseNotes: string;
  creditType: number;
  roomLid: string;
  orgLid: string;

  constructor(d: any) {
    this.releaseLid = d.release_lid;
    this.primeTimeLid = d.prime_time_lid;
    this.releasedFor = d.released_for;
    this.releasedFrom = d.released_from;
    this.releasedTo = d.released_to;
    this.releasedBy = d.released_by;
    this.releaseNotes = d.release_notes;
    this.creditType = d.credit_type;
    this.roomLid = d.room_lid;
    this.orgLid = d.org_lid;
  }
}
