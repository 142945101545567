import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { AnnouncementService } from '@app/data/announcement.service';

@Component({
  templateUrl: './comment-modal.component.html',
})
export class CommentModalComponent implements OnInit {
  
  id!: string;

  title!: string;
  actionName!: string;
  actionClass!: string;
  actionPayload: any;

  cancelName!: string;
  cancelClass!: string;
  cancelPayload: any;

  commentRequired!: boolean;

  form!: UntypedFormGroup;

  constructor(
    private _announcementService: AnnouncementService,
    private bsModalRef: BsModalRef,
    private _fb: UntypedFormBuilder,
  ){
    this._createForm();
  }

  public ngOnInit() {
    this.form.controls['comment'].setValidators(this.commentRequired ? [Validators.required] : []);
    this.form.controls['comment'].updateValueAndValidity({ onlySelf: true, emitEvent: false});
  }

  public onSubmit() {
    const comment = this.form.value.comment;
    this._announcementService.announceCommented(this.id, true, this.actionName, this.actionPayload, comment);

    this.bsModalRef.hide();
  }

  public onCancel() {
    this._announcementService.announceCommented(this.id, false, this.cancelName, this.actionPayload, undefined);

    this.bsModalRef.hide();
  }

  private _createForm() {
    this.form = this._fb.group({
      comment: [''],
    });
  }
}
