@import '/src/assets/css/variables.scss';

.angular-with-newlines {
  white-space: pre-wrap;
}

// For creating linear background gradient
@mixin linear-gradient($top, $bottom){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

// Box shadow
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}


// For creating border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin rotate {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// Button base
@mixin button-base {
  border-color: $color-pale-aqua;
  color: $color-cadet;
  padding: 10px 15px;

  &:hover, &:active {
    background-color: $color-glitter;
  }

  &:focus {
    outline: 0;
  }

  i {
    color: $color-pale-cerulean;
  }
}

// Base styling for the header
@mixin header-backgroud {
  @include linear-gradient($color-ocean-boat-blue, $color-deep-sky-blue);
}

@mixin header-base {
  @include linear-gradient($color-ocean-boat-blue, $color-deep-sky-blue);
  height: $app-header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}

// Base styling for the action bar
@mixin action-bar-base {
  height: $app-action-bar-height;
  background-color: $color-white;
  border-bottom: 1px solid $color-pale-aqua;
  position: fixed;
  top: $app-header-height;
  left: 0;
  width: 100%;
  z-index: 10;

  h2 {
    font-size: 17px;
    margin: 0;
    padding: 0;
    line-height: $app-action-bar-height;
    color: $color-cadet;
    position: absolute;
    top: 0;
    left: 30px;

    @include breakpoint(mobile) {
      font-size: 13px;
    }
  }
}

// @mixin case-state-color {
//   &.is-state-1 {
//     color: $color-white;
//   }
//   &.is-state-2 {
//     color: $color-white;
//   }
//   &.is-state-3 {
//     color: $color-white;
//   }
//   &.is-state-4 {
//     color: $color-white;
//   }
//   &.is-state-5 {
//     color: $color-white;
//   }
//   &.is-state-6 {
//     color: $color-white;
//   }
//   &.is-state-7 {
//     color: $color-white;
//   }
//   &.is-state-8 {
//     color: $color-white;
//   }
//   &.is-state-9 {
//     color: $color-white;
//   }
//   &.is-state-10 {
//     color: $color-white;
//   }
//   &.is-state-11 {
//     color: $color-white;
//   }
//   &.is-state-12 {
//     color: $color-white;
//   }
//   &.is-state-13 {
//     color: $color-white;
//   }
//   &.is-state-14 {
//     color: $color-white;
//   }
//   &.is-state-15 {
//     color: $color-white;
//   }
//   &.is-state-16 {
//     color: $color-white;
//   }
//   &.is-state-17 {
//     color: $color-black;
//   }
//   &.is-state-canceled {
//     color: $color-white;
//   }
//   &.is-state- {
//     color: $color-white;
//   }
// }

// @mixin case-state-background-color {
//   &.is-state-1 {
//     background-color: $color-charcoal;
//   }
//   &.is-state-2 {
//     background-color: $color-lavender;
//   }
//   &.is-state-3 {
//     background-color: $color-cadet-grey;
//   }
//   &.is-state-4 {
//     background-color: $color-lavender;
//   }
//   &.is-state-5 {
//     background-color: $color-lavender;
//   }
//   &.is-state-6 {
//     background-color: $color-ocean-boat-blue;
//   }
//   &.is-state-7 {
//     background-color: $color-lavender;
//   }
//   &.is-state-8 {
//     background-color: $color-ocean-boat-blue;
//   }
//   &.is-state-9 {
//     background-color: $color-orange;
//   }
//   &.is-state-10 {
//     background-color: $color-sap-green;
//   }
//   &.is-state-11 {
//     background-color: $color-orange;
//   }
//   &.is-state-12 {
//     background-color: $color-sap-green;
//   }
//   &.is-state-13 {
//     background-color: $color-sap-green;
//   }
//   &.is-state-14 {
//     background-color: $color-sap-green;
//   }
//   &.is-state-15 {
//     background-color: $color-sap-green;
//   }
//   &.is-state-16 {
//     background-color: $color-lavender;
//   }
//   &.is-state-17 {
//     background-color: $color-usc-gold;
//   }
//   &.is-state-canceled {
//     background-color: $color-harvard-crimson;
//   }
// }

@mixin block-utlization-score-color {
  &.is-voluntary-released {
    color: $color-black;
  }
  &.is-score-1 {
    color: $color-white;
  }
  &.is-auto-released-1 {
    color: $color-black;
  }
  &.is-score-2 {
    color: $color-white;
  }
  &.is-auto-released-2 {
    color: $color-black;
  }
  &.is-score-3 {
    color: $color-white;
  }
  &.is-auto-released-3 {
    color: $color-white;
  }
}

@mixin block-utlization-score-background-color {
  &.is-voluntary-released {
    background: repeating-linear-gradient(
      45deg,
      rgba($color-sap-green, 0.1),
      rgba($color-sap-green, 0.1) 10px,
      rgba(lighten($color-sap-green, 10%), 0.1) 10px,
      rgba(lighten($color-sap-green, 10%), 0.1) 20px
    );
  }
  &.is-score-1 {
    background-color: $color-sap-green;
  }
  &.is-auto-released-1 {
    background: repeating-linear-gradient(
      45deg,
      rgba($color-sap-green, 0.3),
      rgba($color-sap-green, 0.3) 10px,
      rgba(lighten($color-sap-green, 10%), 0.3) 10px,
      rgba(lighten($color-sap-green, 10%), 0.3) 20px
    );
  }
  &.is-score-2 {
    background-color: $color-cadet-grey;
  }
  &.is-auto-released-2 {
    background: repeating-linear-gradient(
      45deg,
      rgba($color-cadet-grey, 0.3),
      rgba($color-cadet-grey, 0.3) 10px,
      rgba(lighten($color-cadet-grey, 10%), 0.3) 10px,
      rgba(lighten($color-cadet-grey, 10%), 0.3) 20px
    );
  }
  &.is-score-3 {
    background-color: $color-harvard-crimson;
  }
  &.is-auto-released-3 {
    background: repeating-linear-gradient(
      45deg,
      rgba($color-harvard-crimson, 0.3),
      rgba($color-harvard-crimson, 0.3) 10px,
      rgba(lighten($color-harvard-crimson, 10%), 0.3) 10px,
      rgba(lighten($color-harvard-crimson, 10%), 0.3) 20px
    );
  }
}

@mixin app-header-nav {
  list-style: none;
  text-align: left;

  & > li {
    display: inline-block;
    position: relative;

    .fa {
      padding-left: 5px;
    }

    .fa-caret-up {
      display: none;
    }

    &.open {
      .fa-caret-down {
        display: none;
      }
      .fa-caret-up {
        display: inline-block;
      }
    }

    & > .nav-btn, & > a {
      color: $color-white;
      opacity: 1;
      line-height: $app-header-height;
      font-size: 16px;
      padding-right: 30px;
    }

    & > .nav-btn {
      background: none;
      border: 0;

      &:hover, &:focus {
        outline: none;
      }

      &:hover{
        opacity: 1;
      }
    }

    & > a {
      display: block;

      &:hover, &.active {
        opacity: 1;
        text-decoration: none;
      }
    }

    .dropdown-menu {
      top: 75%;
      li > a {
        padding: 5px 20px;
        color: $color-cadet;

        .muted {
          opacity: 0.5;
        }
      }
    }
  }

}

@mixin statistic-base {
  .stat-label {
    font-size: 16px;
    color: $color-cadet;
  }

  .stat-value {
    font-size: 28px;
    color: $color-charcoal;
  }

  .progress-bar-container {
    margin-top: 8px;
  }

  .stat-separator {
    height: 4px;
    border-bottom: 1px solid $color-glitter;
    margin-top: 10px;
  }

  .stat-last-period {
    font-size: 12px;
    color: $color-cadet;
    padding-top: 10px;
  }

  .stat-last-value {
    padding-left: 15px;
  }

  .stat-change {
    float: right;

    .fa {
      font-size: 16px;
      color: $color-red-orange;

      &.is-green {
        color: $color-yellow-green;
      }

      &.is-gray {
        color: $color-light-grey;
      }
    }
  }

  .stat-delta {
    font-size: 16px;
  }
}

@mixin app-container-base {
  margin: 0 auto;
  width: 95%;

  @include breakpoint(mobile) {
    min-width: 375px;
  }

  @include breakpoint(sm) {
    width: 95%;
  }

  .app-row {
    @include clearfix;
    margin-bottom: 25px;
  }

  .column-left {
    float: left;
    width: 340px;
    margin-right: 10px;

    @include breakpoint(mobile-and-tablet) {
      display: none;
    }
  }

  .column-main.is-left-enabled {
    float: left;
    width: 70%;

    @include breakpoint(mobile) {
      width: 98%;
    }

    @include breakpoint(mobile-and-tablet) {
      width: 98%;
    }
  }

  .column-main {
    float: left;
    min-width: 75%;
    width: calc(100% - 360px);

    @include breakpoint(mobile) {
      width: 98%;
    }

    @include breakpoint(mobile-and-tablet) {
      width: 98%;
    }
  }

  .column-right {
    float: left;
    margin-left: 10px;
    width: calc(25% - 10px);
    max-width: 350px;

    @include breakpoint(mobile) {
      width: 98%;
      margin-left: 0px;
      margin-top: 10px;
    }

    @include breakpoint(mobile-and-tablet) {
      width: 98%;
      margin-left: 0px;
      margin-top: 10px;
    }
  }
}

@mixin app-module-base {
  @include border-radius(4px);
  background-color: $color-white;
  border: 1px solid $color-pale-aqua;

  .module-header {
    padding: 0 15px;
    height: 35px;
    border-bottom: 1px solid $color-lavender-mist;
    position: relative;

    h4 {
      margin: 0;
      padding: 0;
      color: $color-charcoal;
      font-size: 15px;
      font-weight: normal;
      line-height: 35px;
    }

    .header-action {
      display: block;
      height: 35px;
      line-height: 35px;
      width: 35px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      color: $color-pale-cerulean;
    }

  }

}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: $color-deep-sky-blue;
  text-align: center;
  margin-right: 3px;

  .initials {
    position: relative;
    @if ($size > 30) {
      top: $size*0.25;  
    } @else {
      top: -$size*0.25;  
    }
    font-size: $size*0.5;
    line-height: $size*0.5;
    color: #fff;
    font-weight: bold;
  }
}

// BREAKPOINTS
// =====================
// Arguments can be:
// * a single key word representing the view:
// 	(mobile, tablet, desktop, wide, mobile-and-tablet, tablet-and-desktop, tablet-and-above, desktop-and-above)
// * a min-width value, example: @include bp(500px) {}
// * a min-width and a max-width value, example: @include bp(300px, 550px) {}

@mixin breakpoint($point, $max-point: null) {
  // simple:
  // ------------------
  // four main views:
  @if $point == mobile {
    @media (max-width: $mobile-max-width) {@content;}
  }
  @else if $point == tablet {
    @media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {@content;}
  }
  @else if $point == desktop {
    @media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {@content;}
  }
  @else if $point == wide {
    @media (min-width: $wide-min-width) {@content;}
  }

    // compound:
    // ------------------
  @else if $point == mobile-and-tablet {
    @media (max-width: $tablet-max-width) {@content;}
  }
  @else if $point == tablet-and-desktop {
    @media (min-width: $tablet-min-width) and (max-width: $desktop-max-width) {@content;}
  }

    // minimum:
    // ------------------
  @else if $point == tablet-and-above {
    @media (min-width: $tablet-min-width) {@content;}
  }
  @else if $point == desktop-and-above {
    @media (min-width: $desktop-min-width) {@content;}
  }

    // custom:
    // ------------------
  @else {
    @if $max-point == null {
      @media (min-width: $point) {@content;}
    }
    @else {
      @media (min-width: $point) and (max-width: $max-point) {@content;}
    }
  }
}
// Usage example: .some-element { @include bp(desktop) {/* styles here */} }
