import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { forkJoin, from } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

import { AuthService } from './auth/auth.service';

import { AppId } from './auth/app-id.model';

@Injectable()
export class AppGuard  {

  constructor (
    private _authService: AuthService,
    private _router: Router,
    private _permissionsService: NgxPermissionsService,
  ) {};

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let targetAppId = new AppId(2); // unknown defaulting to board - TODO: find a better solution
    if (route.pathFromRoot.length > 1 && route.pathFromRoot[1].url.length > 0) {
      const prefix = route.pathFromRoot[1].url[0].path;
      const appId = AppId.FromUrlRoot(prefix)
      if (appId) {
        targetAppId = appId;
      } else {
        console.error('Unknown app url prefix ' + prefix + ' encountered');
      }
    } else {
      console.error('Route does not start with app url prefix');
    }
    
    return forkJoin([
      from(this._authService.isAppEnabled(targetAppId.id)),
      from(this._permissionsService.hasPermission(targetAppId.getAppPermission()))
    ]).toPromise().then(checks => {
      if (checks && !checks[0]) {
        this._router.navigate(['/', 'upgrade'], { queryParams: { app: targetAppId.id }});
      }

      if (checks && !checks[1]) {
        this._router.navigate(['/', 'not-authorized'], { queryParams: { app: targetAppId.id }});
      }

      return true;
    },
    (reasons: any) => {
      this._authService.redirectToLogin(state);
      return true;
    });
  }
}
