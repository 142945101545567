import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'capFirst'})
export class CapFirstPipe implements PipeTransform {
  transform(value:any): any {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;  
  }
}
