import { isReflectMetadataSupported, logError, MISSING_REFLECT_CONF_MSG, nameof } from './helpers';
import { injectMetadataInformation } from './metadata';
import { extractOptionBase } from './options-base';
import { ArrayTypeDescriptor, ensureTypeDescriptor, ensureTypeThunk } from './type-descriptor';
export function jsonArrayMember(maybeTypeThunk, options = {}) {
  return (target, propKey) => {
    var _a;
    const decoratorName = `@jsonArrayMember on ${nameof(target.constructor)}.${String(propKey)}`;
    const typeThunk = ensureTypeThunk(maybeTypeThunk, decoratorName);
    const dimensions = options.dimensions == null ? 1 : options.dimensions;
    if (!isNaN(dimensions) && dimensions < 1) {
      logError(`${decoratorName}: 'dimensions' option must be at least 1.`);
      return;
    }
    const reflectedType = isReflectMetadataSupported ? Reflect.getMetadata('design:type', target, propKey) : null;
    if (reflectedType != null && reflectedType !== Array && reflectedType !== Object) {
      logError(`${decoratorName}: property is not an Array. ${MISSING_REFLECT_CONF_MSG}`);
      return;
    }
    injectMetadataInformation(target, propKey, {
      type: () => createArrayType(ensureTypeDescriptor(typeThunk()), dimensions),
      emitDefaultValue: options.emitDefaultValue,
      isRequired: options.isRequired,
      options: extractOptionBase(options),
      key: propKey.toString(),
      name: (_a = options.name) !== null && _a !== void 0 ? _a : propKey.toString(),
      deserializer: options.deserializer,
      serializer: options.serializer
    });
  };
}
export function createArrayType(elementType, dimensions) {
  let type = new ArrayTypeDescriptor(elementType);
  for (let i = 1; i < dimensions; ++i) {
    type = new ArrayTypeDescriptor(type);
  }
  return type;
}
