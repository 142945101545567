import { Component, Input, EventEmitter, Output } from '@angular/core';

import { AppId } from '@app/data/auth/app-id.model';

@Component({
  selector: 'generic-header',
  styleUrls: [ './generic-header.component.scss' ],
  templateUrl: './generic-header.component.html'
})
export class GenericHeaderComponent {

  @Input() appId!: AppId;
  @Input() isOpened!: boolean;
  @Output() onMenuClicked = new EventEmitter<void>();

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  menuClicked() {
    this.onMenuClicked.emit();
  }
}
