<div class="app-daterange-picker">
  <button class="btn btn-secondary" id="picker-trigger">
    <div>
      {{ twoPeriods.current.getStartFormatted() }} - {{ twoPeriods.current.getEndFormatted() }}
    </div>
    <div class="muted">
      vs {{ twoPeriods.previous.getStartFormatted() }} - {{ twoPeriods.previous.getEndFormatted() }}
    </div>
    <i class="fa fa-caret-down" aria-hidden="true"></i>
  </button>
  <div class="popup" [ngClass]="{'hidden': isCalendarClosed}">
    <div id="current-period-container" class="calendar-container">
      <div class="tabs">
        <a href="" (click)="showCurrentPeriod()" class="tab" [ngClass]="{'active': !isPreviousPeriodShown}">
          {{ twoPeriods.current.getStartFormatted() }} - {{ twoPeriods.current.getEndFormatted() }}
        </a>
        <a href="" (click)="showPreviousPeriod()" class="tab" [ngClass]="{'active': isPreviousPeriodShown}">
          vs {{ twoPeriods.previous.getStartFormatted() }} - {{ twoPeriods.previous.getEndFormatted() }}
        </a>
      </div>
      <div #current_period_tab class="tab-container" [ngClass]="{'hidden': isPreviousPeriodShown}">
        <button class="btn-hidden" #current_period_selector >&nbsp;</button>
      </div>
      <div #previous_period_tab class="tab-container" [ngClass]="{'hidden': !isPreviousPeriodShown}">
        <button class="btn-hidden" #previous_period_selector>&nbsp;</button>
        <div class="range-list" [ngClass]="{'is-setting': isSettingRange}">
          <a href="" (click)="changePeriodFunction('period')" [ngClass]="{'active': twoPeriods.relativeFunction === 'period' && !isCustomSelecting}">{{ 'DATE_RANGE.PREVIOUS_PERIOD' | translate }}</a>
          <a href="" (click)="changePeriodFunction('month')" [ngClass]="{'active': twoPeriods.relativeFunction === 'month' && !isCustomSelecting}">{{ 'DATE_RANGE.PREVIOUS_MONTH' | translate }}</a>
          <a href="" (click)="changePeriodFunction('year')" [ngClass]="{'active': twoPeriods.relativeFunction === 'year' && !isCustomSelecting}">{{ 'DATE_RANGE.PREVIOUS_YEAR' | translate }}</a>
          <a href="" (click)="changePeriodFunction('custom')" [ngClass]="{'active': twoPeriods.relativeFunction === 'custom' || isCustomSelecting}">{{ 'DATE_RANGE.CUSTOM' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>