import { Provider } from '../provider/provider.model';
import { AppId } from './app-id.model';

const PERMISSION_NAMES: any = {
  1: 'ManagerApp',
  2: 'ManagerSidebarMenu',
  3: 'ManagerUsers',
  4: 'ManagerDefaultEdit',
  5: 'ManagerAudit',
  6: 'ManagerProviderEdit',
  100: 'ManagerSurgery',

  1000: 'BoardApp',
  1001: 'BoardSidebarMenu',
  1002: 'BoardDefaultView',
  1003: 'BoardAddOns',
  1004: 'BoardSummaryStats',
  1005: 'BoardLatestNews',
  1006: 'BoardPresentation',
  1007: 'BoardFamilyView',
  1015: 'BoardBlockView',

  2000: 'ShiftApp',
  2001: 'ShiftSidebarMenu',
  2002: 'ShiftScheduleViewer',
  2003: 'ShiftProviderSelector',
  2004: 'ShiftDefaultEdit',
  2005: 'ShiftTBD2',

  3000: 'AnalyticsApp',
  3001: 'AnalyticsSidebarMenu',
  3002: 'AnalyticsMetricGroupContribution',
  3003: 'AnalyticsMetricGroupScheduling',
  3004: 'AnalyticsMetricGroupEfficiency',
  3005: 'AnalyticsMetricCases',
  3006: 'AnalyticsMetricMinutes',
  3007: 'AnalyticsMetricContribution',
  3008: 'AnalyticsMetricContributionByMinute',
  3009: 'AnalyticsMetricAddOns',
  3010: 'AnalyticsMetricUtilization',
  3011: 'AnalyticsMetricScheduleAccuracy',
  3012: 'AnalyticsMetricCancellations',
  3013: 'AnalyticsMetricUnusedMinutes',
  3014: 'AnalyticsMetricDelayMinutes',
  3015: 'AnalyticsMetricFirstCaseOnTimeStarts',
  3016: 'AnalyticsMetricAfterHourCases',
  3017: 'AnalyticsMetricAfterHourMinutes',
  3018: 'AnalyticsMetricTurnoverRoom',
  3019: 'AnalyticsMetricTurnoverSurgeon',
  3020: 'AnalyticsMetricCharges',
  3021: 'AnalyticsMetricActualPayments',
  3022: 'AnalyticsMetricDirectCosts',
  3023: 'AnalyticsMetricGroupBlock',
  3024: 'AnalyticsMetricBlockSelf',
  3025: 'AnalyticsDefaultEdit',
  3026: 'AnalyticsMetricAdjustedScheduleAccuracy',
  3027: 'AnalyticsMetricGroupReportCard',
  3028: 'AnalyticsMetricReportCardSurgeon',
  3029: 'AnalyticsMetricReportCardAnesthesia',
  3030: 'AnalyticsMetricReportCardScrub',
  3031: 'AnalyticsMetricReportCardCirculator',
  3032: 'AnalyticsMetricReportCardSelf',
  3033: 'AnalyticsMetricGroupContributionLocked',
  3034: 'AnalyticsMetricGroupSchedulingLocked',
  3035: 'AnalyticsMetricGroupEfficiencyLocked',
  3036: 'AnalyticsMetricGroupBlockLocked',
  3037: 'AnalyticsMetricGroupUtilizationLocked',
  3038: 'AnalyticsMetricGroupQuality',
  3039: 'AnalyticsMetricGroupQualityLocked',
  3040: 'AnalyticsMetricFirstCaseOnTimeStartsNG',
  3041: 'AnalyticsMetricChargesReportCard',
  3042: 'AnalyticsMetricActualPaymentsReportCard',
  3043: 'AnalyticsMetricDirectCostsReportCard',
  3044: 'AnalyticsMetricProcedureDurationAccuracy',
  3045: 'AnalyticsMetricAdjustedProcedureDurationAccuracy',
  3046: 'AnalyticsMetricFirstCaseOnTimeStartsExpanded',
  3047: 'AnalyticsMetricGroupPrefCard',
  3048: 'AnalyticsMetricTurnoverFlipRoom',
  3049: 'AnalyticsMetricTurnoverFlipSurgeon',
  
  6000: 'SurgeryApp',
  6001: 'SurgerySidebarMenu',
  6002: 'SurgeryVendorRequest',
  6003: 'SurgeryVendorRequestViewSelf',
  6004: 'SurgeryVendorRequestViewAll',
  6005: 'SurgeryVendorRequestRepEdit',
  6006: 'SurgeryVendorRequestCspEdit',
  6007: 'SurgeryVendorRequestNotesEditSelf',
  6008: 'SurgeryVendorRequestNotesEditAll',
  6009: 'SurgeryStaffingScheduleView',
  6010: 'SurgeryStaffingScheduleEdit',
  6011: 'SurgeryVendorRequestLocked',
  6012: 'SurgeryStaffingScheduleLocked',
  4000: 'SurgeryCaseView',
  1008: 'SurgeryCaseProviderEdit',
  1009: 'SurgeryCaseNotesEdit',
  1010: 'SurgeryCaseStateEdit',
  1011: 'SurgeryCaseNotificationView',
  1012: 'SurgeryCaseNotificationEdit',
  1016: 'SurgeryCaseNotificationLocked',
  1017: 'SurgeryCaseMilestoneExpectedLocked',
  1013: 'SurgeryCasePatientView',
  1014: 'SurgeryCaseDefaultEdit',
  4001: 'SurgeryCaseVendorView',
  4002: 'SurgeryCaseVendorEdit',
  5000: 'SurgeryBlockView',
  5001: 'SurgeryBlockRelease',
  5002: 'SurgeryBlockSubscribe',
  5003: 'SurgeryBlockEdit',
  5004: 'SurgeryBlockLocked',
  5005: 'SurgeryBlockReleaseAdmin',
  5006: 'SurgeryRoomView',
  5007: 'SurgeryRoomEdit',
  6013: 'SurgeryAnnouncementView',
  6014: 'SurgeryAnnouncementEdit',
  6015: 'SurgeryOrgFlagLocked',
  6016: 'SurgeryBookingRequestViewSelf',
  6017: 'SurgeryBookingRequestViewAll',
  6018: 'SurgeryBookingRequestEditSelf',
  6019: 'SurgeryBookingRequestEditAll',
  6020: 'SurgeryBookingRequestBook',
  6021: 'SurgeryBookingRequestLocked',
  6022: 'SurgeryAreaView',
  6023: 'SurgeryAreaEdit',
  6024: 'SurgeryBookingPatientLookup',
  6025: 'SurgeryDefaultEdit',
  6026: 'SurgeryCaseSearch',
  6027: 'SurgeryProcedureView',
  6028: 'SurgeryProcedureEdit',
  6029: 'SurgeryBullpenView',
  6030: 'SurgeryBullpenEdit',
  6031: 'SurgeryRoomNoteEdit',

  7000: 'EntityApp',
  7001: 'EntityProviderList',
  7002: 'EntityProviderView',
  7003: 'EntityProviderEdit',
  7004: 'EntitySupplyItemView',
  7005: 'EntitySupplyItemEdit',
  7006: 'EntityPatientDemographicView',
  7007: 'EntityPatientDemographicEdit',
  7008: 'EntityPatientInsuranceView',
  7009: 'EntityPatientInsuranceEdit',
}

export class LoginUserOrg {
  orgLid: string;
  orgName: string;
  orgAppIds: Array<number>;
  defaultAppId: AppId;
  provider: Provider | undefined;
  permissionsRaw: Array<number>;
  permissions: Array<string>;
  
  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.orgName = d.org_name;
    this.orgAppIds = d.org_app_ids;
    this.defaultAppId = new AppId(+d.default_app_id);
    if (d.provider) this.provider = new Provider(d.provider);
    this.permissionsRaw = d.permissions;
    
    this.permissions = new Array();
    this.permissionsRaw.map(p => {
      if (PERMISSION_NAMES[p]) this.permissions.push(PERMISSION_NAMES[p]);
    });
  }

  toJson(): any {
    return {
      org_lid: this.orgLid,
      org_name: this.orgName,
      org_app_ids: this.orgAppIds,
      default_app_id: this.defaultAppId.id,
      provider: this.providerToJson(),
      permissions: this.permissionsRaw,
    }
  }

  providerToJson(): any {
    return this.provider ? this.provider.toJson() : undefined;
  }
}

export class AccessToken {
  token: string;
  expires: number;

  constructor(d: any) {
    this.token = d.token;
    this.expires = d.expires;
  }
}

export class LoginUser {
  userLid: string;
  displayName: string;
  accessToken: AccessToken;
  orgs: Array<LoginUserOrg>;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.displayName = d.display_name;
    this.accessToken = new AccessToken(d.access_token);
    this.orgs = d.orgs.map((o: any) => new LoginUserOrg(o));
  }
}

export class LoginUserCache {
  userLid: string;
  displayName: string;
  org: LoginUserOrg;

  constructor(d: any) {
    this.userLid = d.user_lid;
    this.displayName = d.display_name;
    this.org = new LoginUserOrg(d.org);
  }

  toJson(): any {
    return {
      user_lid: this.userLid,
      display_name: this.displayName,
      org: this.org.toJson()
    }
  }
}

export class SingleSignOn {
  domain: string;
  orgLid: string;
  type: number;
  url: string;
  logoutUrl: string;
  clientId: string;
  resourceId: string;
  
  constructor(d: any) {
    this.domain = d.domain;
    this.orgLid = d.org_lid;
    this.type = d.type;
    this.url = d.url;
    this.logoutUrl = d.logout_url;
    this.clientId = d.client_id;
    this.resourceId = d.resource_id;
  }
}