import { SurgeryCase } from "../case/surgery.model";
import { LocalDateRange } from "../common.model";
import { Provider } from "../provider/provider.model";
import { SurgeryRoom } from "../room/room.model";
import { SurgeryBlockAssignment } from "./org.model";
import { SurgeryBlockManualCredit } from "./org.surgery.block.model";

export class AddSurgeryBlockManualCreditResponse {
  credit: SurgeryBlockManualCredit;
  assignment: SurgeryBlockAssignment;
  surgery: SurgeryCase;
  provider: Provider;
  room: SurgeryRoom;

  constructor(d: any) {
    this.credit = new SurgeryBlockManualCredit(d.credit);
    this.assignment = new SurgeryBlockAssignment(d.assignment);
    this.surgery = new SurgeryCase(d.surgery);
    this.provider = new Provider(d.provider);
    this.room = new SurgeryRoom(d.room);
  }
}

export class GetSurgeryBlockManualCreditsByBlockResponse {
  blockLid: string;
  creditForFromTo: LocalDateRange | undefined;
  page: number;
  credits: Array<SurgeryBlockManualCredit>;
  hasMore: boolean;
  assignments: Array<SurgeryBlockAssignment>;
  surgeries: Array<SurgeryCase>;
  providers: Array<Provider>;
  rooms: Array<SurgeryRoom>;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.creditForFromTo = d.credit_for_from_to ? new LocalDateRange(d.credit_for_from_to) : undefined;
    this.page = d.page;
    this.credits = d.credits.map((c: any) => new SurgeryBlockManualCredit(c));
    this.hasMore = d.has_more;
    this.assignments = d.assignments.map((a: any) => new SurgeryBlockAssignment(a));
    this.surgeries = d.surgeries.map((s: any) => new SurgeryCase(s));
    this.providers = d.providers.map((p: any) => new Provider(p));
    this.rooms = d.rooms.map((r: any) => new SurgeryRoom(r));
  }
}

export class GetSurgeryBlockManualCreditsByBlockCountResponse {
  blockLid: string;
  creditForFromTo: LocalDateRange | undefined;
  count: number;
  pageSize: number;

  constructor(d: any) {
    this.blockLid = d.block_lid;
    this.creditForFromTo = d.credit_for_from_to ? new LocalDateRange(d.credit_for_from_to) : undefined;
    this.count = d.count;
    this.pageSize = d.page_size;
  }
}
