import { Component, Input, OnInit } from '@angular/core';

import { AppState } from '@app/app.service';
import { OrgCacheService } from '@app/data/org-cache.service';

import { GetPerioperativeStatesResponse } from '@app/data/org/org.surgery.message';

import type { UISurgeryStateConfig } from '../surgery-state-style/surgery-state-style.directive';

@Component({
  selector: 'ui-surgery-state-label',
  template: '<span class="px-1" [ngStyle]="styles" *ngIf="label">{{label}}</span>',
})
export class UISurgeryStateLabelComponent implements OnInit {

  @Input() config!: UISurgeryStateConfig;

  private _orgId!: string;
  
  public label: string | undefined = undefined;
  public styles: {
    'background-color': string,
    'color': string,
  } = {
    'background-color': '#2c405a',
    'color': '#ffffff',
  }

  constructor(
    private _appState: AppState,
    private _orgCacheService: OrgCacheService,
  ){}

  ngOnInit(): void {
    this._orgId = this._appState.get('orgId');
    this._orgCacheService.getSurgeryStates(this._orgId).subscribe({
      next: (response: GetPerioperativeStatesResponse) => {
        let match = undefined;
        if (this.config.status === 3) {
          match = response.canceled;
        } else {
          match = response.states.find((s) => s.state === this.config.state);
        }
        if (match) {
          this.label = this.config.external ? match.externalName : match.internalName;
          this.styles = {
            'background-color': '#' + (this.config.external ? match.externalColorBackground : match.internalColorBackground),
            'color': '#' + (this.config.external ? match.externalColorText : match.internalColorText),
          }
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}