export class SurgeryBlockUtilizationPrediction {
  orgLid: string;
  blockLid: string;
  assignmentLid: string;
  assignmentDate: string;
  predictionDate: string;
  modelName: string;
  utilization: number;
  created: number;
  modified: number;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.blockLid = d.block_lid;
    this.assignmentLid = d.assignment_lid;
    this.assignmentDate = d.assignment_date;
    this.predictionDate = d.prediction_date;
    this.modelName = d.model_name;
    this.utilization = d.utilization;
    this.created = d.created;
    this.modified = d.modified;
  }
}
