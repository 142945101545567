export class PhoneNumber {
  e164Format: string;
  nationalFormat: string;
  internationalFormat: string;

  constructor(d: any) {
    this.e164Format = d.e164_format;
    this.nationalFormat = d.national_format;
    this.internationalFormat = d.international_format;
  }
}