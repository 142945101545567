<div class="news-headline {{category}}" [ngSwitch]="type" [ngClass]="{ 'is-presenting': isPresenting, 'is-in-detail': isInDetail }">
  <span *ngSwitchCase="1">
    <span *ngIf="!isPresenting">Case <a href="" (click)="showDetail(data.case_lid)"  class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} - </span>Scheduled at {{data.new_start_time}} <span *ngIf="isInDetail">in {{data.room}}</span>
  </span>
  <span *ngSwitchCase="2">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <ng-container *ngIf="data.periop_state_timestamp_label">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_AT_TIME' | translate:{ periop_status: data.op_state_label, time: data.periop_state_timestamp_label } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_AT_TIME' | translate:{ periop_status: data.op_state_label, time: data.periop_state_timestamp_label } | translateCut:1 }}
    </ng-container>
    <ng-container *ngIf="!data.periop_state_timestamp_label">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_REMOVED' | translate:{ periop_status: data.op_state_label } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_REMOVED' | translate:{ periop_status: data.op_state_label } | translateCut:1 }}
    </ng-container>    
  </span>
  <span *ngSwitchCase="3">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span>{{ 'NEWS.MOVED_TO_ROOM_AT_TIME' | translate:{ room_name: data.new_room, time: data.new_start_time} }}
  </span>
  <span *ngSwitchCase="4">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span>{{ 'NEWS.MOVED_TO_NEW_DATE_TIME' | translate:{ new_date: data.new_start_date, new_time: data.new_start_time} }}
  </span>
  <span *ngSwitchCase="5">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span>{{ 'NEWS.MOVED_TO_NEW_TIME' | translate:{ new_time: data.new_start_time } }}
  </span>
  <span *ngSwitchCase="6">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    {{ 'NEWS.SCHEDULED_START_CONFLICTS' | translate:{ case_id: data.other_case_id } }}
  </span>
  <span *ngSwitchCase="7">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    {{ 'NEWS.EXPECTED_END_CONFLICTS_NEXT_CASE' | translate:{ case_id: data.other_case_id} }}
  </span>
  <span *ngSwitchCase="8">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <ng-container *ngIf="data.duration > 1">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_DELAYED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_DELAYED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:1 }}
    </ng-container>
    <ng-container *ngIf="data.duration == 1">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_DELAYED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_DELAYED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:1 }}
    </ng-container>
  </span>
  <span *ngSwitchCase="9">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <ng-container *ngIf="data.duration > 1">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_EXPECTED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_EXPECTED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:1 }}
    </ng-container>
    <ng-container *ngIf="data.duration == 1">
      <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.PERI_OP_EXPECTED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:0 }}</span> {{ 'NEWS.PERI_OP_EXPECTED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:1 }}
    </ng-container>
  </span>
  <span *ngSwitchCase="10">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <ng-container *ngIf="data.duration > 1">
      {{ 'NEWS.CASE_DELAYED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:0 }} <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.CASE_DELAYED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:1 }}</span> {{ 'NEWS.CASE_DELAYED_MINUTES' | translate:{ periop_status: data.op_state_label, minutes: data.duration } | translateCut:2 }}
    </ng-container>
    <ng-container *ngIf="data.duration == 1">
      {{ 'NEWS.CASE_DELAYED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:0 }} <span class="op-label" [ui-surgery-state-style]="{ status: 1, state: data.periop_state | string2Number, external: false }">{{ 'NEWS.CASE_DELAYED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:1 }}</span> {{ 'NEWS.CASE_DELAYED_MINUTE' | translate:{ periop_status: data.op_state_label } | translateCut:2 }}
    </ng-container>
    <span *ngIf="data.reason">({{data.reason}})</span>
  </span>
  <span *ngSwitchCase="11" class="multi-cases">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - 
      <ng-container *ngIf="data.cases && data.cases.length > 1">
        {{ 'NEWS.CASE_DELAYING_CASES' | translate }}
      </ng-container>
      <ng-container *ngIf="!data.cases || data.cases.length == 1">
        {{ 'NEWS.CASE_DELAYING_CASE' | translate }}
      </ng-container>
      <span class="case-item" *ngFor="let case of data.cases; let i = index;">
        <a href="#" (click)="showDetail(data.caseIds[i])" class="case-id">{{case}}</a>
      </span>
    </span>
    <span *ngIf="isInDetail">
      {{ 'NEWS.DELAYED_BECAUSE_OF' | translate:{ case_id: data.case_id} }}
    </span>
  </span>
  <span *ngSwitchCase="12">
    <span *ngIf="!isInDetail">
      <ng-container *ngIf="data.duration > 1">
        {{ 'NEWS.ROOM_AFTER_HOUR_MINUTES' | translate:{ room_name: data.room, minutes: data.duration } }}
      </ng-container>
      <ng-container *ngIf="data.duration == 1">
        {{ 'NEWS.ROOM_AFTER_HOUR_MINUTE' | translate:{ room_name: data.room } }}
      </ng-container>
    </span>
  </span>
  <span *ngSwitchCase="13">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    {{ 'NEWS.MOVE_TO_ROOM' | translate:{ room_name: data.new_room, time: data.new_start_time} }}
  </span>
  <span *ngSwitchCase="14">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    {{ 'NEWS.NOTIFY_SURGEON' | translate:{ surgeon_last_name: data.primary_surgeon_last_name, time: data.new_start_time } }}
  </span>
  <span *ngSwitchCase="15">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} -
      Consider swapping with case<span *ngIf="data.cases.length > 1">s</span>
      <span class="case-item" *ngFor="let case of data.cases; let i = index;">
        <a href="" (click)="showDetail(data.caseIds[i])" class="case-id">{{case}}</a>
      </span> to avoid delays
    </span>
    <span *ngIf="isInDetail">Consider swapping with case {{data.case_id}}</span>
  </span>
  <span *ngSwitchCase="16">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span>{{ 'NEWS.ADD_ON_REQUESTED' | translate }}
  </span>
  <span *ngSwitchCase="17">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    {{ 'NEWS.START_EARLIER' | translate:{ time: data.new_start_time } }}
  </span>
  <span *ngSwitchCase="18">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    <span class="op-label" [ui-surgery-state-style]="{ status: 3, state: 1, external: false }">{{ 'NEWS.CASE_CANCELED' | translate }}</span> <span *ngIf="data.reason">({{data.reason}})</span>
  </span>
  <span *ngSwitchCase="19">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}</span> {{ 'NEWS.ROLE_ADDED' | translate:{ provider_name: data.provider_full_name, role_name: data.provider_role_name } }}
  </span>
  <span *ngSwitchCase="20">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}</span> {{ 'NEWS.ROLE_REMOVED' | translate:{ provider_name: data.provider_full_name, role_name: data.provider_role_name } }}
  </span>
  <span *ngSwitchCase="21">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span> <q>{{data.note}}</q>
  </span>
  <span *ngSwitchCase="23">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span *ngIf="!isInDetail">{{data.primary_surgeon_last_name}} / {{data.primary_anesthesia_name}} - </span>
    {{ 'NEWS.PREDICTED_NEW_CASE_DURATION' | translate:{ minutes: data.new_duration } }}
  </span>
  <span *ngSwitchCase="24">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span> {{ 'NEWS.LABEL_ADDED' | translate:{ label_name: data.label_name } }} <span class="case-label" [ngStyle]="{ 'background-color': '#' + data.label_color }">{{data.label_initial}}</span>
  </span>
  <span *ngSwitchCase="25">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}:&nbsp;</span> {{ 'NEWS.LABEL_REMOVED' | translate:{ label_name: data.label_name } }} <span class="case-label" [ngStyle]="{ 'background-color': '#' + data.label_color }">{{data.label_initial}}</span>
  </span>
  <span *ngSwitchCase="26">
    <span *ngIf="!isPresenting">{{ "CASE" | translate }} <a href="" (click)="showDetail(data.case_lid)" class="case-id">{{data.case_id}}</a> - </span>
    <span class="actor-name" *ngIf="actor && actor.name">{{actor.name}}</span> {{ 'NEWS.SENT_MESSAGE_TO_FAMILY' | translate }} "{{data.message_body}}"
  </span>
</div>