import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@app/app.service';
import { OrgService } from '@app/data/org/org.service';

import { SurgerySpecialty } from '@app/data/org/org.model';

import { GetSpecialitiesResponse } from '@app/data/org/org.message';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-specialty-picker',
  templateUrl: './specialty-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SpecialtyPickerComponent
    },
    OrgService
  ]
})

export class SpecialtyPickerComponent implements ControlValueAccessor, OnInit {

  onChange = (s: SurgerySpecialty | undefined) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  private _orgId!: string;
  
  public placeholder: string;
  public searchStr?: string;
  public specialties?: Array<SurgerySpecialty>;
  public selected?: SurgerySpecialty;

  constructor(
    private _translate: TranslateService,
    private _appState: AppState,
    private _orgService: OrgService,
  ){
    this.placeholder = this._translate.instant('SEARCH');
  }

  ngOnInit() {
    this._orgId = this._appState.get('orgId');
    this._getSpecialties();
  }

  writeValue(s: SurgerySpecialty): void {
    this.selected = s;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public onSelect(e: TypeaheadMatch): void {
    this.markAsTouched();
    if (e) {
      this.selected = e.item;
    } else {
      this.selected = undefined;
    }
    this.onChange(this.selected);
  }

  private _getSpecialties() {
    this._orgService.getSpecialities(this._orgId)
      .subscribe((response: GetSpecialitiesResponse) => {
        this.specialties = response.specialties;
      },
      err => {
        console.error(err);
      });
  }

  
}