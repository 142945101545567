<div class="generic-action-bar">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="navbar-brand" *ngIf="breadcrumbs.length > 0">
        <ol class="breadcrumb bg-light py-0">
          <li class="breadcrumb-item" *ngFor="let crumb of breadcrumbs; let i = index" [ngClass]="{'active': i == breadcrumbs.length-1 }">
            <a class="text-decoration-none" [routerLink]="crumb.commands" [queryParams]="crumb.extras ? crumb.extras.queryParams : {}" [queryParamsHandling]="crumb.extras ? crumb.extras.queryParamsHandling : null" [fragment]="crumb.extras ? crumb.extras.fragment : undefined" *ngIf="crumb.commands">{{crumb.label}}</a>
            <ng-container *ngIf="!crumb.commands">{{ crumb.label }}</ng-container>
          </li>
        </ol>
      </div>

      <!-- <button class="navbar-toggler" type="button" (click)="toggleActionBar()">
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <div class="collapse navbar-collapse justify-content-end" [ngClass]="{ 'show': actionBarOpen }"> 
        <ul class="navbar-nav">
          <li class="nav-item">&nbsp;</li>
        </ul>
      </div>
    </div>
  </nav>
</div>