import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { ProcedureCodeSet } from "../org/procedure.model";
import { ProviderIdType } from "../provider/provider.model";
import { ProcedureDocument, ProviderDocument, SurgeryCaseDocument, SurgeryCaseQueryRequest } from "./search.model";

export class SearchProcedureResponse {
  results: Array<ProcedureDocument>;
  codeSets: Array<ProcedureCodeSet>;

  constructor(d: any) {
    this.results = d.results.map((r: any) => new ProcedureDocument(r));
    this.codeSets = d.code_sets.map((cs: any) => new ProcedureCodeSet(cs));
  }
}

export class SearchProviderResponse {
  results: Array<ProviderDocument>;
  idTypes: Array<ProviderIdType>;

  constructor(d: any) {
    this.results = d.results.map((r: any) => new ProviderDocument(r));
    this.idTypes = d.id_types.map((it: any) => new ProviderIdType(it));
  }
}

@jsonObject
export class SearchSurgeryCaseResponse {
  @jsonMember(String, { name: 'org_lid' })
  orgLid!: string;

  @jsonMember(SurgeryCaseQueryRequest)
  request!: SurgeryCaseQueryRequest;

  @jsonArrayMember(SurgeryCaseDocument)
  results!: Array<SurgeryCaseDocument>;

  @jsonMember(Number, { name: 'total_hits' })
  totalHits!: number;
}