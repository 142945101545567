import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './pdf-viewer-modal.component.html'
})
export class PdfViewerModalComponent implements OnInit, OnDestroy {
  
  private _routerSubscription?: Subscription;

  pdfObservable?: Observable<any>;
  pdf?: string;

  constructor(
    protected _router: Router,
    public bsModalRef: BsModalRef,
  ){}

  ngOnInit(): void {
    if (this._routerSubscription) this._routerSubscription.unsubscribe();
    this._routerSubscription = this._router.events.subscribe(e => {
      this.bsModalRef.hide();
    });

    if (this.pdfObservable) {
      this.pdfObservable.subscribe((data: Blob) => {
        this.pdf = window.URL.createObjectURL(data);
      },
      (error) =>{
        console.error(error);
      });
    }
  }

  ngOnDestroy() {
    if (this._routerSubscription) this._routerSubscription.unsubscribe();
  }
}