<ng-template #specialtyTemplate let-specialty="item">
  <div class="row">
    <div class="col">
      {{ specialty.name }} 
    </div>
  </div>
</ng-template>
 
<input [(ngModel)]="searchStr" 
  [disabled]="disabled"
  [typeahead]="specialties" 
  [typeaheadItemTemplate]="specialtyTemplate"
  [typeaheadMinLength]="0"
  (typeaheadOnSelect)="onSelect($event)"
  typeaheadOptionField="name"
  placeholder="{{ placeholder }}"
  class="form-control">