import {Pipe, PipeTransform} from '@angular/core';

/*
 * Returns the label of a record in a map looked up based on value
 *
 * Usage:
 *   obj | objectExpress:func
 * Example:
 *   {{ userObject | objectExpress:getUserText }}
*/
@Pipe({name: 'objectExpress'})
export class ObjectExpressPipe implements PipeTransform {
  transform(obj: any, func: (arg0: any) => string): string {
    if (obj === undefined) return '';
    return func(obj);
  }
}
