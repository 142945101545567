import moment from 'moment';
import { Injectable } from '@angular/core';

export const LONG_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const SHORT_DATE_FORMAT = 'MMMM D, YYYY';
export const LONG_TIME_FORMAT = 'HH:mm';
export const SHORT_TIME_FORMAT = 'HHmm';
export const DATE_PICKER_FORMAT = 'MMM DD, YYYY hh:mm a';
export const NUMERIC_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'MMM DD, YYYY - HHmm';
export const SHORT_DATE_LABEL_FORMAT = 'ddd, MMM DD';

@Injectable()
export class TimerService {

  getCurrentDate(isLong: Boolean = true): String {
    return moment().format(isLong ? LONG_DATE_FORMAT : SHORT_DATE_FORMAT);
  }

  getCurrentTime(isLong: Boolean = true): String {
    return moment().format(isLong ? LONG_TIME_FORMAT : SHORT_TIME_FORMAT);
  }

  formatDateString(dateString: string, isLong: Boolean =  true) {
    return moment(dateString).format(isLong ? LONG_DATE_FORMAT : SHORT_DATE_FORMAT);
  }

  parseDatePickerValue(dateString: string) {
    return moment(dateString, DATE_PICKER_FORMAT);
  }

  formatDatePickerValue(timeObj: moment.Moment): String {
    return timeObj.format(DATE_PICKER_FORMAT);
  }

  convertToDateString(timeObj: moment.Moment, isLong: Boolean = true): String {
    return timeObj.format(isLong ? LONG_DATE_FORMAT : SHORT_DATE_FORMAT)
  }

  convertToTimeString(timeObj: moment.Moment, isLong: Boolean = true): String {
    return timeObj.format(isLong ? LONG_TIME_FORMAT : SHORT_TIME_FORMAT)
  }

  getTimeSlots(date: moment.Moment, startHour: number = 0, numberOfSlots: number = 47): Array<String> {
    let _slots = [];
    let _slotIdx = 0;
    let _baseTime = date.hour(startHour).minute(0);
    _slots.push(_baseTime.format(SHORT_TIME_FORMAT));
    while (_slotIdx < numberOfSlots) {
      _slots.push(_baseTime.add(30, 'minute').format(SHORT_TIME_FORMAT));
      _slotIdx++;
    }
    return _slots;
  }

}