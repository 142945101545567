import moment from 'moment';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'age'})
export class AgePipe implements PipeTransform {
  transform(value: moment.MomentInput, ...args: string[]): any {
    if (!value) return value;
    return moment().diff(value, 'years');
  }
}
