import {Pipe, PipeTransform} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

/*
 * Turn an enum value into it's display name
 *
 * Usage:
 *   value | displayEnum:type
 * Example:
 *   {{ 2 | displayEnum:'AnesthesiaType' }}
 *   formats to: Regional
*/
@Pipe({name: 'displayEnum'})
export class DisplayEnumPipe implements PipeTransform {
  constructor(
    private _translate: TranslateService
  ){}

  transform(value: number | string | undefined, enumType: string): string {
    if (value === undefined) return '';

    const _value = Number(value);
    
    switch(enumType) {
      case 'AnesthesiaType':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.ANESTHESIA_TYPE.AXILLARY_BLOCK');
          case 2: return this._translate.instant('ENUM.ANESTHESIA_TYPE.EPIDURAL');
          case 3: return this._translate.instant('ENUM.ANESTHESIA_TYPE.GENERAL');
          case 4: return this._translate.instant('ENUM.ANESTHESIA_TYPE.GENERAL_WITH_EPIDURAL');
          case 5: return this._translate.instant('ENUM.ANESTHESIA_TYPE.LOCAL');
          case 6: return this._translate.instant('ENUM.ANESTHESIA_TYPE.MAC');
          case 7: return this._translate.instant('ENUM.ANESTHESIA_TYPE.MODERATE_SEDATION');
          case 8: return this._translate.instant('ENUM.ANESTHESIA_TYPE.SPINAL');
          case 9: return this._translate.instant('ENUM.ANESTHESIA_TYPE.NONE');
          case 10: return this._translate.instant('ENUM.ANESTHESIA_TYPE.DEEP_SEDATION');
          case 11: return this._translate.instant('ENUM.ANESTHESIA_TYPE.IV_REGIONAL');
          case 12: return this._translate.instant('ENUM.ANESTHESIA_TYPE.CHOICE');
        }
        break;

      case 'AnnouncementMessageType':
        switch(_value) {
          case 1: return this._translate.instant('SURGERY.ANNOUNCEMENT.MESSAGE_TYPE.DANGER');
          case 2: return this._translate.instant('SURGERY.ANNOUNCEMENT.MESSAGE_TYPE.INFO');
          case 3: return this._translate.instant('SURGERY.ANNOUNCEMENT.MESSAGE_TYPE.SUCCESS');
          case 4: return this._translate.instant('SURGERY.ANNOUNCEMENT.MESSAGE_TYPE.WARNING');
        }
        break;

      case 'AuditEventType':
        switch(_value) {
          case 1000: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.PATIENT_RECORD_VIEWED');
          case 2000: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_CREATED');
          case 2001: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_SESSION_STARTED');
          case 2002: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_ROLE_ADDED');
          case 2003: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_ROLE_REMOVED');
          case 2004: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_NAME_CHANGED');
          case 2005: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_INVALID_CREDENTIALS');
          case 2006: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_PASSWORD_LOCKED');
          case 2007: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_PASSWORD_RESET_REQUEST');
          case 2008: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_PASSWORD_CHANGED');
          case 3000: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_ACCESS_EXPIRE_CHANGED');
          case 3001: return this._translate.instant('ENUM.AUDIT_EVENT_TYPE.USER_ACCESS_EXPIRE_REMOVED');
        }
        break;

      case 'DayOfWeek':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.DAY_OF_WEEK.MONDAY');
          case 2: return this._translate.instant('ENUM.DAY_OF_WEEK.TUESDAY');
          case 3: return this._translate.instant('ENUM.DAY_OF_WEEK.WEDNESDAY');
          case 4: return this._translate.instant('ENUM.DAY_OF_WEEK.THURSDAY');
          case 5: return this._translate.instant('ENUM.DAY_OF_WEEK.FRIDAY');
          case 6: return this._translate.instant('ENUM.DAY_OF_WEEK.SATURDAY');
          case 7: return this._translate.instant('ENUM.DAY_OF_WEEK.SUNDAY');
        }
        break;

      case 'DayOfWeekShort':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.MON');
          case 2: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.TUE');
          case 3: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.WED');
          case 4: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.THU');
          case 5: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.FRI');
          case 6: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.SAT');
          case 7: return this._translate.instant('ENUM.DAY_OF_WEEK_SHORT.SUN');
        }
        break;

      case 'EncounterClass':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.ENCOUNTER_CLASS.INPATIENT');
          case 2: return this._translate.instant('ENUM.ENCOUNTER_CLASS.OUTPATIENT');
          case 3: return this._translate.instant('ENUM.ENCOUNTER_CLASS.EMERGENCY');
        }
        break;
    
      case 'ItemCategory':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.ITEM_CATEGORY.IMPLANT');
          case 2: return this._translate.instant('ENUM.ITEM_CATEGORY.INSTRUMENT');
          case 3: return this._translate.instant('ENUM.ITEM_CATEGORY.SUPPLY');
          case 5: return this._translate.instant('ENUM.ITEM_CATEGORY.PHARMACY');
        }
        break;

      case 'Gender':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.GENDER.MALE');
          case 2: return this._translate.instant('ENUM.GENDER.FEMALE');
          case 3: return this._translate.instant('ENUM.GENDER.UNKNOWN');
          case 4: return this._translate.instant('ENUM.GENDER.OTHER');
        }
        break;

      case 'GenderInitial':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.GENDER.MALE_INITIAL');
          case 2: return this._translate.instant('ENUM.GENDER.FEMALE_INITIAL');
          case 3: return this._translate.instant('ENUM.GENDER.UNKNOWN_INITIAL');
          case 4: return this._translate.instant('ENUM.GENDER.OTHER_INITIAL');
        }
        break;

      case 'GenericStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.GENERIC_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.GENERIC_STATUS.INACTIVE');
        }
        break;

      case 'MediumStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.MEDIUM_STATUS.ENABLED');
          case 2: return this._translate.instant('ENUM.MEDIUM_STATUS.DISABLED');
        }
        break;

      case 'MediumType':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.MEDIUM_TYPE.EMAIL');
          case 2: return this._translate.instant('ENUM.MEDIUM_TYPE.MOBILE');
        }
        break;

      case 'ProviderRoleType':
        switch(_value) {
          case 1000: return this._translate.instant('ENUM.PROVIDER_ROLE_TYPE.SURGERY_VENDOR_REP');
          case 1001: return this._translate.instant('ENUM.PROVIDER_ROLE_TYPE.SURGEON_CLINIC_STAFF');
        }
        break;

      case 'ProviderStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.PROVIDER_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.PROVIDER_STATUS.INACTIVE');
          case 3: return this._translate.instant('ENUM.PROVIDER_STATUS.DUPLICATE');
        }
        break;

      case 'SurgeryBlockProviderStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_BLOCK_PROVIDER_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.SURGERY_BLOCK_PROVIDER_STATUS.INACTIVE');
        }
        break;

      case 'SurgeryBlockStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_BLOCK_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.SURGERY_BLOCK_STATUS.INACTIVE');
        }
        break;

      case 'SurgeryBookingRequestStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_BOOKING_REQUEST_STATUS.INCOMPLETE');
          case 2: return this._translate.instant('ENUM.SURGERY_BOOKING_REQUEST_STATUS.PENDING_REVIEW');
          case 3: return this._translate.instant('ENUM.SURGERY_BOOKING_REQUEST_STATUS.IN_REVIEW');
          case 5: return this._translate.instant('ENUM.SURGERY_BOOKING_REQUEST_STATUS.BOOKED');
          case 6: return this._translate.instant('ENUM.SURGERY_BOOKING_REQUEST_STATUS.CANCELED');
        }
        break;

      case 'SurgeryCaseSmsFollowerStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_CASE_SMS_FOLLOWER_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.SURGERY_CASE_SMS_FOLLOWER_STATUS.PENDING');
          case 3: return this._translate.instant('ENUM.SURGERY_CASE_SMS_FOLLOWER_STATUS.FAILED');
          case 4: return this._translate.instant('ENUM.SURGERY_CASE_SMS_FOLLOWER_STATUS.STOPPED');
        }
        break;

      case 'SurgeryCaseType':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_CASE_TYPE.SCHEDULED');
          case 2: return this._translate.instant('ENUM.SURGERY_CASE_TYPE.ADD_ON');
          case 3: return this._translate.instant('ENUM.SURGERY_CASE_TYPE.UNKNOWN');
        }
        break;

      case 'SurgeryLocation':
        switch(_value) {
          case 5: return this._translate.instant('ENUM.SURGERY_LOCATION.PRE_OP');
          case 12: return this._translate.instant('ENUM.SURGERY_LOCATION.PACU_I');
          case 14: return this._translate.instant('ENUM.SURGERY_LOCATION.PACU_II');
        }
        break;

      case 'SurgeryRoomPrimeTimeStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_ROOM_PRIME_TIME_STATUS.ACTIVE');
          case 2: return this._translate.instant('ENUM.SURGERY_ROOM_PRIME_TIME_STATUS.IN_ACTIVE');
        }
        break;

      case 'SurgeryScheduleStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.PENDING');
          case 2: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.SCHEDULED');
          case 3: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.CANCELED');
        }
        break;

      case 'SurgeryScheduleType':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.ELECTIVE');
          case 2: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.URGENT');
          case 3: return this._translate.instant('ENUM.SURGERY_SCHEDULE_STATUS.EMERGENCY');
        }
        break;
  
        case 'SurgerySSIType':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.SURGERY_SSI_TYPE.DEEP_INCISIONAL_PRIMARY');
          case 2: return this._translate.instant('ENUM.SURGERY_SSI_TYPE.DEEP_INCISIONAL_SECONDARY');
          case 3: return this._translate.instant('ENUM.SURGERY_SSI_TYPE.ORGAN_SPACE');
          case 4: return this._translate.instant('ENUM.SURGERY_SSI_TYPE.SUPERFICIAL_INCISIONAL_PRIMARY');
          case 5: return this._translate.instant('ENUM.SURGERY_SSI_TYPE.SUPERFICIAL_INCISIONAL_SECONDARY');
        }
        break;

      case 'UserPasswordStatus':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.USER_PASSWORD_STATUS.NOT_SET');
          case 2: return this._translate.instant('ENUM.USER_PASSWORD_STATUS.VALID');
          case 3: return this._translate.instant('ENUM.USER_PASSWORD_STATUS.LOCKED');
        }
        break;

      case 'WoundClass':
        switch(_value) {
          case 1: return this._translate.instant('ENUM.WOUND_CLASS.CLEAN');
          case 2: return this._translate.instant('ENUM.WOUND_CLASS.CLEAN_CONTAMINATED');
          case 3: return this._translate.instant('ENUM.WOUND_CLASS.DIRTY');
          case 4: return this._translate.instant('ENUM.WOUND_CLASS.CONTAMINATED');
        }
        break;
    }
    
    return this._translate.instant('ENUM.UNKNOWN');
  }
}
