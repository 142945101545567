import {Pipe, PipeTransform} from '@angular/core';

/*
 * Returns a generic value from a record in a map looked up based on a key
 *
 * Usage:
 *   key | mapLookupGeneric:map:valueGetter
 * Example:
 *   {{ 100 | mapLookupGeneric:metricMap:getMetric }}
*/
@Pipe({name: 'mapLookupGeneric'})
export class MapLookupGenericPipe implements PipeTransform {
  transform<T>(key: string, map: Map<string, any> | undefined, valueGetter: (arg0: any) => T, unknown?: T): T | undefined {
    if (key === undefined) return unknown;
    if (map === undefined) return unknown;

    const obj = map.get(key);
    if (obj) {
      return valueGetter(obj);
    } else {
      return unknown;
    }
  }
}
