{
  "name": "leaprail-spa",
  "version": "17.2.10",
  "description": "Leap Rail Single Page App",
  "license": "UNLICENSED",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-dev": "ng build --configuration development",
    "build-staging": "ng build --configuration staging --source-map && MINIFIED_PATH_PREFIX='https://staging-apps.leaprail.us' ./scripts/upload-sourcemaps.sh",
    "build-production": "ng build --configuration production --source-map && MINIFIED_PATH_PREFIX='https://apps.leaprail.com' ./scripts/upload-sourcemaps.sh",
    "build:stats": "ng build --configuration development --stats-json",
    "analyze": "webpack-bundle-analyzer dist/leaprail-spa/stats.json",
    "clean:dist": "npm run rimraf -- dist",
    "i18n:init": "ngx-translate-extract --input ./src --output ./src/assets/i18n/template.json --key-as-default-value -s --replace --format json",
    "i18n:extract": "ngx-translate-extract --input ./src --output ./src/assets/i18n/{en,es}.json --key-as-default-value -s --clean --format json"
  },
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@datadog/browser-logs": "^5.34.1",
    "@ng-idle/core": "^16.0.0",
    "@ng-idle/keepalive": "^16.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@types/daterangepicker": "^3.1.8",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.7",
    "chartjs-plugin-datalabels": "^2.2.0",
    "daterangepicker": "^3.1.0",
    "deepmerge": "^4.3.1",
    "file-type": "^19.6.0",
    "libphonenumber-js": "^1.11.17",
    "moment": "^2.30.1",
    "ng2-charts": "^7.0.0",
    "ngx-bootstrap": "^18.1.3",
    "ngx-cookie-service": "^18.0.0",
    "ngx-file-drop": "^14.0.2",
    "ngx-moment": "^6.0.2",
    "ngx-permissions": "^17.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "typedjson": "^1.8.0",
    "zone.js": "~0.14.0",
    "zxcvbn": "^4.4.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@types/node": "^22.10.2",
    "@types/zxcvbn": "^4.4.5",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "^4.10.2"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/leaprail/leaprail-spa.git"
  },
  "bugs": {
    "url": "https://github.com/leaprail/leaprail-spa/issues"
  }
}