import {Pipe, PipeTransform} from '@angular/core';

/*
 * Returns a numeric value from a record in a map looked up based on a key
 *
 * Usage:
 *   key | mapLookupNumber:map:valueGetter
 * Example:
 *   {{ 'tiger' | mapLookupNumber:animalWeights:getWeight }}
*/
@Pipe({name: 'mapLookupNumber'})
export class MapLookupNumberPipe implements PipeTransform {
  transform<T>(key: T | undefined, map: Map<T, any> | undefined, valueGetter: (arg0: any) => number, unknown: number = 0): number {
    if (key === undefined) return unknown;
    if (map === undefined) return unknown;

    const obj = map.get(key);
    if (obj) {
      return valueGetter(obj);
    } else {
      return unknown;
    }
  }
}
