export class Item {
  public itemLid: string;
  public orgLid: string;
  public name: string;
  public category: number;
  public subCategoryLid: string | undefined;
  public manufacturerId: string;
  public manufacturerName: string;
  public manufacturerItemId: string;
  
  constructor(d: any) {
    this.itemLid = d.item_lid;
    this.orgLid = d.org_lid;
    this.name = d.name;
    this.category = d.category;
    this.subCategoryLid = d.sub_category_lid;
    this.manufacturerId = d.manufacturer_id;
    this.manufacturerName = d.manufacturer_name;
    this.manufacturerItemId = d.manufacturer_item_id;
  }
}

export class ItemId {
  public orgLid: string;
  public codeSet: string;
  public code: string;
  public itemLid: string;

  constructor(d: any) {
    this.orgLid = d.org_lid;
    this.codeSet = d.code_set;
    this.code = d.code;
    this.itemLid = d.item_lid;
  }
}

export class ItemWithIds {
  public ids: Array<ItemId>;
  public item: Item;

  constructor(d: any) {
    this.ids = d.ids.map((i: any) => new ItemId(i));
    this.item = new Item(d.item);
  }
}

export class ItemPrice {
  public itemPriceLid: string;
  public itemLid: string;
  public supplierId: string | undefined;
  public supplierName: string | undefined;
  public supplierItemId: string | undefined;
  public contract: string | undefined;
  public price: number;
  public effectiveFrom: string;
  public effectiveTo: string | undefined;

  constructor(d: any) {
    this.itemPriceLid = d.item_price_lid;
    this.itemLid = d.item_lid;
    this.supplierId = d.supplier_id;
    this.supplierName = d.supplier_name;
    this.supplierItemId = d.supplier_item_id;
    this.contract = d.contract;
    this.price = d.price;
    this.effectiveFrom = d.effective_from;
    this.effectiveTo = d.effective_to;
  }
}

export class ItemSubCategory {
  public subCategoryLid: string;
  public orgLid: string;
  public category: number;
  public name: string;

  constructor(d: any) {
    this.subCategoryLid = d.sub_category_lid;
    this.orgLid = d.org_lid;
    this.category = d.category;
    this.name = d.name;
  }
}

export class GetSupplyItemResponse {
  public item: Item;
  public ids: Array<ItemId>;
  public prices: Array<ItemPrice>;
  public subCategory: ItemSubCategory | undefined;

  constructor(d: any) {
    this.item = new Item(d.item);
    this.ids = d.ids.map((i: any) => new ItemId(i));
    this.prices = d.prices.map((p: any) => new ItemPrice(p));
    if (d.sub_category) this.subCategory = new ItemSubCategory(d.sub_category);
  }
}