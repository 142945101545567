import { isReflectMetadataSupported, isSubtypeOf, isValueDefined, LAZY_TYPE_EXPLANATION, logError, logWarning, MISSING_REFLECT_CONF_MSG, nameof } from './helpers';
import { injectMetadataInformation } from './metadata';
import { extractOptionBase } from './options-base';
import { ArrayTypeDescriptor, ensureTypeDescriptor, ensureTypeThunk, isTypelike, isTypeThunk, MapTypeDescriptor, SetTypeDescriptor, TypeDescriptor } from './type-descriptor';
export function jsonMember(optionsOrPrototype, propertyKeyOrOptions) {
  if (typeof propertyKeyOrOptions === 'string' || typeof propertyKeyOrOptions === 'symbol') {
    const property = propertyKeyOrOptions;
    const prototype = optionsOrPrototype;
    const decoratorName = `@jsonMember on ${nameof(prototype.constructor)}.${String(property)}`;
    if (!isReflectMetadataSupported) {
      logError(`${decoratorName}: ReflectDecorators is required if the type is not \
explicitly provided with e.g. @jsonMember(Number)`);
      return;
    }
    const reflectPropCtor = Reflect.getMetadata('design:type', prototype, property);
    if (reflectPropCtor == null) {
      logError(`${decoratorName}: could not resolve detected property constructor at \
runtime. Potential solutions:
 - ${LAZY_TYPE_EXPLANATION}
 - ${MISSING_REFLECT_CONF_MSG}`);
      return;
    }
    const typeDescriptor = ensureTypeDescriptor(reflectPropCtor);
    if (isSpecialPropertyType(decoratorName, typeDescriptor)) {
      return;
    }
    injectMetadataInformation(prototype, property, {
      type: () => typeDescriptor,
      key: propertyKeyOrOptions.toString(),
      name: propertyKeyOrOptions.toString()
    });
    return;
  }
  return jsonMemberDecoratorFactory(optionsOrPrototype, propertyKeyOrOptions);
}
function jsonMemberDecoratorFactory(optionsOrType, options) {
  return (target, property) => {
    var _a;
    const decoratorName = `@jsonMember on ${nameof(target.constructor)}.${String(property)}`;
    let typeThunk;
    if (isTypelike(optionsOrType) || isTypeThunk(optionsOrType)) {
      typeThunk = ensureTypeThunk(optionsOrType, decoratorName);
    } else {
      options = optionsOrType;
    }
    options = options !== null && options !== void 0 ? options : {};
    if (Object.prototype.hasOwnProperty.call(options, 'constructor')) {
      if (typeThunk !== undefined) {
        throw new Error('Cannot both define constructor option and type. Only one allowed.');
      }
      if (!isValueDefined(options.constructor)) {
        logError(`${decoratorName}: cannot resolve specified property constructor at \
runtime. ${LAZY_TYPE_EXPLANATION}`);
        return;
      }
      const newTypeDescriptor = ensureTypeDescriptor(options.constructor);
      typeThunk = () => newTypeDescriptor;
      if (isReflectMetadataSupported && !isSubtypeOf(newTypeDescriptor.ctor, Reflect.getMetadata('design:type', target, property))) {
        logWarning(`${decoratorName}: detected property type does not match` + ` 'constructor' option.`);
      }
    } else if (typeThunk !== undefined) {} else if (isReflectMetadataSupported) {
      const reflectCtor = Reflect.getMetadata('design:type', target, property);
      if (reflectCtor == null) {
        logError(`${decoratorName}: cannot resolve detected property constructor at \
runtime. ${LAZY_TYPE_EXPLANATION}`);
        return;
      }
      typeThunk = () => ensureTypeDescriptor(reflectCtor);
    } else if (options.deserializer === undefined) {
      logError(`${decoratorName}: Cannot determine type`);
      return;
    }
    const typeToTest = typeThunk === null || typeThunk === void 0 ? void 0 : typeThunk();
    if (typeToTest !== undefined && isSpecialPropertyType(decoratorName, typeToTest)) {
      return;
    }
    injectMetadataInformation(target, property, {
      type: typeThunk === undefined ? undefined : () => ensureTypeDescriptor(typeThunk()),
      emitDefaultValue: options.emitDefaultValue,
      isRequired: options.isRequired,
      options: extractOptionBase(options),
      key: property.toString(),
      name: (_a = options.name) !== null && _a !== void 0 ? _a : property.toString(),
      deserializer: options.deserializer,
      serializer: options.serializer
    });
  };
}
function isConstructorEqual(type, constructor) {
  return type instanceof TypeDescriptor ? type.ctor === constructor : type === constructor;
}
function isSpecialPropertyType(decoratorName, typeDescriptor) {
  if (!(typeDescriptor instanceof ArrayTypeDescriptor) && isConstructorEqual(typeDescriptor, Array)) {
    logError(`${decoratorName}: property is an Array. Use the jsonArrayMember decorator to` + ` serialize this property.`);
    return true;
  }
  if (!(typeDescriptor instanceof SetTypeDescriptor) && isConstructorEqual(typeDescriptor, Set)) {
    logError(`${decoratorName}: property is a Set. Use the jsonSetMember decorator to` + ` serialize this property.`);
    return true;
  }
  if (!(typeDescriptor instanceof MapTypeDescriptor) && isConstructorEqual(typeDescriptor, Map)) {
    logError(`${decoratorName}: property is a Map. Use the jsonMapMember decorator to` + ` serialize this property.`);
    return true;
  }
  return false;
}
