declare const $: any;
import moment from 'moment';
import daterangepicker from 'daterangepicker';

import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TwoPeriods, Interval } from '@app/data/analytics/analytics.model';

const basePickerOptions: daterangepicker.Options = {
  alwaysShowCalendars: true,
  autoApply: false,
  opens: 'left'
};

const currentPeriodRanges: { [name: string]: [daterangepicker.DateOrString, daterangepicker.DateOrString] } = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  'Month to Date': [moment().startOf('month'), moment()],
  'Year to Date': [moment().startOf('year'), moment()],
};

@Component({
  selector: 'app-daterange-picker',
  styleUrls: [ './daterange-picker.component.scss' ],
  templateUrl: './daterange-picker.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit, OnDestroy {

  @Input() public twoPeriods!: TwoPeriods;
  @Output() public onPeriodSelected = new EventEmitter<TwoPeriods>();
  
  @ViewChild('current_period_tab', {static: true}) currentPeriodTab!: ElementRef;
  @ViewChild('current_period_selector', {static: true}) currentPeriodSelector!: ElementRef;

  @ViewChild('previous_period_tab', {static: true}) previousPeriodTab!: ElementRef;
  @ViewChild('previous_period_selector', {static: true}) previousPeriodSelector!: ElementRef;

  public currentPeriodPicker?: daterangepicker;
  public previousPeriodPicker?: daterangepicker;

  public isCalendarClosed = true;
  public isPreviousPeriodShown = false;
  public isSettingRange = false;
  public isCustomSelecting = false;

  constructor(
    protected _translate: TranslateService,
  ){}
  
  public ngOnInit() {
    
    $('body').on('click', '#picker-trigger', () => {
      // Handle the toggle of the popup on button trigger
      if (this.isCalendarClosed) {
        this.isCalendarClosed = false;
        this.currentPeriodSelector.nativeElement.click();
      } else {
        this.isCalendarClosed = true;
        this.isPreviousPeriodShown = false;
      }
      // Handle closing the component on clicking outside of the component;
      window.setTimeout(() => {
        $('body').on('click.drp', this.clickedAfterOpened);
      });
    });   
    
    // Set up the current period date picker
    const currentPeriodPickerOptions: daterangepicker.Options = {
      startDate: this.twoPeriods.current.start,
      endDate: this.twoPeriods.current.end,
      parentEl: this.currentPeriodTab.nativeElement,
      ranges: currentPeriodRanges,
    };
    this.currentPeriodPicker = new daterangepicker(this.currentPeriodSelector.nativeElement, { ...basePickerOptions, ...currentPeriodPickerOptions }, (start, end, rangeName) => {
      this.twoPeriods.current.update(start, end);
      if (this.twoPeriods.relativeFunction !== 'custom') {
        switch(rangeName) {
          case 'Last Month':
            this.twoPeriods.relativeFunction = 'month';
            break;
          case 'Last Year':
            this.twoPeriods.relativeFunction = 'year';
            break;
          case 'Month to Date':
            this.twoPeriods.relativeFunction = 'month';
            break;
          case 'Year to Date':
            this.twoPeriods.relativeFunction = 'year';
            break;
          default:
            this.twoPeriods.relativeFunction = 'period';
            break;
        }
      }
      this.updatePreviousDates();
    });

    // Set up the previous period date picker
    const previousPeriodPickerOptions: daterangepicker.Options = {
      startDate: this.twoPeriods.previous.start,
      endDate: this.twoPeriods.previous.end,
      parentEl: this.previousPeriodTab.nativeElement,
      showCustomRangeLabel: false,
      ranges: {
        // This is just a dummy option - for UI space allocation in the widget
        // The actual range is custom coded in the HTML as a floating container
        Sample: [moment(), moment()]
      }
    }

    this.previousPeriodPicker = new daterangepicker(this.previousPeriodSelector.nativeElement, { ...basePickerOptions, ...previousPeriodPickerOptions }, (start, end) => {
      $(this.previousPeriodSelector.nativeElement).data('selectedStart', start);
      $(this.previousPeriodSelector.nativeElement).data('selectedEnd', end);
      this.twoPeriods.previous.update(start, end);
    });

    // Handling the closing of the popup when values are applied or canceled
    $(this.currentPeriodSelector.nativeElement).on('apply.daterangepicker', () => this.isCalendarClosed = true);
    $(this.previousPeriodSelector.nativeElement).on('apply.daterangepicker', () => {
      const start = $(this.previousPeriodSelector.nativeElement).data('selectedStart');
      const end = $(this.previousPeriodSelector.nativeElement).data('selectedEnd');
      this.twoPeriods.relativeFunction = 'custom';
      this.twoPeriods.previous.update(start, end);
      this.updatePreviousDates();
      this.isCalendarClosed = true;
      this.isPreviousPeriodShown = false;
      this.isCustomSelecting = false;
    });
    $(this.currentPeriodSelector.nativeElement).on('cancel.daterangepicker', () => this.isCalendarClosed = true);
    $(this.previousPeriodSelector.nativeElement).on('cancel.daterangepicker', () => {
      this.isCalendarClosed = true;
      this.isPreviousPeriodShown = false;
      this.isCustomSelecting = false;
      this.isSettingRange = false;
    });
    $(this.previousPeriodSelector.nativeElement).on('startDateClick.daterangepicker', () => {
      this.isSettingRange = true;
    });
    $(this.previousPeriodSelector.nativeElement).on('endDateClick.daterangepicker', () => {
      this.isSettingRange = false;
      this.isCustomSelecting = true;
    });
  }

  public ngOnDestroy() {
    $('body').off('click', '#picker-trigger');
    $('body').off('click', this.clickedAfterOpened);
    if (this.currentPeriodPicker) {
      this.currentPeriodPicker.remove();
    }
    if (this.previousPeriodPicker) {
      this.previousPeriodPicker.remove();
    }
  }

  public clickedAfterOpened = (e: any) => {
    const $target = $(e.target);
    const isOutsideOfWidget = $target.parents('.app-daterange-picker').length === 0;
    const isOutsideCalTable = $target.parents('.table-condensed').length === 0;
    if (isOutsideOfWidget && isOutsideCalTable) {
      this.isCalendarClosed = true;
      this.isPreviousPeriodShown = false;
      $('body').off('click', this.clickedAfterOpened);
    }
  }

  public updatePreviousDates() {
    if (this.twoPeriods.relativeFunction !== 'custom') {
      this.twoPeriods.previous = Interval.RelativeTo(this.twoPeriods.current, this.twoPeriods.relativeFunction);
      this.previousPeriodPicker?.setStartDate(this.twoPeriods.previous.start);
      this.previousPeriodPicker?.setEndDate(this.twoPeriods.previous.end);
    }
    this.setPeriod();
  }

  public changePeriodFunction(funcType: any) {
    this.twoPeriods.relativeFunction = funcType;
    this.updatePreviousDates();
    if (funcType !== 'custom') {
      this.isCalendarClosed = true;
      this.isPreviousPeriodShown = false;
      this.isCustomSelecting = false;
      this.isSettingRange = false;
    } else {
      $(this.previousPeriodSelector.nativeElement).data('selectedStart', this.twoPeriods.previous.start);
      $(this.previousPeriodSelector.nativeElement).data('selectedEnd', this.twoPeriods.previous.end);
      this.previousPeriodSelector.nativeElement.click();
    }
    return false;
  }

  public showPreviousPeriod() {
    this.isPreviousPeriodShown = true;
    this.previousPeriodSelector.nativeElement.click();
    return false;
  }

  public showCurrentPeriod() {
    this.isPreviousPeriodShown = false;
    this.currentPeriodSelector.nativeElement.click();
    return false;
  }

  public setPeriod() {
    if (
      this.twoPeriods.current.start.isSame(this.twoPeriods.previous.start, 'day') &&
      this.twoPeriods.current.end.isSame(this.twoPeriods.previous.end, 'day')
    ) {
      return;
    }

    this.onPeriodSelected.emit(this.twoPeriods);
  }
}
