import { jsonMember, jsonObject } from "typedjson";

@jsonObject
export class IntervalNew {

  @jsonMember(Number)
  start!: number;

  @jsonMember(Number)
  end!: number;
}

export class Range {

  @jsonMember(Number)
  start!: number;

  @jsonMember(Number)
  end!: number;
  
}

export class LocalDateRange {
  start: string;
  end: string;

  constructor(d: any) {
    this.start = d.start;
    this.end = d.end;
  }
}