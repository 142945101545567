// BREAK POINTS
// ============================
$tablet-min-width: 760px;
$desktop-min-width: 960px;
$wide-min-width: 1280px;

// Max-widths:
$mobile-max-width: ($tablet-min-width - 1);
$tablet-max-width: ($desktop-min-width - 1);
$desktop-max-width: ($wide-min-width - 1);


// Global app level variables
$app-header-height: 60px;
$app-action-bar-height: 60px;

// Color variable names match with those defined in the Sketch file
$color-ocean-boat-blue: rgba(0, 121, 197, 1);
$color-deep-sky-blue: rgba(0, 177,228, 1);
$color-black: rgba(0, 0, 0, 1);
$color-white: rgba(255, 255, 255, 1);
$color-white-10: rgba(255, 255, 255, 0.1);
$color-light-grey: rgba(153, 153, 153, 1);
$color-alice-blue: rgba(236, 245, 253, 1);
$color-pale-aqua: rgba(197, 217, 232, 1);
$color-cadet: rgba(63, 83, 110, 1);
$color-charcoal: rgba(44, 64, 90, 1);
$color-cadet-grey: rgba(141, 171, 196, 1);
$color-periwinkle-12: rgba(185, 216, 155, 0.12);
$color-glitter: rgba(229, 238, 245, 1);
$color-dark-cerulean: rgba(11, 82, 124, 1);
$color-wind-blue-yonder: rgba(143, 172, 197, 1);
$color-pale-cerulean: rgba(168, 198, 223, 1);
$color-dark-slate-blue: rgba(83, 49, 137, 1);
$color-byzantium: rgba(119, 51, 124, 1);
$color-bistre: rgba(52, 60, 25, 1);
$color-smokey-topaz: rgba(137, 49, 60, 1);
$color-sap-green: rgba(61, 147, 53, 1);
$color-phthalo-blue: rgba(5, 19, 137, 1);
$color-teal: rgba(5, 137, 115, 1);
$color-dark-sienna: rgba(60, 29, 5, 1);
$color-golden-brown: rgba(147, 102, 6, 1);
$color-rose-vale: rgba(176, 62, 77, 1);
$color-tropical-rain-forest: rgba(0, 124, 79, 1);
$color-old-lavender: rgba(110, 83, 124, 1);
$color-lavender-mist: rgba(216, 229, 239, 1);
$color-platinum: rgba(216, 229, 238, 1);
$color-ghost-white: rgba(250, 251, 252, 1);
$color-usc-gold: rgba(250, 202, 0, 1);
$color-azure: rgba(0, 147, 238, 1);
$color-wild-blue-yonder: rgba(143, 172, 197, 1);
$color-wild-blue-yonder-20: rgba(143, 172, 197, 0.2);
$color-electric-violet: rgba(144, 18, 254, 1);
$color-lavender: rgba(181, 126, 220, 1);
$color-orange: rgba(255, 128, 0, 1);
$color-red-orange: rgba(254, 79, 86, 1);
$color-mauvelous: rgba(253, 166, 169, 1);
$color-lime-green: rgba(78, 206, 61, 1);
$color-harvard-crimson: rgba(208, 2, 27, 1);
$color-napier-green: rgba(65, 117, 5, 1);
$color-golden-poppy-15: rgba(233, 188, 0, 0.15);
$color-timberwolf-10: rgba(216, 216, 216, 0.1);
$color-yellow-green: rgba(152, 220, 77, 1);
$color-medium-spring-bug: rgba(190, 227, 145, 1);
$color-date-range-black: rgb(33, 37, 41);
$color-date-range-grey: rgb(238, 238, 238);
$color-date-range-blue: rgb(0, 136, 204);


// Chart Coloring
$color-chart-background: rgba(239, 239, 239, 0.3);