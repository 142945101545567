import moment from 'moment';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  styleUrls: [ './date-picker.component.scss' ],
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Input() currentDate!: moment.Moment;
  @Output() dateSelected = new EventEmitter<moment.Moment>();

  public date?: Date;

  public updateCurrentDate(): void {
    this.dateSelected.emit(moment(this.date).startOf('day'));
  }

  ngOnInit() {
    this.date = this.currentDate.toDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentDate']) {
      this.date = this.currentDate.toDate();
    }
  }
}
