<div class="room-selector" dropdown container="body">
  <a class="link-secondary text-decoration-none" href id="room-selector-link" dropdownToggle (click)="false" aria-controls="room-selector-link-dropdown">
      <i class="fa fa-plus d-inline"></i>
  </a>
  <ul id="room-selector-link-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
    <li role="menuitem" *ngFor="let room of allRooms">
      <a href="#" class="dropdown-item" (click)="toggle(room.roomLid)">
        <div class="room-item">
          <span class="name">{{room.name}}</span>
          <i class="fa fa-check room-selected" *ngIf="isSelected(room.roomLid)"></i>
        </div>
      </a>
    </li>
  </ul>
</div>
