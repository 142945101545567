import {Pipe, PipeTransform} from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({name: 'phoneNumber'})
export class phoneNumberPipe implements PipeTransform {
  transform(value:any): any {
    if (value) {
      try {
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber.formatNational();
      } catch (error) {
        return value;  
      }
    }
    return value;  
  }
}
